import React, { useState } from "react";
import {
  Form, 
  Upload,
  message,
  Card,
  Typography,
  Col,
} from "antd";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import { 
  useUpdateProfilePicMutation,
} from "../../../apis/Seller/SellerAccountsAPI";

import { InboxOutlined } from "@ant-design/icons";

const Text = Typography;
function SellerProfile(SellerData) {
  const [updatprofileDetails] = useUpdateProfilePicMutation();
  // eslint-disable-next-line
  const [profileImage, setProfileImage] = useState("");
  // eslint-disable-next-line
  const [name, setName] = useState("");
  

  // eslint-disable-next-line
  const beforeUpload = (file) => {
    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      message.error("Only images are allowed!");
      return Upload.LIST_IGNORE;
    }

    // Check if the file size is within the limit
    const fileSizeLimit = 1024 * 1024; // 1MB
    if (file.size > fileSizeLimit) {
      message.error(`File size exceeds ${fileSizeLimit / 1024}KB!`);
      return Upload.LIST_IGNORE;
    }

    return true;
  };
  // eslint-disable-next-line
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const [ProfileForm] = Form.useForm();
  const [profileFileList, setprofileFileList] = useState();
  const [fileChanged, setFileChanged] = useState(false);
  const handleFileChange = async (info) => {
    if (info?.file?.status !== "error") {
      let fileList = info.file;
      // fileList = fileList.slice(-1);
      // fileList = fileList.filter((file) => {
      if (fileList.type.includes("image")) {
        setFileChanged(true);

        //setFileList(fileList);
        const file = info?.fileList[0];
        let url = file?.originFileObj;

        setprofileFileList(url);
        // handleQRFieldChange(inputValues);
        if (fileChanged) {
          ProfileForm.submit();
          setFileChanged(false);
        }
        return true;
      } else {
        message.error(`${fileList.name} is not a valid image file.`);
        return false;
      }
    }
    //});
  };
  const handleProfileupdate = async (val) => {
    const data = {
      profile_image: profileFileList,
      // name: UPIId,
      // email: UPIPhoneNo,
    };
    let response;
    response = await updatprofileDetails(data);

    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      // dispatch(SellerDetails({}));
     //  SellerDetails();
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  return (
    <Card className="card card-design">
      <Form
        layout="vertical"
        autoComplete="off"
        form={ProfileForm}
        onFinish={handleProfileupdate}
      >
        <Form.Item>
          <Upload
            name="profileImage"
            listType="picture-circle"
            className="avatar-uploader"
            showUploadList={false}
            maxCount={1}
            action={""}
            onChange={(info) => {
              handleFileChange(info);
              //form.setFieldsValue({ [name]: info.file }); // update form values
            }}
            beforeUpload={beforeUpload}
          >
            {profileImage ? (
              <img src={URL.createObjectURL(profileImage)} alt="avatar" />
            ) : (
              <div>
                <InboxOutlined />
                <div className="ant-upload-text">Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <Text className="text-head-light">Full name</Text>
            <Text className="text-head">{SellerData?.person_full_name || "-"} </Text>
          </li>
          <li className="list-group-item">
            <Text className="text-head-light">Phone number</Text>
            <Text className="text-head">{SellerData?.phone_number || "-"}</Text>
          </li>
          <li className="list-group-item">
            <Text className="text-head-light">Email address</Text>
            <Col className="row-style">
              <Text className="text-head mr-2">
              {SellerData?.email || "-"}
              </Text>
            </Col>
          </li>
        </ul>
      </Form>
    </Card>
  );
}

export default SellerProfile;
