import { React, useEffect, useState } from "react";
// eslint-disable-next-line
import {
  Row,
  Col,
  Badge,
  Drawer,
  Typography,
  Dropdown,
  Menu,
  Modal,
  // eslint-disable-next-line
  Button,
} from "antd";
// eslint-disable-next-line
import { DownOutlined } from "@ant-design/icons";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { ReactComponent as Logo } from "../../../assets/seller/icons/mobapartner.svg";
import { ReactComponent as BellIcon } from "../../../assets/seller/icons/bell_black_outline.svg";
import { ReactComponent as CrossIcon } from "../../../assets/seller/icons/x-close.svg";

import { ReactComponent as HomeblueIcon } from "../../../assets/seller/icons/homeblue.svg";
import "./Navbar.css";
import {
  HeaderContainer,
  OpenCloseButton,
  ProfileContainer,
  LeftContainer,
  LeftContainerMobile,
} from "./NavbarStyled";
import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
import ProfileDropdown from "./ProfileDropDown/ProfileDropdown";

import { showDemandRupifiUrl } from "../../../commonUtils/commonUtils";
import { SellerMainSearch } from "../SellerMainSearch/SellerMainSearch";
// import { seller_ListSelector } from "../../../redux/slices/loginAuth/selector";
import SellerRegistration from "../Sellerregisration/SellerRegistration";
import SellerNotification from "../SellerNotifications/SellerNotification";

import { useLazyGetStoreAccessDetailsQuery } from "../../../apis/Seller/SellerReportsAPI.js";
const { Title, Text } = Typography;
// const Help = [
//   <svg
//     width="22"
//     height="22"
//     viewBox="0 0 22 22"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//     key={0}
//   >
//     <g data-name="help-circle" transform="translate(-1 -1)">
//       <circle
//         data-name="Ellipse 363"
//         cx={10}
//         cy={10}
//         r={10}
//         transform="translate(2 2)"
//         style={{
//           fill: "none",
//           stroke: "#000",
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: 2,
//         }}
//       />
//       <path
//         data-name="Path 40497"
//         d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"
//         style={{
//           fill: "none",
//           stroke: "#000",
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: 2,
//         }}
//       />
//       <path
//         data-name="Line 11"
//         transform="translate(12 17)"
//         style={{
//           fill: "none",
//           stroke: "#000",
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: 2,
//         }}
//         d="M0 0h.01"
//       />
//     </g>
//   </svg>,
// ];

const ArrowTop = [
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.121"
    height="10.121"
    viewBox="0 0 10.121 10.121"
  >
    <path
      data-name="Line 113"
      transform="translate(1.061 1.061)"
      style={{
        fill: "none",
        stroke: "#000",
        strokelinecap: "round",
        strokelinejoin: "round",
        strokewidth: "1.5px",
      }}
      d="m0 8 8-8"
    />
    <path
      data-name="Path 45676"
      d="M7 7h8v8"
      style={{
        fill: "none",
        stroke: "#000",
        strokelinecap: "round",
        strokelinejoin: "round",
        strokewidth: "1.5px",
      }}
      transform="translate(-5.939 -5.939)"
    />
  </svg>,
];
const Navbar = ({ setCollapsed, collapsed }) => {
  const bmp_isactive = localStorage.getItem("bmp_isactive");
  const bmp_id = localStorage.getItem("bmp_id");
  const [NotificationCount, setNotificationCount] = useState(0);
  const url = new URL(`/home/product-search/${bmp_id}`, showDemandRupifiUrl);
  // url.searchParams.set("value", bmp_id);
  url.searchParams.set("bmp", bmp_id);
  const navigate = useNavigate();
  const handleNavigateDashboard = () => {
    const currentPath = window.location.pathname;
    const dashboardPath = "/seller/sellerdashboard";
    if (currentPath === dashboardPath) {
      window.location.reload();
    } else {
      navigate(dashboardPath);
    }
  };
  // eslint-disable-next-line
  const [getStoreListAPI, { data: StoreList, isFetching }] =
    useLazyGetStoreAccessDetailsQuery();
  useEffect(() => {
    getStoreListAPI();
  }, [getStoreListAPI]);

  // let sellerList = useSelector(seller_ListSelector);
  // Store sellerList in local storage
  // useEffect(() => {
  //   if (StoreList.length > 0) {
  //     localStorage.setItem("sellerList", JSON.stringify(StoreList));
  //   }
  // }, [StoreList]);

  // const storedSellerList = localStorage.getItem("sellerList");
  // if (!sellerList || sellerList.length === 0) {
  //   sellerList = storedSellerList ? JSON.parse(storedSellerList) : [];
  // }
  const [isAddNewSellerModalOpen, setIsAddNewSellerModalOpen] = useState(false);

  const handleAddNewSellerModalClose = () => {
    setIsAddNewSellerModalOpen(false);
  };
  useEffect(() => window.scrollTo(0, 0));
  const [visible, setVisible] = useState(false);
  const bmp_name = localStorage.getItem("bmp_name") || "";

  const [selectedVendor, setSelectedVendor] = useState(bmp_name);
  const menu = (
    <Menu>
      {StoreList?.data?.map((seller, index) => (
        <>
          <Menu.Item key={index} onClick={() => handleVendorChange(seller)}>
            {seller.bmp_id} ({seller.vendor_name})
          </Menu.Item>
          <Menu.Divider />
        </>
      ))}
      {/* <Menu.Divider /> */}
      <Menu.Item
        key="add-new-seller"
        className="btn-add-newseller-list"
        onClick={() => handleAddNewSeller()}
      >
        <HomeblueIcon /> Add New Store
      </Menu.Item>
    </Menu>
  );
  // eslint-disable-next-line
  const handleAddNewSeller = () => {
    setIsAddNewSellerModalOpen(true);
  };
  const handleVendorChange = (newVendor) => {
    // console.log("🚀 ~ handleVendorChange ~ newVendor:", newVendor)
    localStorage.setItem("bmp_id", newVendor?.bmp_id);
    localStorage.setItem("bmp_name", newVendor?.vendor_name);
    localStorage.setItem("bmp_isactive", newVendor?.is_active);
    setSelectedVendor(newVendor?.vendor_name);
    window.location.reload();
    // You can also add additional logic here, such as updating the URL or making an API call
  };
  return (
    <>
      <HeaderContainer
        className=" d-none d-md-block d-lg-block d-xl-block"
        style={{
          backgroundColor: "#fff",
          color: "#0a243f",
          boxShadow: "0 3px 3px 0 rgba(0, 0, 0, 0.08)",
          borderBottom: "1px solid #dedede ",
          padding: "12px 0px",
        }}
      >
        <Row gutter={[24, 0]} style={{ margin: "0px" }}>
          <Col xs={12} sm={24} md={8} lg={8} xl={8}>
            <LeftContainer>
              <OpenCloseButton
                style={{ color: "#0a243f" }}
                onClick={() => setCollapsed(!collapsed)}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </OpenCloseButton>
              <Logo
                style={{ cursor: "pointer", width: "120px" }}
                onClick={handleNavigateDashboard}
              />
              <Col
                className="fixed-font font-eclipse cls-top-seller-menu"
                style={{ cursor: "pointer" }}
              >
                <Dropdown
                  className=""
                  overlay={menu}
                  style={{ cursor: "pointer" }}
                  trigger={["click"]}
                >
                  <div>
                    {selectedVendor}{" "}
                    <DownOutlined className="profile-dropdown-styled-cursor" />
                  </div>
                </Dropdown>
              </Col>
              {/* <Col className="fixed-font font-eclipse" >
              {bmp_id} ({bmp_name})
                <DownOutlined className="profile-dropdown-styled-cursor" />
              </Col> */}
            </LeftContainer>
          </Col>

          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <SellerMainSearch />
          </Col>

          <Col
            xs={12}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="header-control text-right"
          >
            <ProfileContainer>
              {/* <Badge size="small">
        <Text className="ant-dropdown-link">{Help}</Text>
      </Badge> */}
              {/* <Col className="d-flex"> */}
              {bmp_isactive === "true" && (
                <>
                  <Badge size="small">
                    <a href={url.href} rel="noreferrer" target="_blank">
                      <Text
                        style={{ marginRight: "6px" }}
                        className="online-store"
                      >
                        Your online store {ArrowTop}
                      </Text>
                    </a>
                  </Badge>

                  <Badge size="small" style={{ marginTop: "4px" }} className="notification-badge" count={NotificationCount}>
                    <Text
                      onClick={(e) => {
                        e.preventDefault();
                        setVisible(true);
                      }}
                      className="ant-dropdown-link"
                      style={{ marginTop: "4px" }}
                    >
                      <BellIcon />
                    </Text>
                  </Badge>
                </>
              )}
              {/* </Col> */}
              <ProfileDropdown />
            </ProfileContainer>
          </Col>
        </Row>
      </HeaderContainer>

      {/* Mobile part */}
      <HeaderContainer
        className="d-block d-sm-block d-lg-none d-xl-none pl-0"
        style={{
          backgroundColor: "#fff",
          color: "#0a243f",
          boxShadow: "0 3px 3px 0 rgba(0, 0, 0, 0.08)",
          borderBottom: "1px solid #dedede ",
          padding: "10px 0px 0px 0px",
          height: "120px",
        }}
      >
        <Row gutter={[24, 0]} style={{ margin: "0px" }}>
          <Col xs={13} sm={24} md={8} lg={8} xl={8} style={{ gap: "0rem" }}>
            <LeftContainerMobile>
              <OpenCloseButton
                style={{ color: "#0a243f", paddingLeft: "5px" }}
                onClick={() => setCollapsed(!collapsed)}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </OpenCloseButton>
              <Logo
                style={{ cursor: "pointer", paddingBottom: "5px" }}
                onClick={handleNavigateDashboard}
              />
              {/* <Col className="fixed-font font-eclipse">               
              {bmp_id} ({bmp_name})
              </Col> */}
            </LeftContainerMobile>
          </Col>
          <Col
            xs={11}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            className="header-control text-right"
          >
            <ProfileContainer>
              <ProfileDropdown />
            </ProfileContainer>

            {/* <Badge size="small">
        <Text className="ant-dropdown-link">{Help}</Text>
      </Badge> */}
            {/* <Badge size="small" style={{ marginTop: "4px" }} count={0}>
        <Text
          onClick={(e) => {
            e.preventDefault();
            setVisible(true);
          }}
          className="ant-dropdown-link"
          style={{ marginRight: "6px", marginTop: "4px" }}
        >
          {bell}
        </Text>
      </Badge> */}
          </Col>
        </Row>
        <Row>
          <Col style={{ padding: "5px" }} xs={24} sm={24} md={8} lg={8} xl={8}>
            <SellerMainSearch />
          </Col>
        </Row>
      </HeaderContainer>

      <Drawer
        title={
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={4} style={{ margin: 0 }}>
              {NotificationCount} Notifications
            </Title>
            <Col
              onClick={() => setVisible(false)}
              style={{
                width: "48px",
                height: "48px",
                padding: "16px",
                borderRadius: " 24px",
                cursor: "pointer",
              }}
            >
              <CrossIcon />
            </Col>
          </Col>
        }
        placement="right"
        visible={visible}
        className="notification-drawer"
        closable={false}
        onClose={() => setVisible(false)}
      >
        {/* drawer content */}
        <Row>
          <SellerNotification setNotificationCount={setNotificationCount} />
        </Row>
      </Drawer>
      <Modal
        title="Add New Store"
        visible={isAddNewSellerModalOpen}
        onCancel={handleAddNewSellerModalClose}
        footer={[]}
      >
        <SellerRegistration
          handleAddNewSellerModalClose={handleAddNewSellerModalClose}
          reloadAPI={getStoreListAPI}
        />
      </Modal>
    </>
  );
};

export default Navbar;
