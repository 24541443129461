import { React, useEffect, useState } from "react";
import { Row, Tabs, Form, Col } from "antd";
import "./SellerAccounts.css";
import {
  useLazyGetVendorDetailsQuery,
  useUpdateSellerVenderMutation,
  useUpdateSellerTimingVenderMutation,
  useUpdateSellerQRCodeMutation,
  useUpdateSellerBankDetailsMutation,
  useUpdateSellerTermsDetailsMutation
} from "../../../apis/Seller/SellerAccountsAPI";
// eslint-disable-next-line
import SellerBasicDetails from "./SellerBasicDetails";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
// eslint-disable-next-line
import SellerInvoiceCustomization from "./SellerInvoiceCustomization";
import SubscriptionReport from "../SellerReport/SubscriptionReport/SubscriptionReport";
import SellerStoreAccess from "./SellerStoreAccess";
import SellerMemberAccess from "./SellerMemberAccess";
// eslint-disable-next-line
import SellerProfile from "./SellerProfile";
const { TabPane } = Tabs;

export default function SellerAccounts() {
  const bmp_id = localStorage.getItem("bmp_id");
  const bmp_credit_enable = localStorage.getItem("bmp_credit_enable");
  const [formData, setFormData] = useState({});
  const [QRfileList, setQRFileList] = useState();
  const [UPIId, setUPIID] = useState();
  const [UPIPhoneNo, setUPIPhoneNo] = useState();
  const [Notesandterms, setNotesandterms] = useState();
  // eslint-disable-next-line
  const [SellerDetails, { data: SellerData, isFetching: isLoading }] =
    useLazyGetVendorDetailsQuery();

  // eslint-disable-next-line
  const [updateSellerVenderApi, { isLoading: isUpdateLoading }] =
    useUpdateSellerVenderMutation();
  // eslint-disable-next-line
  const [updateSellerVenderTimingApi, { isLoading: isTimingUpdateloading }] =
    useUpdateSellerTimingVenderMutation();

  const [updateSellerQRCodeApi] = useUpdateSellerQRCodeMutation();
  const [updateSellerTermsDetailsApi] = useUpdateSellerTermsDetailsMutation();
  const [updateSellerbankDetailsApi] = useUpdateSellerBankDetailsMutation();

  const [isTimingModalOpen, setIsTimingModalOpen] = useState(false);
  const [bankDetails, setBankDetails] = useState({});

  useEffect(() => {
    SellerDetails();
  }, [SellerDetails]);

  const [form] = Form.useForm();
  const [ShopTimingForm] = Form.useForm();
  const [QRForm] = Form.useForm();
  const handleChildFieldChange = (inputValues) => {
    setFormData({ ...formData, ...inputValues });
    form.setFieldsValue(inputValues);
  };

  const handleEditedTimingsData = async (val) => {
    const data = {
      bmp_id: bmp_id,
      shop_opening_time: val?.shop_opening_time.format("h:mm a") || "",
      shop_closing_time: val?.shop_closing_time.format("h:mm a") || "",
    };
    let response;
    response = await updateSellerVenderTimingApi(data);

    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setIsTimingModalOpen(false);
      SellerDetails();
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handleEditFormData = async (val) => {
    const data = {
      bmp_id: bmp_id,
      email: SellerData?.data?.email || "",
      phone_number: SellerData?.data?.phone_number || "",
      address: val?.mail_address || "",
      location: val?.location || "",
      state: val?.state || "",
      city: val?.city || "",
      pincode: val?.pincode || "",
      person_full_name: val?.person_full_name || "",
      shop_opening_time: val?.shop_opening_time || "",
      shop_closing_time: val?.shop_closing_time || "",
      website: val?.website || "",
      shop_act_registration_number: val?.shop_act_registration_number || "",
      gst_number: val?.gst_number || "",
      adhar_card_number: val?.adhar_card_number || "",
      pan_card_number: val?.pan_card_number || "",
      remarks: val?.remarks || "",
      seller_group: val?.seller_group || "",
      google_map_link: val?.google_map_link || "",
      types_of_material: val?.type_of_materials || [],
      seller_display_image: formData?.seller_display_image || "",
    };
    let response;
    response = await updateSellerVenderApi(data);

    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      // dispatch(SellerDetails({}));
      SellerDetails();
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handleEditQRCode = async (val) => {
    const data = {
      qr_scanner: QRfileList,
      upi_id: UPIId,
      upi_phone_number: UPIPhoneNo,
    };
    let response;
    response = await updateSellerQRCodeApi(data);

    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      // dispatch(SellerDetails({}));
      SellerDetails();
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handleSavebankdetails = async (val) => {
    const data = {
      bank_details: bankDetails,
    };
    let response;
    response = await updateSellerbankDetailsApi(data);

    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      // dispatch(SellerDetails({}));
      SellerDetails();
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  const handleSaveTermsdetails = async (val) => {
    const data = {
      notes_and_terms: Notesandterms,
    };
    let response;
    response = await updateSellerTermsDetailsApi(data);

    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      // dispatch(SellerDetails({}));
      SellerDetails();
    } else {
      showErrorToast(response?.error?.data?.message);
    }
  };
  return (
    <>
    
      <Row className="mt-4 mb-2">
        <Tabs style={{width:"100%"}}  tabPosition={"top"} defaultActiveKey={1} >
          <TabPane tab={<span>Settings</span>} key="1">
            <div>
              <SellerBasicDetails
                SellerData={SellerData?.data}
                isLoading={isLoading}
                handleChildFieldChange={handleChildFieldChange}
                name={"seller_display_image"}
                form={form}
                handleEditFormData={handleEditFormData}
                handleEditedTimingsData={handleEditedTimingsData}
                ShopTimingForm={ShopTimingForm}
                setIsTimingModalOpen={setIsTimingModalOpen}
                isTimingModalOpen={isTimingModalOpen}
                // handleDateFieldChange={handleDateFieldChange}
              />
            </div>
          </TabPane>
          <TabPane tab={<span>Store invoice edit</span>} key="2">
            <Col xs={24} sm={12} md={12} lg={12} xl={16}>
              <SellerInvoiceCustomization
                handleEditQRCode={handleEditQRCode}
                QRForm={QRForm}
                QRfileList={QRfileList}
                setQRFileList={setQRFileList}
                SellerData={SellerData?.data}
                setUPIID={setUPIID}
                setUPIPhoneNo={setUPIPhoneNo}
                UPIId={UPIId}
                UPIPhoneNo={UPIPhoneNo}
                updateSellerbankDetailsApi={updateSellerbankDetailsApi}
                bankDetails={bankDetails}
                setBankDetails={setBankDetails}
                handleSavebankdetails={handleSavebankdetails}
                handleSaveTermsdetails={handleSaveTermsdetails}
                Notesandterms={Notesandterms}
                setNotesandterms={setNotesandterms}
              />
            </Col>
          </TabPane>
          <TabPane tab={<span>Member access</span>} key="4">
            <SellerMemberAccess  />
          </TabPane>

         
          {bmp_credit_enable==="true" && (
            <TabPane tab={<span>mobCREDIT subscription</span>} key="5">
              <SubscriptionReport SellerData={SellerData?.data} />
            </TabPane>
          )}
             <TabPane tab={<span>Store access</span>} key="6">
              <SellerStoreAccess/>
            </TabPane>
            {/* <TabPane  tab={<span>Your profile</span>} key="7">
            <Col xs={24} sm={12} md={12} lg={12} xl={16}>
          <SellerProfile  SellerData={SellerData?.data} />
          </Col>
          </TabPane> */}
        </Tabs>
      </Row>
    </>
  );
}
