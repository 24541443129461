import React from "react";
import { Checkbox, Col, Typography, Row, Card } from "antd";
import "./SellerMobCreditOnboarding.css";
import MobBannerIcon from "../../../assets/seller/icons/MobActivation_Banner.webp";
import { ReactComponent as MobCreditIcon } from "../../../assets/seller/icons/Mobcreditlogo.svg";
import { ReactComponent as LampLogo } from "../../../assets/seller/icons/lamp.svg";
import RupifyLogo from "../../../assets/seller/icons/rupify_nobg.webp";
import MuthoothLogo  from "../../../assets/seller/icons/muthooth.svg";
import MobCreditBlackIcon  from "../../../assets/seller/icons/mobcredit-black.svg";
import liquilogo  from "../../../assets/seller/icons/liquilogo.webp";
import MoneyHandLogo  from "../../../assets/seller/icons/money-hand.webp";
import Whattoexpect from "./whattoexpect";
import AboutMobCredit from "./AboutMobCredit";
import { ReactComponent as IndiaFlagLogo } from "../../../assets/seller/icons/india_flag.svg";
import MobCreditVideo from "./MobCreditVideo";
const { Text } = Typography;

const GettingStarted = (props) => {
    const { 
      handleStartedCheckboxChange,
      errorStarted,
      checkedStarted
    } = props;
  // eslint-disable-next-line


  return (
    <>
      <Col
        className=""
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        md={{ span: 24, offset: 0 }}
        lg={{ span: 24, offset: 0 }}
        xl={{ span: 24, offset: 0 }}
      >
        <Row>
          <Col xs={24} sm={12} md={8} lg={8} xl={24}>
            <Text className="txt-getstarted">Let’s get you started!</Text>
          </Col>
        </Row>
        <Row className="text-center">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <img src={MobBannerIcon} className="banner-top" alt="" />
          </Col>
        </Row>
        <Row gutter={[16,16]} type="flex">
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card className="card-credit-section">
              <MobCreditIcon  className="credit-icon"/>
            <Col>
            <Typography.Title level={3}>
                        1st time in India  <IndiaFlagLogo className="india-flag-icon"  />
                      </Typography.Title>
            <Text className="made-india">MADE IN INDIA</Text>
            </Col>
            
            <Col className="mt-4 mb-2">Backed by:</Col>
            <Col style={{display:"flex",alignItems:"center",justifyContent:"flex-start",gap:"12px", flexWrap:"wrap"}}>
            <img alt="" src={MuthoothLogo}></img>
            <img alt="" src={liquilogo} style={{height:"100%"}}></img>
            <img alt="" src={MobCreditBlackIcon}></img>
            <img alt="" src={RupifyLogo}></img>
            </Col>
            </Card>
           
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Card className="card-tips-section">
             <Text className="text-heading-sm"><LampLogo/> {" "}Did you know?</Text><br/>
             <Text className="text-heading-sm-details">4 Lakh crores or USD 50 billion dollars is given out in line of credit to architects, contractors & builders in this sector of construction and interior materials by the suppliers like you on their own books</Text><br/><br/>
              <Col> <img alt="" className="money-hand" src={MoneyHandLogo}></img></Col>
            </Card>
          </Col>
        </Row>
        <Text className="text-subtext">In accordance with RBI regulations</Text>
        <AboutMobCredit/>
        <Row className="mt-4">
        <Typography.Title level={4}>How does mobCREDIT work?</Typography.Title>
      
        </Row>
        <MobCreditVideo/>
        <Row className="mt-4">
        <Typography.Title level={4}>WHAT EXPECTATION TO SET FOR YOUR CUSTOMER ( with GSTIN )</Typography.Title>
      
        </Row>
        <Whattoexpect/>
        <Row className="mt-4 mb-4 custom-bottom-margin">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Checkbox  defaultChecked={checkedStarted} className="chk-text" onChange={handleStartedCheckboxChange}>
              I have read and understood the information provided regarding
              mobCREDIT
            </Checkbox>
            {errorStarted && <div style={{ color: 'red' }}>Please accept mobCREDIT terms to proceed.</div>}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default React.memo(GettingStarted);
