import { Col, Form, Input, Select, Avatar } from "antd";
import { React } from "react";
import "./SellerAccounts.css";
const SellerUserAdd = (prop) => {

  const { Userform, handleUserCreate, selectedRow, isEditModalOpen,RolesData } = prop;
  
  // eslint-disable-next-line

    return (
    <>
      {!isEditModalOpen ? (
        <>
          <Col className="password-change-container">
            <Form
              form={Userform}
              name="Userform"
              autoComplete="off"
              style={
                {
                  // maxWidth: 500,
                }
              }
              layout="vertical"
              onFinish={handleUserCreate}
            >
              <Form.Item
                label={
                  <Col>
                    User name 
                    {/* <span className="text-danger"> *</span> */}
                  </Col>
                }
                className=""
                name="person_full_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter username",
                  },
                ]}
              >
                <Input
                  type="text"
                  defaultValue={selectedRow?.name}
                  className="change-password-input"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Email id or phone number{" "}
                    {/* <span className="text-danger"> *</span> */}
                  </span>
                }
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please enter email id or phone number",
                  },
                ]}
              >
                <Input
                  type="text"
                  // defaultValue={selectedRow?.email}
                  className="change-password-input"
                />
              </Form.Item>

              <Form.Item
                label={
                  <Col>
                    Role 
                    {/* <span className="text-danger"> *</span> */}
                  </Col>
                }
                name="groups"
                rules={[
                  {
                    required: true,
                    message: "Please select role",
                  },
                ]}
              >
                <Select
                  className="change-password-input"
                  // defaultValue={selectedRow?.role}
                  placeholder="Select role"
                >
                  {RolesData?.data &&
                    RolesData?.data.map((role) => (
                      <Select.Option key={role.id} value={role.id}>
                        {role.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </>
      ) : (
        <Col className="password-change-container">
          <Form
            form={Userform}
            name="Userform"
            autoComplete="off"
            style={
              {
                // maxWidth: 500,
              }
            }
            layout="vertical"
            onFinish={handleUserCreate}
          >
            <div className="d-flex">
              {selectedRow?.avatar ? (
                <Avatar
                  src={
                    selectedRow?.avatar
                      ? selectedRow?.avatar
                      : "/MOBAdmin/Content/images/deleteicon.jpg"
                  }
                  className="avatar-6 img-fluid-6 rounded-circle mr-1"
                />
              ) : (
                <span className="member_img">
                  {selectedRow?.person_full_name?.slice(0, 2).toUpperCase()}
                </span>
              )}
              <p className="mt-2">
                <span className="member-name">{selectedRow?.person_full_name}</span>
                <br />
                <span className="member-email">{selectedRow?.email}</span>
              </p>
            </div>
            <Form.Item
              label={
                <Col>
                  Role 
                  {/* <span className="text-danger"> *</span> */}
                </Col>
              }
              name="groups"
              rules={[
                {
                  required: true,
                  message: "Please select role",
                },
              ]}
            >
              <Select
                className="change-password-input"
                defaultValue={selectedRow?.role}
                placeholder="Select role"
              >
                {RolesData?.data &&
                  RolesData?.data.map((role) => (
                    <Select.Option key={role.id} value={role.id}>
                      {role.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Email id or phone number{" "}
                  {/* <span className="text-danger"> *</span> */}
                </span>
              }
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please enter email id or phone number",
                },
              ]}
            >
              <Input
                type="text"
                defaultValue={selectedRow?.email}
                disabled="true"
                className="change-password-input"
              />
            </Form.Item>
          </Form>
        </Col>
      )}
    </>
  );
};

export default SellerUserAdd;
