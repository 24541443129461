import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../baseQuery";

export const SellerAccountsAPI = createApi({
  reducerPath: "SellerAccountsAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getVendorDetails: builder.query({
      query: (params) => {
        return {
          url: `/api/seller/accounts/get_vendor_details`,
          params,
        };
      },
    }),
    updateSellerVender: builder.mutation({
      query: (data) => {
        const formData = new FormData();

        formData.append("mob_vendor_id", data?.bmp_id);
        formData.append("email", data?.email);
        formData.append("phone_number", data?.phone_number);
       // formData.append("address", data?.address);
      //  formData.append("location", data?.location);
       // formData.append("state", data?.state);
       // formData.append("city", data?.city);
      //  formData.append("pincode", data?.pincode);
      //  formData.append("person_full_name", data?.person_full_name);
      //  formData.append("shop_opening_time", data?.shop_opening_time);
      //  formData.append("shop_closing_time", data?.shop_closing_time);
      //  formData.append("website", data?.website);
      //  formData.append("google_map_link", data?.google_map_link);
      //  formData.append(
     //     "shop_act_registration_number",
       //   data?.shop_act_registration_number
      //  );
      //  formData.append("gst_number", data?.gst_number);
      //  formData.append("adhar_card_number", data?.adhar_card_number);
       // formData.append("pan_card_number", data?.pan_card_number);
       // formData.append("remarks", data?.remarks);
      //  formData.append("seller_group", data?.seller_group);
     //   formData.append("types_of_material", [
      //    JSON.stringify(data?.types_of_material || []),
      //  ]);
        // for (let i = 0; i < data?.types_of_material?.length; i++) {
        //   formData.append(
        //     "types_of_material[" + i + "]",
        //     data?.types_of_material[i]
        //   );
        // }
        formData.append("seller_display_image", data?.seller_display_image);
        // if (data?.bank_details) {
        //   const bankDetails = data?.bank_details;

        //   formData.append(
        //     "bank_details",
        //     JSON.stringify({
        //       bank_name: bankDetails.bank_name || "",
        //       bank_branch: bankDetails.bank_branch || "",
        //       name_of_account_holder: bankDetails.name_of_account_holder || "",
        //       bank_account_number: bankDetails.bank_account_number || "",
        //       bank_ifsc: bankDetails.bank_ifsc || "",
        //       bank_account_type: bankDetails.bank_account_type || "",
        //     })
        //   );
        // }

        return {
          url: `api/seller/accounts/update_vendor_details/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    updateSellerBankDetails: builder.mutation({
      query: (data) => {
        console.log("🚀 ~ data:", data)
        const formData = new FormData();
        
        if (data?.bank_details) {
          const bankDetails = data?.bank_details;

          formData.append(
            "bank_details",
            JSON.stringify({
              bank_name: bankDetails.bank_name || "",
              bank_branch: bankDetails.bank_branch || "",
              name_of_account_holder: bankDetails.name_of_account_holder || "",
              bank_account_number: bankDetails.bank_account_number || "",
              bank_ifsc: bankDetails.bank_ifsc || "",
              bank_account_type: bankDetails.bank_account_type || "",
            })
          );
        }

        return {
          url: `api/seller/accounts/update_vendor_details/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    updateSellerTermsDetails: builder.mutation({
      query: (data) => {
        console.log("🚀 ~ data:", data)
        const formData = new FormData();        
        if (data?.notes_and_terms) {
          const notes_and_terms = data?.notes_and_terms;
          formData.append("notes_and_terms", notes_and_terms);         
        }

        return {
          url: `api/seller/accounts/update_vendor_details/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    updateSellerQRCode: builder.mutation({
      query: (data) => {
        const formData = new FormData();

        if(data?.qr_scanner)
          {
            formData.append("qr_scanner", data?.qr_scanner);
          }
       
        if(data?.upi_id)
        {
          formData.append("upi_id", data?.upi_id);
        }
        if(data?.upi_phone_number)
          {
            formData.append("upi_phone_number", data?.upi_phone_number);
          } 
        // if (data?.bank_details) {
        //   const bankDetails = data?.bank_details;

        //   formData.append(
        //     "bank_details",
        //     JSON.stringify({
        //       bank_name: bankDetails.bank_name || "",
        //       bank_branch: bankDetails.bank_branch || "",
        //       name_of_account_holder: bankDetails.name_of_account_holder || "",
        //       bank_account_number: bankDetails.bank_account_number || "",
        //       bank_ifsc: bankDetails.bank_ifsc || "",
        //       bank_account_type: bankDetails.bank_account_type || "",
        //     })
        //   );
        // }

        return {
          url: `api/seller/accounts/update_vendor_details/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    updateSellerTimingVender: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("mob_vendor_id", data?.bmp_id);
        formData.append("shop_opening_time", data?.shop_opening_time);
        formData.append("shop_closing_time", data?.shop_closing_time);
        return {
          url: `api/seller/accounts/update_vendor_details/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    changePassword: builder.mutation({
      query: (params) => ({
        url: "/api/seller/forget_password/",
        method: "POST",
        body: params,
      }),
    }),

    getVendorUserDetails: builder.query({
      query: (params) => {
        return {
          url: "/api/seller/accounts/get_subadmins/",
          params,
        };
      },
    }),
    UserCreation: builder.mutation({
      query: (params) => ({
        url: "/api/seller/accounts/create_subadmin/",
        method: "POST",
        body: params,
      }),
    }),
    getUserRolesDetails: builder.query({
      query: (params) => {
        return {
          url: "/api/seller/accounts/get_all_groups/",
          params,
        };
      },
    }),
    UserDelete: builder.mutation({
      query: (sub_admin_id) => ({
        url: "/api/seller/accounts/delete_subadmin/",
        method: "DELETE",
        body: sub_admin_id,
      }),
    }),
    UserRoleUpdateCreation: builder.mutation({
      query: (params) => ({
        url: "/api/seller/accounts/add_permissions_to_user/",
        method: "POST",
        body: params,
      }),
    }),
    updateProfilePic: builder.mutation({
      query: (data) => {
        const formData = new FormData();

        if(data?.profile_image)
          {
            formData.append("profile_image", data?.profile_image);
          }
       
        if(data?.name)
        {
          formData.append("name", data?.name);
        }
        if(data?.email)
          {
            formData.append("email", data?.email);
          } 
        
        return {
          url: `/api/seller/dashboard/update_profile/`,
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useLazyGetVendorDetailsQuery,
  useUpdateSellerVenderMutation,
  useChangePasswordMutation,
  useUpdateSellerTimingVenderMutation,
  useLazyGetVendorUserDetailsQuery,
  useUserCreationMutation,
  useUserDeleteMutation,
  useLazyGetUserRolesDetailsQuery,
  useUserRoleUpdateCreationMutation,
  useUpdateSellerQRCodeMutation,
  useUpdateProfilePicMutation,
  useUpdateSellerBankDetailsMutation,
  useUpdateSellerTermsDetailsMutation
  
} = SellerAccountsAPI;

export default SellerAccountsAPI;
