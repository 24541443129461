import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Typography,
  Card,
  Collapse,
  Select,
  Modal,
} from "antd";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import {
//   showErrorToast,
//   showSuccessToast,
// } from "../../../NotificationToast/NotificationToast";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useRequestLineOfCreditMutation } from "../../../apis/Seller/RequestMobCreditApi";
import MobBannerIcon from "../../../assets/seller/icons/MobActivation_Banner.webp";
import MoneyHandLogo from "../../../assets/seller/icons/money-hand.webp";
import { ReactComponent as LampLogo } from "../../../assets/seller/icons/lamp.svg";
import { ReactComponent as IndiaFlagLogo } from "../../../assets/seller/icons/india_flag.svg";
import RupifyLogo from "../../../assets/seller/icons/rupify_nobg.webp";
import MuthoothLogo from "../../../assets/seller/icons/muthooth.svg";
import MobCreditBlackIcon from "../../../assets/seller/icons/mobcredit-black.svg";
import liquilogo from "../../../assets/seller/icons/liquilogo.webp";
import { ReactComponent as MobCreditIcon } from "../../../assets/seller/icons/Mobcreditlogo.svg";
import FinalSubmit from "../../../assets/seller/icons/finalSubmit.svg";
import Whattoexpect from "./whattoexpect";
import CustomInput from "./CustomInput";
import Rejectclients from "./rejectclients";
import AboutMobCredit from "./AboutMobCredit";
import CommercialsTerms from "./CommercialsTerms";
import Howtouse from "./howtouse";
import MobCreditVideo from "./MobCreditVideo";

const CustomerMobCreditOnboarding = () => {
  
  const Text = Typography;
  const mobileRegex = /^\d{10}$/;
  const gstRegex = /^(?:[0-3|9][0-9])[a-zA-Z0-9]{13}$/;
  const bmp_id = localStorage.getItem("bmp_id");
  // const nameRegex = /^[a-zA-Z\s]{3,}$/;
  const navigate = useNavigate();
  const [requsetMobFormDetails, setRequsetMobFormDetails] = useState({});
  const [isSubmitClick, setIsSubmitClick] = useState(false);
  const [OpenMOBRequestSubmittedModal, setOpenMOBRequestSubmittedModal] =
    useState(false);
  const WHAT_REASON_LIST = [
    {
      value: "Architect",
      label: "Architect",
    },
    {
      value: "Contractor",
      label: "Contractor",
    },
    {
      value: "Builder",
      label: "Builder",
    },

    {
      value: "Other",
      label: "Other",
    },
    {
      value: "Interior designer",
      label: "Interior Designer",
    },
    {
      value: "Civil engineers",
      label: "Civil Engineers",
    },
  ];
  const checkEmptyValidation = (value) => {
    if (value && value !== "") {
      return true;
    } else {
      return false;
    }
  };
  const handleSendRequest = () => {
    if (
      // checkEmptyValidation(requsetMobFormDetails?.name) &&
      checkEmptyValidation(requsetMobFormDetails.business_name) &&
      checkPhoneNumberValidation(requsetMobFormDetails?.phone_number) &&
      checkgstValidation(requsetMobFormDetails?.gst)
    ) {
      const updatedFormDetails = { ...requsetMobFormDetails, bmp_id: bmp_id };
      requestMobCredit({ body: updatedFormDetails });
    } else {
      setIsSubmitClick(true);
    }
  };
  const checkPhoneNumberValidation = (value) => {
    return mobileRegex.test(value);
  };
  const checkgstValidation = (value) => {
    if (gstRegex.test(value) && value !== "" && value !== undefined) {
      return true;
    } else {
      return false;
    }
  };
  const handleSelectChange = (e, key) => {
    setRequsetMobFormDetails({
      ...requsetMobFormDetails,
      [key]: e,
    });
  };

  const [requestMobCredit, { isSuccess: successToGetMobCredit, isLoading }] =
    useRequestLineOfCreditMutation();
  useEffect(() => {
    if (successToGetMobCredit) {
      setIsSubmitClick(false);
      setRequsetMobFormDetails({});
      setOpenMOBRequestSubmittedModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successToGetMobCredit]);
  const handleInputChange = (event) => {
    if (event.target.name === "phone_number") {
      if (event.target.value === "") {
        setRequsetMobFormDetails({
          ...requsetMobFormDetails,
          [event.target.name]: "",
        });
      } else if (parseInt(event.target.value)) {
        setRequsetMobFormDetails({
          ...requsetMobFormDetails,
          [event.target.name]: parseInt(event.target.value),
        });
      }
    } else if (event.target.name === "gst") {
      if (event.target.value === "" || /^[0-9]{2}$/.test(event.target.value)) {
        setRequsetMobFormDetails({
          ...requsetMobFormDetails,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
      if (
        event.target.value === "" ||
        /^[0,1,2,3,9]{0,1}$/.test(event.target.value)
      ) {
        setRequsetMobFormDetails({
          ...requsetMobFormDetails,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }
      if (
        event.target.value === "" ||
        /^[0,1,2,3,9][0-9][a-zA-Z0-9]{1,}$/.test(event.target.value)
      ) {
        setRequsetMobFormDetails({
          ...requsetMobFormDetails,
          [event.target.name]: event.target.value.toUpperCase(),
        });
      }

      // if (!/[!@#$%^&*()_+]/.test(event.target.value)) {
      //   setRequsetMobFormDetails({
      //     ...requsetMobFormDetails,
      //     [event.target.name]: event.target.value.toUpperCase(),
      //   });
      // }
    } else {
      setRequsetMobFormDetails({
        ...requsetMobFormDetails,
        [event.target.name]: event.target.value,
      });
    }
  };
  const items = [
    {
      key: "1",
      label: "What expectations to set for your customer (with GSTIN)",
      children: <Whattoexpect />,
    },
    {
      key: "2",
      label: "About mobCREDIT",
      children: <AboutMobCredit />,
    },
    {
      key: "3",
      label: "Commercials & terms",
      children: <CommercialsTerms />,
    },
    {
      key: "4",
      label: "How to use mobCREDIT",
      children: <Howtouse />,
    },
    {
      key: "5",
      label: "How to handle REJECTED clients",
      children: <Rejectclients />,
    },
  ];

  const handleBack = () => {
    navigate("/seller/sellerdashboard");
  };
  return (
    <>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          md={{ span: 24, offset: 0 }}
          lg={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
        >
          <Col>
            <Text>
              <Button
                icon={<ArrowLeftOutlined />}
                onClick={handleBack}
                className="view-detail-btn"
              >
                BACK TO DASHBOARD
              </Button>
            </Text>
          </Col>

          <Row className="mt-4">
            <Col>
              <Row>
                <Col xs={24} sm={12} md={8} lg={8} xl={24}>
                  <Text className="txt-getstarted">
                    Request for mobCREDIT for your client
                  </Text>
                  <Row gutter={[16, 16]} className="cart-return-reason-parent mt-4">
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <CustomInput
                        label={
                          <Row className="inputLabelParent">
                            <Text className="inputLabelContent">
                              Business name
                            </Text>{" "}
                            <Text className="text-danger">*</Text>
                          </Row>
                        }
                        inputClass={`mt-2 signup-modal-inp ${
                          isSubmitClick &&
                          !checkEmptyValidation(
                            requsetMobFormDetails.business_name
                          ) &&
                          "request-price-inp-err"
                        }`}
                        ContainerClass={"inputParent"}
                        labelClass={"address-mdoal-input-label"}
                        value={requsetMobFormDetails?.business_name}
                        name={"business_name"}
                        handleInputChange={(event) => handleInputChange(event)}
                      />
                      <Col className="mt-4 p-0">
                        <CustomInput
                          maxLength={10}
                          label={
                            <Row className="inputLabelParent">
                              <Text className="inputLabelContent">
                                Business mobile (for OTP){" "}
                              </Text>
                              <Text className="text-danger">*</Text>
                            </Row>
                          }
                          inputClass={`mt-2 signup-modal-inp ${
                            isSubmitClick &&
                            !checkPhoneNumberValidation(
                              requsetMobFormDetails.phone_number
                            ) &&
                            "request-price-inp-err"
                          }`}
                          ContainerClass={"inputParent"}
                          labelClass={"address-mdoal-input-label"}
                          value={requsetMobFormDetails?.phone_number}
                          name={"phone_number"}
                          handleInputChange={(event) =>
                            handleInputChange(event)
                          }
                        />
                      </Col>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <CustomInput
                        label={
                          <Row className="inputLabelParent">
                            <Text className="inputLabelContent">GSTIN</Text>
                            <Text className="text-danger"> *</Text>
                          </Row>
                        }
                        ContainerClass={"inputParent"}
                        inputClass={`mt-2 signup-modal-inp ${
                          isSubmitClick &&
                          !checkgstValidation(requsetMobFormDetails.gst) &&
                          "request-price-inp-err"
                        }`}
                        labelClass={"address-mdoal-input-label "}
                        value={requsetMobFormDetails?.gst}
                        name={"gst"}
                        maxLength={15}
                        handleInputChange={(event) => handleInputChange(event)}
                      />
                      <Row className="reason-dropdown-parent mt-4  p-2">
                        <Text className="inputLabelContent">
                          Please select what defines your client (Optional)
                        </Text>
                        <Select
                          className={`mob-credit-form-select mt-2 select-full`}
                          value={requsetMobFormDetails?.what_defines}
                          name={"what_defines"}
                          placeholder={"Select"}
                          onChange={(e) =>
                            handleSelectChange(e, "what_defines")
                          }
                          options={WHAT_REASON_LIST}
                        />
                      </Row>
                      <Button
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={handleSendRequest}
                        className="submitButton mt-4"
                      >
                        SEND REQUEST
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={16} type="flex" className="mt-4">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className="text-center"
                >
                  <img src={MobBannerIcon} className="banner-top" alt="" />
                </Col>
              </Row>
              <Row gutter={[16,16]} type="flex" className="mt-4">
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Card className="card-credit-section">
                    <MobCreditIcon className="credit-icon" />
                    <Col>
                      <Typography.Title level={3}>
                        1st time in India <IndiaFlagLogo   className="india-flag-icon" />
                      </Typography.Title>
                      <Text className="made-india">MADE IN INDIA</Text>
                    </Col>

                    <Col className="mt-4 mb-2 backed-text">Backed by:</Col>
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "12px",
                        flexWrap:"wrap"
                      }}
                    >
                      <img alt="" src={MuthoothLogo}></img>
                      <img
                        alt=""
                        src={liquilogo}
                        style={{ height: "100%" }}
                      ></img>
                      <img alt="" src={MobCreditBlackIcon}></img>
                      <img alt="" src={RupifyLogo}></img>
                    </Col>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <Card className="card-tips-section">
                    <Col span={24} className="p-0">                    
                      <Text className="text-heading-sm">
                        <LampLogo /> Did you know?
                      </Text>
                    </Col>
                    <Col span={24} className="p-0">
                      <Text className="text-heading-sm-details">
                        4 Lakh crores or USD 50 billion dollars is given out in
                        line of credit to architects, contractors & builders in
                        this sector of construction and interior materials by
                        the suppliers like you on their own books
                      </Text>
                      <Col>
                        {" "}
                        <img
                          alt=""
                          className="money-hand"
                          src={MoneyHandLogo}
                        ></img>
                      </Col>
                    </Col>
                  </Card>
                </Col>
              </Row>
              <Text className="text-subtext">
                In accordance with RBI regulations
              </Text>
            </Col>
          </Row>
          <Row className="mt-4">
        <Typography.Title level={4}>How does mobCREDIT work?</Typography.Title>
      
        </Row>
        <MobCreditVideo/>
          <Row className="mt-6">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Collapse
                className="collapse-section"
                accordion
                expandIconPosition="end"
                defaultActiveKey={1}
              >
                {items.map((item) => (
                  <Collapse.Panel key={item.key} header={item.label}>
                    {item.children}
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        className="order-payment-style"
        title={<Text></Text>}
        visible={OpenMOBRequestSubmittedModal}
        onCancel={() => setOpenMOBRequestSubmittedModal(false)}
        footer={[]}
      >
        <div className="padding-16px">
          <Row className="submissionModalParent">
            <Row className="submissionModalImageContentParent ">
              <img src={FinalSubmit} height={130} width={130} alt="" />

              <Row className="submissionModalContentParent">
                <Text className="submissionModalTitle">
                  Your request has been submitted!
                </Text>
                <Text className="submissionModalDesc">
                  Our support member will get in touch with you shortly.
                  <br /> For further assistance please call/ whatsapp at{" "}
                  <a
                    href="https://wa.me/918970415365"
                    rel="noreferrer"
                    target="_blank"
                  >
                    +91 8970415365
                  </a>
                  .<br />
                  You can also reach us through mail at{" "}
                  <a href="mailto:ask@madoverbuildings.com">
                    ask@madoverbuildings.com
                  </a>
                </Text>
              </Row>

              <Button
                className="editAddressFooterButton"
                onClick={() => setOpenMOBRequestSubmittedModal(false)}
              >
                OK
              </Button>
            </Row>
          </Row>
        </div>
      </Modal>      
    </>
  );
};

export default CustomerMobCreditOnboarding;
