import React,{useState} from "react";
import { Col, Typography, Row, Card,Modal,Button } from "antd";
import "./SellerMobCreditOnboarding.css";
import Video1Logo from "../../../assets/seller/icons/video1.webp";
import Video2Logo from "../../../assets/seller/icons/video2.webp";
import Video3Logo from "../../../assets/seller/icons/video3.webp";
import {ReactComponent as PlayLogo} from "../../../assets/seller/icons/youtubeplay.svg";
// const { Text } = Typography;

const MobCreditVideo = (props) => {
  //   const {

  //   } = props;
  // eslint-disable-next-line
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVideoId, setModalVideoId] = useState('');
  const [modalVideoTitle, setmodalVideoTitle] = useState('');
  const [autoPlay, setautoPlay] = useState("");
  const handleModalOpen = (videoId,videoTitle) => {
    setModalVisible(true);
    setModalVideoId(videoId);
    setmodalVideoTitle(videoTitle);
    setautoPlay(`https://www.youtube.com/embed/${videoId}?autoplay=0`);
  };
  return (
    <>
      <Col
        className="mt-2"
      
      >
       {/* <iframe width="100%" height="315" src="https://www.youtube.com/embed/SpFemJFcfa4?si=gOI4buCStfYI90xm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
       
        {/* <Row gutter={[16,16]} className="mt-4">
          <Col xs={12} sm={12} md={12} lg={12} xl={8}>
            <Card className="card-content p-0">
           
            <Typography.Title className="" level={5}>Easy registration with GSTIN (36 secs)</Typography.Title>
            </Card>
            
          </Col>
        
          <Col xs={12} sm={12} md={12} lg={12} xl={8}>
            <Card className="card-content p-0">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/3AakpS6zLZY?si=da2yONAgYuTQh6SA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <Typography.Title level={5}>Activate Line of Credit (88 secs)</Typography.Title>
            </Card>
           
          </Col>
     
          <Col xs={12} sm={12} md={12} lg={12} xl={8}>
            <Card className="card-content p-0">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/NFy5mCrdZgY?si=_s2yV_HnRqHuNkzd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <Typography.Title level={5}>Manage store sales (36 secs)</Typography.Title>
            </Card>
            
          </Col>
        </Row> */}
        <Row gutter={[16, 16]} className="mt-4">
  <Col xs={24} sm={12} md={12} lg={12} xl={8}>
    <Card className="video-card-content  p-0 mb-2" onClick={() => handleModalOpen('SpFemJFcfa4','Easy registration with GSTIN (36 secs)')}>
      <img width={"100%"} src={Video1Logo} alt="Easy registration with GSTIN (36 secs)"></img>
      <Typography.Title className="mt-2"  level={5}>Easy registration with GSTIN (36 secs)</Typography.Title>
      <div className="d-flex justify-content-center cls-center-img">
    <Button type="text"><PlayLogo/></Button>
  </div>
    </Card>
   
  </Col>

  <Col xs={24} sm={12} md={12} lg={12} xl={8}>
    <Card className="video-card-content  p-0 mb-2" onClick={() => handleModalOpen('3AakpS6zLZY','Activate Line of Credit (88 secs)')}>
    <img width={"100%"} src={Video2Logo} alt="Activate Line of Credit (88 secs)"></img>
    <Typography.Title  className="mt-2"  level={5}>Activate Line of Credit (88 secs)</Typography.Title>
    <div className="d-flex justify-content-center cls-center-img">
    <Button type="text"><PlayLogo/></Button>
  </div>
    </Card>
    
  </Col>

  <Col xs={24} sm={12} md={12} lg={12} xl={8}>
    <Card className="video-card-content p-0 mb-2" onClick={() => handleModalOpen('NFy5mCrdZgY','Manage store sales (36 secs)')}>
    <img width={"100%"} src={Video3Logo} alt="Manage store sales (36 secs)"></img>
    <Typography.Title className="mt-2"  level={5}>Manage store sales (36 secs)</Typography.Title>
    <div className="d-flex justify-content-center cls-center-img">
    <Button type="text"><PlayLogo/></Button>
  </div>
    </Card>
   
  </Col>

  <Modal
  className="youtube-modal"
  width={"75%"} // increase width to 800px
  height={"75%"} // increase height to 600px
  wrapClassName="modal-center"
    title={modalVideoTitle}
    visible={modalVisible}
    onCancel={() => {
        setModalVisible(false);
        document.getElementById("youtube-iframe").src = "";         
        document.getElementById("youtube-iframe").src = autoPlay;  
      }}
    footer={null}
  >
    {modalVisible && (
      <iframe
        id="youtube-iframe"
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${modalVideoId}?autoplay=1`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
        onClick={() => {
            const iframe = document.getElementById('youtube-iframe');
            iframe.requestFullscreen();
          }}
      />
    )}
  </Modal>
</Row>
        
      </Col>
    </>
  );
};

export default React.memo(MobCreditVideo);
