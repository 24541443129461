import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sellerlanding.css";
import "animate.css";
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import Marquee from "react-fast-marquee";
import {
  Layout,
  Typography,
  Button,
  Row,
  Col,
  Card,
  Collapse,
  Carousel,
  Tabs,
  Modal,
} from "antd";

import { ReactComponent as PartnerLogoIcon } from "../../../assets/seller/icons/mobPartnerlogo_black.svg";

// eslint-disable-next-line
import BannerIcon from "../../../assets/seller/icons/topbanner-image.webp";

// eslint-disable-next-line
import SKUAddIcon from "../../../assets/seller/icons/SKUAdd.webp";
import Flag from "../../../assets/seller/icons/national-flag.png";
import Globe from "../../../assets/seller/icons/globe-icon.svg";
import BrandLogo1 from "../../../assets/seller/icons/brand-logo-1.png";
import BrandLogo2 from "../../../assets/seller/icons/brand-logo-2.svg";
import BrandLogo3 from "../../../assets/seller/icons/brand-logo-3.svg";
import BrandLogo4 from "../../../assets/seller/icons/brand-logo-4.svg";
import BrandLogo5 from "../../../assets/seller/icons/brand-logo-5.svg";
import BrandLogo6 from "../../../assets/seller/icons/brand-logo-6.svg";
import BrandLogo7 from "../../../assets/seller/icons/brand-logo-7.svg";
import BrandLogo8 from "../../../assets/seller/icons/philips-logo.svg";
import BrandLogo9 from "../../../assets/seller/icons/brand-logo-9.svg";
import BrandLogo10 from "../../../assets/seller/icons/brand-logo-10.svg";
import BrandLogo11 from "../../../assets/seller/icons/centuryplay-logo.svg";
import BrandLogo12 from "../../../assets/seller/icons/brand-logo-12.svg";
import { ReactComponent as AssuredpaymentsIcon } from "../../../assets/seller/icons/Assuredpayments.svg";
import { ReactComponent as GreenTickIcon } from "../../../assets/seller/icons/greentick.svg";
import { ReactComponent as PlayLogo } from "../../../assets/seller/icons/youtubeplay.svg";
import LoCIcon from "../../../assets/seller/icons/LoCCreation.webp";
import Video1Logo from "../../../assets/seller/icons/HomeVideo1.webp";
import Video2Logo from "../../../assets/seller/icons/HomeVideo2.webp";
import Video3Logo from "../../../assets/seller/icons/HomeVideo3.webp";
import CashFlowIcon from "../../../assets/seller/icons/CashFlow.webp";
import MuthoothLogo from "../../../assets/seller/icons/muthooth.svg";
import MobCreditBlackIcon from "../../../assets/seller/icons/mobcredit-black.svg";
import { ReactComponent as PlusIcon } from "../../../assets/seller/icons/plus.svg";
// eslint-disable-next-line
import liquilogo from "../../../assets/seller/icons/liquilogo.webp";
import RupifyLogo from "../../../assets/seller/icons/rupify_nobg.webp";
import MuthoothblackLogo from "../../../assets/seller/icons/muthooth_black.webp";
import liquiBlacklogo from "../../../assets/seller/icons/liqui_black.webp";
import { ReactComponent as IndiaFlagLogo } from "../../../assets/seller/icons/india_flag_full.svg";
import { ReactComponent as CreditIcon } from "../../../assets/seller/icons/mobCreditAmount.svg";
import { ReactComponent as RepaymentIcon } from "../../../assets/seller/icons/repayment.svg";

import PartnerUserIcon from "../../../assets/seller/icons/partner-user.webp";
import PartnerUserNoBgIcon from "../../../assets/seller/icons/partner-user-nobg.webp";
import { ReactComponent as TickIcon } from "../../../assets/seller/icons/shieldtick.svg";
import linkedin from "../../../assets/seller/icons/linkedin.svg";
import facebook from "../../../assets/seller/icons/facebook.svg";
import instagram from "../../../assets/seller/icons/instagram.svg";
import twitter from "../../../assets/seller/icons/twitter.svg";
// eslint-disable-next-line
import callicon from "../../../assets/seller/icons/call-us-icon.svg";
// eslint-disable-next-line
import testimonail1 from "../../../assets/seller/icons/testi-img.jpg";
// import PlayIcon from "../../../assets/seller/icons/play-icon-img.svg";
// eslint-disable-next-line
import QuoteIcon from "../../../assets/seller/icons/quotes-img.png";
import { ReactComponent as CustomercreditIcon } from "../../../assets/seller/icons/customer_credit.svg";
import Mobcreditlogo from "../../../assets/seller/icons/Mobcreditlogo.svg";
// eslint-disable-next-line
import Rupifylogo from "../../../assets/seller/icons/rupifylogo.webp";
import RupifyBlacklogo from "../../../assets/seller/icons/Rupify_black.webp";

import HidecorLogo from "../../../assets/seller/icons/hidecor.webp";
import YaahviLogo from "../../../assets/seller/icons/yahhvi.webp";
import sandharbhLogo from "../../../assets/seller/icons/sandharbh.webp";
import MysankalpLogo from "../../../assets/seller/icons/mysankalp.webp";
import MysankalpAuthorLogo from "../../../assets/seller/icons/mysankalp_author.webp";
import HidecoreAuthorLogo from "../../../assets/seller/icons/hidecor_author.webp";
import yashviauthorLogo from "../../../assets/seller/icons/yashviauthor.webp";
import SandarbauthorLogo from "../../../assets/seller/icons/sandarb_author.webp";
import RazorPayIcon from "../../../assets/seller/icons/razorpay-black.webp";

import CreditActivelogo from "../../../assets/seller/icons/mobCredit_active.webp";
import CreditRejectlogo from "../../../assets/seller/icons/mobCredit_Reject.webp";
import CreditApplylogo from "../../../assets/seller/icons/mobCredit_Apply.webp";
const { TabPane } = Tabs;
const { Header, Content, Footer } = Layout;

const Text = Typography;
const Seller = () => {
  useEffect(() => {
    // Set the title when the component mounts
    document.title = "MOB | Seller Portal";
  }, []);
  const [modalVideoUrl, setModalVideoUrl] = useState('');
  const [activeKey, setActiveKey] = useState("1");
  const [modalVisible, setModalVisible] = useState(false);
  const [autoPlay, setautoPlay] = useState("");
  // eslint-disable-next-line 
  const [modalVideoId, setModalVideoId] = useState("");
  const [modalVideoTitle, setmodalVideoTitle] = useState("");
  useEffect(() => {
    const wow = new WOW.WOW({
      // Configure WOW.js options
      animateClass: "animated", // CSS class name for animations
      offset: 100, // Distance in pixels to trigger the animation
    });
    wow.init();
  }, []);
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleModalOpen = (videoId, videoTitle) => {
    setModalVisible(true);
    setModalVideoId(videoId);
    setmodalVideoTitle(videoTitle);
    setautoPlay(`https://www.youtube.com/embed/${videoId}?autoplay=0`);
    setModalVideoUrl(`https://www.youtube.com/embed/${videoId}?autoplay=1`);
  };
  const testimonialsData = [
    {
      quote:
        "mobCREDIT has been a game-changer for our material procurement process. It streamlines cash flow, eliminates delays, and ensures seamless project execution. It’s an essential tool for any architect focused on efficiency.",
      author: "Anantha VR",
      position: "Hidecor",
      Logo: HidecorLogo,
      logoclass: "HidecorLogo",
      authorimage: HidecoreAuthorLogo,
      firmtype: "Interior Design firm",
    },
    {
      quote:
        "mobCREDIT has simplified the process, eliminating the hassle of constantly requesting credit from suppliers. Now, we focus on what matters most—building! Highly recommend it!",
      author: "Sankalp Solanki",
      position: "My Sankalp",
      Logo: MysankalpLogo,
      logoclass: "MysankalpLogo",
      authorimage: MysankalpAuthorLogo,
      firmtype: "Home & Office Construction",
    },
    {
      quote:
        "Initially, we didn’t think we needed it, but mobCREDIT has greatly simplified payments and boosted supplier trust. With broader adoption by suppliers, it will ease procurement.",
      author: "Amith Shetty",
      position: "Yaahvi Homes",
      Logo: YaahviLogo,
      logoclass: "YaahviLogo",
      authorimage: yashviauthorLogo,
      firmtype: "Construction & Interior firm",
    },
    {
      quote:
        "Each transaction is securely authorised via OTP, giving us control and peace of mind. Ability to use this with independent suppliers is excellent. No more sudden follow ups from the suppliers for repayments.",
      author: "Sairaam Rai",
      position: "Sandarbh Design Studio",
      Logo: sandharbhLogo,
      logoclass: "sandharbhLogo",
      authorimage: SandarbauthorLogo,
      firmtype: "Home & Office Construction",
    },
  ];

  return (
    // <div>
    //   <header className="header-top-section fixed-top wow fadeIn">
    //     <div className="container">
    //       <div className="d-none d-lg-block d-md-block">
    //         <div className="row align-items-center justify-content-center">
    //           <div className="col-lg-8">
    //             <a href="/seller">
    //               <img src={partnerLogoIcon} alt="" />
    //             </a>
    //           </div>
    //           <div className="col-lg-4 d-flex justify-content-end">
    //             <a
    //               href="/SellerLogin"
    //               target="_blank"
    //               className="register-style-btn"
    //             >
    //               Register now
    //             </a>
    //             <a
    //               href="/SellerLogin"
    //               className="btn login-btn-style"
    //               style={{ background: "#fff !important" }}
    //             >
    //               Login
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="d-block d-lg-none d-md-none">
    //         <div className="row d-flex align-items-center">
    //           <div className="col-4">
    //             <a href="/seller">
    //               <img
    //                 src={partnerLogoIcon}
    //                 className="mobile-logo-size"
    //                 alt=""
    //               />
    //             </a>
    //           </div>
    //           <div className="col-8 text-right d-flex" style={{alignItems: "center"}}>
    //             <a
    //               href="/SellerLogin"
    //               target="_blank"
    //               className="btn btn-primary register-style-btn"
    //             >
    //               Register now
    //             </a>
    //             <a href="/Sellerlogin" className="btn login-btn-style">
    //               Login
    //             </a>
    //           </div>
    //           {/* <div className="col-3 text-right">

    //           </div> */}
    //         </div>
    //       </div>
    //     </div>
    //   </header>

    //   <section className="banner-section d-flex align-items-center">
    //     <div className="container">
    //       <div className="padding-size d-none d-lg-block d-md-block d-xl-block">
    //         <div className="row">
    //           <div className="col-md-7">
    //             <div className="row margin-top-padding">
    //               <div className="col-md-12 banner-toppadding">
    //                 {/* <button type="button" className="btn btn-primary ">FREE FOREVER</button> */}
    //                 <h1 className="bannertext">
    //                   Complete{" "}
    //                   <span className="text-default-mob text-bold">Sales Management</span>{" "}
    //                 </h1>
    //                 <p className="bannertext">
    //                   enabled with{" "}
    //                   <span className="text-default-mob text-bold">Line of Credit</span>
    //                 </p>
    //               </div>
    //             </div>

    //             <div className="row" style={{ paddingBottom: "30px" }}>
    //               <div className="col-md-9 banner-content">
    //                 <div className="form-controll-click text-left">
    //                   <p className="text-cashflow">Unlock your cashflow</p>
    //                   {/* <input
    //                     type="text"
    //                     id="txtMobileNo"
    //                     className="form-control position-relative"
    //                     aria-describedby=""
    //                     maxLength="10"
    //                     minLength="10"
    //                     placeholder="Enter your mobile number"
    //                   />
    //                   <label>+91</label> */}
    //                   <a
    //                     href="/SellerLogin"
    //                     target="_blank"
    //                     className="btn btn-primary register-now-btn"
    //                     id="btnRegister"
    //                   >
    //                     Register now
    //                   </a>
    //                 </div>
    //                 <p className="register-style-text">
    //                   <span className="text-powerdby"> POWERED BY</span>
    //                   <img
    //                     src={Mobcreditlogo}
    //                     className="mobile-logo-size"
    //                     alt=""
    //                   />
    //                   <img
    //                     src={Rupifylogo1}
    //                     className="mobile-logo-size"
    //                     alt=""
    //                   />
    //                 </p>
    //               </div>
    //             </div>
    //           </div>
    //           {/* <div className="col-md-5 d-flex align-items-end">
    //             <div className="slider-bg">
    //               <img src={BannerIcon} className="" alt="" />
    //             </div>
    //           </div> */}
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    //   <section className="mobile-banner-section d-block d-lg-none d-md-none d-xl-none">
    //     <div className="container">
    //       <div className="col-md-12 free-ever-padding">
    //         <div className="row">
    //           <div className="col-12">
    //             {/* <button type="button" className="btn btn-primary free-forever-style">FREE FOREVER</button> */}
    //             <h1>
    //               Complete{" "}
    //               <span className="text-default-mob text-bold">Sales Management</span>{" "}
    //             </h1>
    //             <h3>
    //               enabled with{" "}
    //               <span className="text-default-mob text-bold">Line of Credit</span>
    //             </h3>
    //           </div>
    //         </div>

    //         <div className="row mobile-banner-content">
    //           <div className="col-12">
    //             <div className="form-controll-click text-left">
    //             <p className="text-cashflow ">Unlock your cashflow</p>
    //               {/* <input
    //                 type="text"
    //                 className="form-control position-relative"
    //                 aria-describedby=""
    //                 placeholder="Enter your mobile number"
    //               />
    //               <label>+91</label> */}
    //             </div>
    //           </div>

    //           <div className="col-12 ">
    //             <a
    //               type="button"
    //               href="/SellerLogin"
    //               target="_blank"
    //               className="btn btn-primary register-now-btn "
    //               id="btnRegistermobile"
    //             >
    //               Register now
    //             </a>
    //             <p className="register-style-text mobile-text-style">
    //             <span className="text-powerdby"> POWERED BY</span>
    //                   <img
    //                     src={Mobcreditlogo}
    //                     className="mobile-logo-size"
    //                     alt=""
    //                   />
    //                   <img
    //                     src={Rupifylogo1}
    //                     className="mobile-logo-size"
    //                     alt=""
    //                   />
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    //   <section className="over-buildings-section position-relative">
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-lg-12">
    //           <h1>Grow your construction & interior material business</h1>
    //         </div>
    //       </div>
    //       <div className="row">
    //         <div className="col-lg-7">
    //           <div className="row mobile-view-bottom">
    //             <div className="col-lg-2 col-2 d-flex align-items-center">
    //               <h4 className="number-buildings">1</h4>
    //             </div>
    //             <div className="col-lg-10 col-10">
    //               <div className="register-text-style">
    //                 <h2>Register now</h2>
    //                 <h6>
    //                   Register with your name, phone number
    //                   <br />
    //                   and email
    //                 </h6>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="number-padding-class">
    //             <div className="row mobile-view-bottom">
    //               <div className="col-lg-2 col-2 d-flex align-items-center">
    //                 <h4 className="number-buildings">2</h4>
    //               </div>
    //               <div className="col-lg-10 col-10">
    //                 <div className="register-text-style">
    //                   <h2>List products</h2>
    //                   <h6>
    //                     {" "}
    //                     List your products directly or use MOB
    //                     <br /> library of 100+ brands and 25,000+ SKUs
    //                   </h6>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="number-padding-class">
    //             <div className="row mobile-view-bottom">
    //               <div className="col-lg-2 col-2 d-flex align-items-center">
    //                 <h4 className="number-buildings">3</h4>
    //               </div>
    //               <div className="col-lg-10 col-10">
    //                 <div className="register-text-style">
    //                   <h2>Use as Billing and management software</h2>
    //                   <h6>
    //                     {" "}
    //                     Register with GST/PAN details and
    //                     <br /> an active bank account
    //                   </h6>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="number-padding-class">
    //             <div className="row mobile-view-bottom">
    //               <div className="col-lg-2 col-2 d-flex align-items-center">
    //                 <h4 className="number-buildings">4</h4>
    //               </div>
    //               <div className="col-lg-10 col-10">
    //                 <div className="register-text-style">
    //                   <h2>Get verified to sell online with MOB</h2>
    //                   <h6>
    //                     {" "}
    //                     Register with GST/PAN details and
    //                     <br /> an active bank account
    //                   </h6>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="number-padding-class">
    //             <div className="row">
    //               <div className="col-lg-2 col-2 d-flex align-items-center">
    //                 <h4 className="number-buildings">5</h4>
    //               </div>
    //               <div className="col-lg-10 col-10">
    //                 <div className="register-text-style">
    //                   <h2>Receive orders and get payments</h2>
    //                   <h6>
    //                     {" "}
    //                     Start getting orders from your area. Get paid to your
    //                     account on completion of sales.
    //                   </h6>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="col-lg-5 register-background-img">
    //           <div className="wow fadeInRight">
    //             <img
    //               src={DetailsSection}
    //               className="details-img-style w-100"
    //               alt="Details Section Background"
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    //   <section className="demo-img-section d-block d-lg-none d-xl-none d-md-none">
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-lg-12 p-0">
    //           <img src={shortimage} className="w-100" alt="" />
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    //   {/* <section className="suppliers-section d-none d-md-block d-lg-block d-xl-block">
    //         <div className="container">
    //             <div className="row">
    //                 <div className="col-lg-12">
    //                     <h1>What our suppliers are saying</h1>
    //                 </div>
    //             </div>
    //             <div className="row">
    //                 <div className="col-lg-12">
    //                     <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
    //                         <div className="carousel-inner testimonails-style">
    //                         <div className="carousel-item active">
    //                             <div className="row">
    //                                 <div className="col-lg-3">
    //                                     <div className="testimonails-img position-relative">
    //                                         <img src={testimonail1} alt="" />

    //                                     </div>
    //                                 </div>
    //                                 <div className="col-lg-9">
    //                                     <div className="testi-box-size-padding">
    //                                         <img src={QuoteIcon} alt="" />
    //                                         <p>“ Mad over buildings rental is super awesome! It was delivered in time and was hassle free to book. It was a great aid to the progress of our project “ </p>
    //                                         <h5 className="testi-name-style">Eric Johnson</h5>
    //                                         <h6 className="testi-company-name">J&amp;M Constructors limited</h6>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="carousel-item">
    //                             <div className="row">
    //                                 <div className="col-lg-3">
    //                                     <div className="testimonails-img position-relative">
    //                                         <img src={testimonail1} alt="" />

    //                                     </div>
    //                                 </div>
    //                                 <div className="col-lg-9">
    //                                     <div className="testi-box-size-padding">
    //                                         <img src={QuoteIcon} alt="" />
    //                                         <p>“ Mad over buildings rental is super awesome! It was delivered in time and was hassle free to book. It was a great aid to the progress of our project “ </p>
    //                                         <h5 className="testi-name-style">Eric Johnson</h5>
    //                                         <h6 className="testi-company-name">J&amp;M Constructors limited</h6>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         </div>
    //                         <button className="carousel-control-prev testi-style" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    //                             <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    //                             <span className="visually-hidden">Previous</span>
    //                         </button>
    //                         <button className="carousel-control-next testi-style" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    //                             <span className="carousel-control-next-icon" aria-hidden="true"></span>
    //                             <span className="visually-hidden">Next</span>
    //                         </button>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </section> */}
    //   {/* <section className="suppliers-section d-block d-md-none d-lg-none d-xl-none wow fadeInDown">
    //         <div className="container">
    //             <div className="row">
    //                 <div className="col-lg-12">
    //                     <h1>What our suppliers are saying</h1>
    //                 </div>
    //             </div>
    //             <div className="row">
    //                 <div className="col-lg-12">
    //                     <div id="carouselExampleControls1" className="carousel slide" data-bs-ride="carousel">
    //                         <div className="carousel-inner testimonails-style">
    //                         <div className="carousel-item active">
    //                             <div className="row">
    //                                 <div className="col-lg-12">
    //                                     <div className="testimonails-img">
    //                                         <img src={testimonail1} alt="" />

    //                                     </div>
    //                                 </div>
    //                                 <div className="col-lg-12">
    //                                     <div className="testi-box-size-padding">
    //                                         <img src={QuoteIcon} alt="" />
    //                                         <p>“ Mad over buildings rental is super awesome! It was delivered in time and was hassle free to book. It was a great aid to the progress of our project “ </p>
    //                                         <h5 className="testi-name-style">Eric Johnson</h5>
    //                                         <h6 className="testi-company-name">J&amp;M Constructors limited</h6>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="carousel-item">
    //                             <div className="row">
    //                                 <div className="col-lg-12">
    //                                     <div className="testimonails-img">
    //                                         <img src={testimonail1} alt="" />

    //                                     </div>
    //                                 </div>
    //                                 <div className="col-lg-12">
    //                                     <div className="testi-box-size-padding">
    //                                         <img src={QuoteIcon} alt="" />
    //                                         <p>“ Mad over buildings rental is super awesome! It was delivered in time and was hassle free to book. It was a great aid to the progress of our project “ </p>
    //                                         <h5 className="testi-name-style">Eric Johnson</h5>
    //                                         <h6 className="testi-company-name">J&amp;M Constructors limited</h6>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         </div>
    //                         <button className="carousel-control-prev testi-style" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="prev">
    //                             <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    //                             <span className="visually-hidden">Previous</span>
    //                         </button>
    //                         <button className="carousel-control-next testi-style" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="next">
    //                             <span className="carousel-control-next-icon" aria-hidden="true"></span>
    //                             <span className="visually-hidden">Next</span>
    //                         </button>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </section> */}
    //   <section className="work-section">
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-lg-7">
    //           <h1>
    //             250000+ <br /> products listed
    //           </h1>
    //           <h6 className="d-none d-lg-block d-md-block">
    //             in more than 100 categories. Just select the item and
    //             <br /> add it to your catalogue within few minutes!
    //           </h6>
    //           <h6 className="d-block d-lg-none d-md-none">
    //             in more than 100 categories. Just select the item and add it to
    //             your catalogue within few minutes!
    //           </h6>
    //           <a
    //             href="/SellerLogin"
    //             target="_blank"
    //             className="btn btn-primary product-register-style-btn"
    //           >
    //             Register now
    //           </a>
    //           {/* <h3>It takes less than 1 minute to register</h3> */}
    //         </div>
    //         <div className="col-lg-5 wow fadeInDown">
    //           <div className="row mobile-products-listed">
    //             <div className="col-lg-4 col-4">
    //               <div className="firrst-box-add mb-3">
    //                 <img src={BuildingMaterial} alt="Buiding-material" />
    //                 <p>
    //                   Buiding
    //                   <br />
    //                   material
    //                 </p>
    //               </div>
    //               <div className="firrst-box-add mb-3">
    //                 <img src={paintsIcon} alt="Paints-wallpapers" />
    //                 <p>
    //                   Paints &<br />
    //                   wallpapers
    //                 </p>
    //               </div>
    //               <div className="firrst-box-add">
    //                 <img src={TilesIcon} alt="Tiles" />
    //                 <p>Tiles</p>
    //               </div>
    //             </div>
    //             <div className="col-lg-4 col-4">
    //               <div className="second-box-add margintop-section-top mb-3">
    //                 <img src={BathroomIcon} alt="Bathroom" />
    //                 <p>
    //                   Bathroom &<br />
    //                   plumbing
    //                 </p>
    //               </div>
    //               <div className="second-box-add mb-3">
    //                 <img src={ElectricIcon} alt="Electric" />
    //                 <p>
    //                   Electric &<br />
    //                   lights
    //                 </p>
    //               </div>
    //               <div className="second-box-add" alt="Windows">
    //                 <img src={WindowIcon} alt="windows" />
    //               </div>
    //             </div>
    //             <div className="col-lg-4 col-4">
    //               <div className="firrst-box-add mb-3" alt="Hardware">
    //                 <img src={HardwareIcon} alt="hardware" />
    //                 <p>Hardware</p>
    //               </div>
    //               <div className="firrst-box-add mb-3" alt="living">
    //                 <img src={LivingIcon} alt="living" />
    //                 <p>
    //                   Living &<br />
    //                   decoration
    //                 </p>
    //               </div>
    //               <div className="firrst-box-add">
    //                 <img src={ToolsIcon} alt="Tools" />
    //                 <p>
    //                   Tools &<br />
    //                   machines
    //                 </p>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    //   <section className="brands-listed-section">
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-lg-12">
    //           <h1>All major brands SKUs</h1>
    //         </div>
    //       </div>
    //       <div className="all-logo-section">
    //         <div className="row">
    //           <div className="col-lg-2 col-4 mb-3">
    //             <img src={BrandLogo1} alt="Logo" />
    //           </div>
    //           <div className="col-lg-2 col-4 mb-3">
    //             <img src={BrandLogo2} alt="Logo" />
    //           </div>
    //           <div className="col-lg-2 col-4 mb-3">
    //             <img src={BrandLogo3} alt="Logo" />
    //           </div>
    //           <div className="col-lg-2 col-4 mb-3">
    //             <img src={BrandLogo4} alt="Logo" />
    //           </div>
    //           <div className="col-lg-2 col-4 mb-3">
    //             <img src={BrandLogo5} alt="Logo" />
    //           </div>
    //           <div className="col-lg-2 col-4 mb-3">
    //             <img src={BrandLogo6} alt="Logo" />
    //           </div>
    //           <div className="col-lg-2 col-4 mb-3">
    //             <img src={BrandLogo7} alt="Logo" />
    //           </div>
    //           <div className="col-lg-2 col-4 mb-3">
    //             <img src={BrandLogo8} alt="Logo" />
    //           </div>
    //           <div className="col-lg-2 col-4 mb-3">
    //             <img src={BrandLogo9} alt="Logo" />
    //           </div>
    //           <div className="col-lg-2 col-4 mb-3">
    //             <img src={BrandLogo10} alt="Logo" />
    //           </div>
    //           <div className="col-lg-2 col-4 mb-3">
    //             <img src={BrandLogo11} alt="Logo" />
    //           </div>
    //           <div className="col-lg-2 col-4 mb-3">
    //             <img src={BrandLogo12} alt="Logo" />
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    //   {/* <section className="start-selling-section d-flex align-items-center">
    //         <div className="container">
    //             <div className="row">
    //                 <div className="col-lg-8">
    //                     <div className="selling-section-top">
    //                         <h2>Start selling today</h2>
    //                         <h4>Reach to thousands of customers</h4>
    //                         <button type="button" className="btn btn-primary selling-btn-style">Register now</button>
    //                         <p className="register-style-text">It takes less than 1 minute to register</p>
    //                     </div>
    //                 </div>
    //                 <div className="col-lg-4 d-flex align-items-end">
    //                     <div className="selling-car-img ">
    //                         <img src={SellingIcon} className="wow fadeInRight" alt="Selling car" />
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </section> */}
    //   {/* <section className="supplier-support">
    //         <div className="container">
    //             <div className="row">
    //                 <div className="col-lg-12">
    //                     <h2>24x7 Supplier support</h2>
    //                 </div>
    //             </div>
    //             <div className="row">
    //                 <div className="col-lg-6">
    //                     <p>Mad over buildings supplier support is available to solve all your doubts and issues before and after you start your online selling business.</p>
    //                 </div>
    //             </div>
    //             <div className="row support-style">
    //                 <div className="col-lg-12">
    //                     <div className="d-flex flex-row bd-highlight mb-3 d-flex align-items-center">
    //                         <div className="p-1">
    //                             <img src={callicon} alt="Call us icon" />
    //                         </div>
    //                         <div className="p-2">
    //                             <a href="tel:+918660423608" className="tels-number-style"><span className="call-us-style">Call us:</span>+91 8660 423 608</a>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="supplier-support-style">
    //                 <div className="row">
    //                     <div className="col-lg-12">
    //                         <h3>Frequently asked questions</h3>
    //                     </div>
    //                 </div>
    //                 <div className="row mt-3 frequently-mobile-style d-block">
    //                     <div className="col-lg-12">
    //                     <div className="accordion" id="accordionExample">
    //                         <div className="accordion-item frequently">
    //                             <h2 className="accordion-header" id="headingOne">
    //                                 <button className="accordion-button frequently collapse" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
    //                                     Can I use this as billing and management software to manage my store/ shop without going online?
    //                                 </button>
    //                             </h2>
    //                             <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
    //                                 <div className="accordion-body">
    //                                     Yes, you can. It is designed to handle all your sales and bills receivables and payables as well.
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="accordion-item frequently">
    //                             <h2 className="accordion-header" id="headingTwo">
    //                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
    //                                     You are still not in my city - how can I use this platform?
    //                                 </button>
    //                             </h2>
    //                             <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
    //                                 <div className="accordion-body">
    //                                     You can use it to create your catalogue from an exhaustive MOB library’ with little effort and use it as your billing and management software. Once we are in your city, you can request use to activate you as an online partner on mob
    //                                 </div>
    //                             </div>
    //                         </div>

    //                         <div className="accordion-item frequently">
    //                             <h2 className="accordion-header" id="headingThree">
    //                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
    //                                     Do I need to invest any amount as a supply partner (BMP)?

    //                                 </button>
    //                             </h2>
    //                             <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
    //                                 <div className="accordion-body">
    //                                     No, you do not need to invest anything with us. We got you covered.

    //                                 </div>
    //                             </div>
    //                         </div>

    //                         <div className="accordion-item frequently">
    //                             <h2 className="accordion-header" id="headingFour">
    //                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="headingFour">
    //                                     Will I need to invest time to make the catalogue and pricing with MOB?

    //                                 </button>
    //                             </h2>
    //                             <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
    //                                 <div className="accordion-body">
    //                                     We have brand-wise SKUs built and all you need to do is help us for the first time with pricing and we will get your catalogue listed.

    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="accordion-item frequently">
    //                             <h2 className="accordion-header" id="headingfive">
    //                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
    //                                     I have low margins on some products and brands. Is the commission amount fixed?

    //                                 </button>
    //                             </h2>
    //                             <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#accordionExample">
    //                                 <div className="accordion-body">
    //                                     The commission amount can vary for brands and even for certain SKUs.

    //                                 </div>
    //                             </div>
    //                         </div>

    //                         <div className="accordion-item frequently">
    //                             <h2 className="accordion-header" id="headingsix">
    //                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="headingsix">
    //                                     Will I get my login credentials to update my pricing directly?

    //                                 </button>
    //                             </h2>
    //                             <div id="collapsesix" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
    //                                 <div className="accordion-body">
    //                                     Yes, you can generate your login credentials directly by signing up as a supplier with your mobile number or email address. Your catalogue will be assigned against it.

    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="accordion-item frequently">
    //                             <h2 className="accordion-header" id="headingSeven">
    //                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
    //                                     How will I accept the order and who will come to pick up the order?

    //                                 </button>
    //                             </h2>
    //                             <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
    //                                 <div className="accordion-body">
    //                                     You will be notified on the partner's login account and via SMS and email. You will be required to assign the vehicle depending on the material ordered.

    //                                 </div>
    //                             </div>
    //                         </div>

    //                         <div className="accordion-item frequently">
    //                             <h2 className="accordion-header" id="headingEight">
    //                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="headingEight">
    //                                     Can I still accept the order if I do not have part or all of the material available?

    //                                 </button>
    //                             </h2>
    //                             <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
    //                                 <div className="accordion-body">
    //                                     No, you cannot accept the order until all the materials are available for the shipment as per the delivery latency mentioned in the catalogue by you. You must fulfil all the materials or you may choose to reject the order. The rejected order may be assigned to another partner by MOB.

    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="accordion-item frequently">
    //                             <h2 className="accordion-header" id="headingNine">
    //                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
    //                                     You have a 7 days free return policy but certain materials are not returnable.

    //                                 </button>
    //                             </h2>
    //                             <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
    //                                 <div className="accordion-body">
    //                                     7 days return policy applies SKU wise. Please ensure that your catalogue reflects that.

    //                                 </div>
    //                             </div>
    //                         </div>

    //                         <div className="accordion-item frequently">
    //                             <h2 className="accordion-header" id="headingTen">
    //                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="headingTen">
    //                                     What if the returned material has a broken package or is damaged?

    //                                 </button>
    //                             </h2>
    //                             <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
    //                                 <div className="accordion-body">
    //                                     You can upload the picture as proof on the portal once the function is available and the return will not be processed.

    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="accordion-item frequently">
    //                             <h2 className="accordion-header" id="headingEleven">
    //                                 <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
    //                                     Can I suspend the account with you if I am not happy?

    //                                 </button>
    //                             </h2>
    //                             <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
    //                                 <div className="accordion-body">
    //                                     Yes, you will have the option to suspend the account and restart as you would like.

    //                                 </div>
    //                             </div>
    //                         </div>

    //                     </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </section> */}
    //   <footer>
    //     <section className="footer-bottom-section d-none d-lg-block d-md-block">
    //       <div className="container">
    //         <div className="row">
    //           {/* Column for the logo */}
    //           <div className="col-lg-2 col-12">
    //             <img src={LogoFooterIcon} alt="Footer Logo" />
    //           </div>
    //           {/* Column for Company links */}
    //           {/* <div className="col-lg-2 col-12 d-flex justify-content-center">
    //             <div className="footer-bottom-box">
    //               <h3>Company</h3>
    //               <ul>
    //                 <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     About us
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Career
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Contact us
    //                   </a>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div> */}
    //           {/* Column for For You links */}
    //           {/* <div className="col-lg-2 col-12 d-flex justify-content-end">
    //             <div className="footer-bottom-box">
    //               <h3>For you</h3>
    //               <ul>
    //                 <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     FAQ’s{" "}
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Return policy
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Privacy policy
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Terms and conditions
    //                   </a>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div> */}
    //           {/* Column for Other links */}
    //           <div className="col-lg-4 col-12 d-flex justify-content-end">
    //             <div className="footer-bottom-box">
    //               <h3>Others</h3>
    //               <ul>
    //               <li>
    //                   <a href="/SellerLogin" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Register Now
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/SellerLogin" rel="noopener noreferrer">
    //                     {" "}
    //                   Login
    //                   </a>
    //                 </li>
    //                 {/* <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Large orders(RFQ){" "}
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/" rel="noopener noreferrer">
    //                     {" "}
    //                     Reselling
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/" rel="noopener noreferrer">
    //                     {" "}
    //                     Rental
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/" rel="noopener noreferrer">
    //                     {" "}
    //                     Construction waste
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/" rel="noopener noreferrer">
    //                     {" "}
    //                     Become a supplier
    //                   </a>
    //                 </li> */}
    //               </ul>
    //             </div>
    //           </div>
    //           {/* Column for Contact information */}
    //           <div className="col-lg-6 col-12 d-flex justify-content-end">
    //             <div className="footer-bottom-box">
    //               <h3>Need help with something?</h3>
    //               <p>
    //                 Call us:
    //                 <a href="tel:+91 8660423608" className="number-style1">
    //                   +91 8660423608
    //                 </a>
    //               </p>
    //               <p>
    //                 Watsapp:
    //                 <a
    //                   href="https://api.whatsapp.com/send?phone=918970415365&amp;text="
    //                   className="number-style2"
    //                 >
    //                   +91 8970415365
    //                 </a>
    //               </p>
    //               <p>
    //                 Mail us:
    //                 <a
    //                   href="mailto:ask@madoverbuildings.com"
    //                   className="number-style3"
    //                 >
    //                   ask@madoverbuildings.com
    //                 </a>
    //               </p>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //     <section className="footer-bottom-section d-block d-lg-none d-md-none">
    //       <div className="container">
    //         <div className="row">
    //           {/* Logo */}
    //           <div className="col-12">
    //             <img src={LogoFooterIcon} alt="MOB Logo" />
    //           </div>
    //           {/* Company Links */}
    //           {/* <div className="col-6">
    //             <div className="footer-bottom-box footer-box-1mobile">
    //               <h3>Company</h3>
    //               <ul>
    //                 <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     About us
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Career
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Contact us
    //                   </a>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div> */}
    //           {/* Others Links */}
    //           <div className="col-6">
    //             <div className="footer-bottom-box footer-box-1mobile">
    //               <h3>Others</h3>
    //               <ul>
    //                 <li>
    //                   <a href="/Sellerlogin" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Register Now
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/Sellerlogin" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Login
    //                   </a>
    //                 </li>
    //                 {/* <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Privacy policy
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="/" target="_blank" rel="noopener noreferrer">
    //                     {" "}
    //                     Terms and conditions
    //                   </a>
    //                 </li> */}
    //               </ul>
    //             </div>
    //           </div>
    //           {/* For You Links */}
    //           {/* <div className="col-6">
    //             <div className="footer-bottom-box footer-box-1mobile">
    //               <h3>For you</h3>
    //               <ul>
    //                 <li>
    //                   <a href="/"> FAQ’s </a>
    //                 </li>
    //                 <li>
    //                   <a href="/"> Return policy</a>
    //                 </li>
    //                 <li>
    //                   <a href="/"> Privacy policy</a>
    //                 </li>
    //                 <li>
    //                   <a href="/"> Terms and conditions</a>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div> */}
    //           {/* Contact Information */}
    //           <div className="col-12">
    //             <div className="footer-bottom-box">
    //               <h3>Need help with something?</h3>
    //               <p>
    //                 Call us:
    //                 <a href="tel:8660423608" className="number-style1">
    //                   +91 8660423608
    //                 </a>
    //               </p>
    //               <p>
    //                 Watsapp:
    //                 <a
    //                   href="https://api.whatsapp.com/send?phone=918970415365&amp;text="
    //                   className="number-style2"
    //                 >
    //                   +91 8970415365
    //                 </a>
    //               </p>
    //               <p>
    //                 Mail us:
    //                 <a
    //                   href="mailto:ask@madoverbuildings.com"
    //                   className="number-style3"
    //                 >
    //                   ask@madoverbuildings.com
    //                 </a>
    //               </p>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //     <section className="border-section">
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-lg-12">
    //             <div className="border-top-style"></div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //     <section className="footer-last-section d-none d-md-block d-xl-block d-lg-block">
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-lg-6">
    //             <div className="row">
    //               <div className="col-lg-4 col-6">
    //                 <div className="input-group footer-drop-down-style">
    //                   <label className="input-group-text">
    //                     <img src={Flag} alt="National Flag" />
    //                   </label>
    //                   <select
    //                     value={selectedValue}
    //                     className="form-select"
    //                     onChange={handleChange}
    //                   >
    //                     <option value="0">India</option>
    //                     {/*<option value="1">India</option>
    //                                         <option value="2">India</option>
    //                                         <option value="3">India</option>*/}
    //                   </select>
    //                 </div>
    //               </div>
    //               <div className="col-lg-4 col-6">
    //                 <div className="input-group footer-drop-down-style">
    //                   <label className="input-group-text">
    //                     <img src={Globe} alt="Globe Icon" />
    //                   </label>
    //                   <select
    //                     value={selectedValue}
    //                     className="form-select"
    //                     onChange={handleChange}
    //                   >
    //                     <option value="0">English</option>
    //                   </select>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="col-lg-6">
    //             <div className="footer-social-icon-style">
    //               <ul>
    //                 <li className="desktop-style-text-folllow">Follow us on</li>
    //                 <li>
    //                   <a
    //                     href="https://www.linkedin.com/company/mad-over-buildings/"
    //                     rel="noreferrer"
    //                     target="_blank"
    //                   >
    //                     <img src={linkedin} alt="linkedin" />
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a
    //                     href="https://m.facebook.com/madoverbuildings/"
    //                     rel="noreferrer"
    //                     target="_blank"
    //                   >
    //                     <img src={facebook} alt="facebook" />
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a
    //                     href="https://www.instagram.com/madoverbuildings_mob/"
    //                     rel="noreferrer"
    //                     target="_blank"
    //                   >
    //                     <img src={instagram} alt="insta" />
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a
    //                     href="https://twitter.com/mobforindia"
    //                     rel="noreferrer"
    //                     target="_blank"
    //                   >
    //                     <img src={twitter} alt="twitter" />
    //                   </a>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div>
    //           <div className="col-12">
    //             <p className="company-name-style">
    //               2024 by Madoverbuilding AI Private Limited
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //     <section className="footer-last-section d-block d-lg-none d-md-none d-xl-none">
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-lg-6">
    //             <div className="row">
    //               <div className="col-lg-4 col-6">
    //                 <div className="input-group footer-drop-down-style">
    //                   <label className="input-group-text">
    //                     <img src={Flag} alt="National Flag" />
    //                   </label>
    //                   <select
    //                     className="form-select"
    //                     value={selectedValue}
    //                     onChange={handleChange}
    //                   >
    //                     <option value="0">India</option>
    //                   </select>
    //                 </div>
    //               </div>
    //               <div className="col-lg-4 col-6">
    //                 <div className="input-group footer-drop-down-style">
    //                   <label className="input-group-text">
    //                     <img src={Globe} alt="Globe Icon" />
    //                   </label>
    //                   <select
    //                     value={selectedValue}
    //                     className="form-select"
    //                     onChange={handleChange}
    //                   >
    //                     <option value="0">English</option>
    //                   </select>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="col-lg-6">
    //             <div className="footer-social-icon-style">
    //               <p className="follows-on-top">Follow us on</p>
    //               <ul>
    //                 <li>
    //                   <a
    //                     href="https://www.linkedin.com/company/mad-over-buildings/"
    //                     rel="noreferrer"
    //                     target="_blank"
    //                   >
    //                     <img src={linkedin} alt="linkedin" />
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a
    //                     href="https://m.facebook.com/madoverbuildings/"
    //                     rel="noreferrer"
    //                     target="_blank"
    //                   >
    //                     <img src={facebook} alt="facebook" />
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a
    //                     href="https://www.instagram.com/madoverbuildings_mob/"
    //                     rel="noreferrer"
    //                     target="_blank"
    //                   >
    //                     <img src={instagram} alt="instagram" />
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a
    //                     href="https://twitter.com/mobforindia"
    //                     rel="noreferrer"
    //                     target="_blank"
    //                   >
    //                     <img src={twitter} alt="twitter" />
    //                   </a>
    //                 </li>
    //               </ul>
    //             </div>
    //           </div>
    //           <div className="col-12">
    //             <p className="company-name-style">
    //               2024 by Madoverbuilding AI Private Limited
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   </footer>
    // </div>
    <>
      <Layout className="web-layout">
        <Header
          className="header header-top-section fixed-top "
          style={{ backgroundColor: "white" }}
        >
          <div className="container">
            <div
              className="logo "
              style={{
                float: "left",
              }}
            >
               <a href="/seller">
              <PartnerLogoIcon />
              </a>
            </div>
            <div style={{ float: "right" }}>
              <Button
                type="primary"
                onClick={() => window.open("/SellerLogin", "_blank")}
                className="register-style-btn"
              >
                Login/ Register
              </Button>
            </div>
          </div>
        </Header>
        <Content>
          <section className="top-banner-bg d-none d-md-block d-lg-block d-xl-block">
            <div className="banner-section">
              <div
                className="container top-banner "
                style={{ margin: "0 auto" }}
              >
                <Row
                  className="top-banner-section"
                  gutter={[16, 16]}
                  align="middle"
                >
                  <Col md={14}>
                    <span className="cashflow-button">
                      <span className="cashflow-text">CASHFLOW UNLOCKED</span>
                    </span>
                    <h1 className="top-banner-text">
                      Your{" "}
                      <span className="text-gradient"> line of credit</span>{" "}
                      partner with complete{" "}
                      <span className="text-gradient"> sales management</span>
                    </h1>
                    <p className="top-banner-text-sm mt-2">
                      No payment worries anymore!
                    </p>
                    <div style={{ marginTop: "24px" }}>
                      <div className="custom-input-register">
                        {/* <span>+91</span>
                        <input
                          className="text-banner-mobile"
                          type="text"
                          placeholder="Business mobile for OTP"
                        />
                        <Button className="custom-button-register">
                          Register now
                        </Button> */}
                        <div className="form-controll-click text-left d-flex">
                    
                       <input
                         type="tel"
                         id="txtMobileNo"
                         className="form-control position-relative text-banner-mobile"
                         aria-describedby=""
                         maxLength="10"
                         minLength="10"
                         placeholder="Enter your mobile number"
                         pattern="[0-9]*"
                         onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                       />
                       <span className="prefix-number">+91</span>
                       <a
                         href="/SellerLogin"
                         target="_blank"
                         className="custom-button-register"
                         id="btnRegister"
                       >
                         Register now
                       </a>
                     </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="made-india-italics"
                        style={{ marginRight: "8px" }}
                      >
                        Made in India
                      </span>
                      <IndiaFlagLogo className="india-flag-icon" />
                    </div>
                  </Col>
                  {/* <Col className="slider-bg p-0" md={10}>
                  <img
                    src={BannerIcon}
                    alt="Dashboard and person using mobile"
                    style={{ width: "100%" }}
                  />
                </Col> */}
                </Row>
              </div>
            </div>
          </section>
          <section className="top-banner-bg d-block d-sm-block d-lg-none d-xl-none">
            <div className="banner-section1">
              <div
                className="container top-banner "
                style={{ margin: "0 auto" }}
              >
                <Row
                  className="top-banner-section"
                  gutter={[16, 16]}
                  align="middle"
                >
                  <Col span={24}>
                    <span className="cashflow-button">
                      <span className="cashflow-text">CASHFLOW UNLOCKED</span>
                    </span>
                    <h1 className="top-banner-text">
                      Your{" "}
                      <span className="text-gradient"> line of credit</span>{" "}
                      partner with complete{" "}
                      <span className="text-gradient"> sales management</span>
                    </h1>
                    <p className="top-banner-text-sm mt-2">
                      No payment worries anymore!
                    </p>
                    <div style={{ marginTop: "24px" }}>
                      {/* <div className="custom-input-register">
                        <span>+91</span>
                        <input
                          className="text-banner-mobile"
                          type="text"
                          placeholder="Business mobile for OTP"
                        />
                        <Button className="custom-button-register">
                          Register now
                        </Button>
                      </div> */}
                      <div className="form-controll-click text-left">
                       
                       {/* <input
                         type="text"
                         id="txtMobileNo"
                         className="form-control position-relative"
                         aria-describedby=""
                         maxLength="10"
                         minLength="10"
                         placeholder="Business mobile for OTP"
                       /> */}
                       <input
                         type="tel"
                         id="txtMobileNo"
                         className="form-control position-relative"
                         aria-describedby=""
                         maxLength="10"
                         minLength="10"
                         placeholder="Enter your mobile number"
                         pattern="[0-9]*"
                         onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                       />
                       <span className="prefix-number">+91</span>
                       <a
                         href="/SellerLogin"
                         target="_blank"
                         className="btn btn-primary register-now-btn"
                         id="btnRegister"
                       >
                        Register now
                       </a>
                     </div>
                    </div>
                    <div
                      style={{
                        marginTop: "16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="made-india-italics"
                        style={{ marginRight: "8px" }}
                      >
                        Made in India
                      </span>
                      <IndiaFlagLogo className="india-flag-icon" />
                    </div>
                  </Col>
                  <Col className="p-0" span={24}>
                  <img
                    src={BannerIcon}
                    alt="Dashboard and person using mobile"
                    style={{ width: "100%" }}
                  />
                </Col>
                </Row>
              </div>
            </div>
          </section>
          <section className="d-none d-md-none d-lg-block d-xl-block" style={{ backgroundColor: "white", padding: "40px 0" }}>
            <div
              className="container d-flex"
              style={{
                margin: "0 auto",
                justifyContent: "space-between",
                gap: "40px",
                width: "100%",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <span className="txt-enabling-partner"> Enabling partners</span>
              <img
                src={MuthoothblackLogo}
                className="partner-logo"
                alt="Partner logo 1"
              />
              <img
                src={liquiBlacklogo}
                className="partner-logo"
                alt="Partner logo 2"
              />
              <img
                src={MobCreditBlackIcon}
                className="partner-logo"
                alt="Partner logo 3"
              />
              <img
                src={RupifyBlacklogo}
                className="partner-logo"
                alt="Partner logo 4"
              />
              <img
                src={RazorPayIcon}
                className="partner-logo"
                alt="Partner logo 5"
              />
            </div>
          </section>
          <section className="d-block d-sm-block d-lg-none d-xl-none" style={{ backgroundColor: "white", padding: "40px 0" }}>
            <Row
              className="container d-flex"             
            >
              <Col className="text-center" span={24}>
              <Text className="txt-enabling-partner text-center"> Enabling partners</Text>
              </Col>
              <Col className="text-center" span={24}>
              <img
                src={MuthoothblackLogo}
                className="partner-logo"
                alt="Partner logo 1"
              />
              <img
                src={liquiBlacklogo}
                className="partner-logo"
                alt="Partner logo 2"
              />
              <img
                src={MobCreditBlackIcon}
                className="partner-logo"
                alt="Partner logo 3"
              />
              <img
                src={RupifyBlacklogo}
                className="partner-logo"
                alt="Partner logo 4"
              />
              <img
                src={RazorPayIcon}
                className="partner-logo"
                alt="Partner logo 5"
              />
              </Col>
            </Row>
          </section>
          <section className="wow fadeInUp d-none d-md-none d-lg-block d-xl-block">
          <div className="container" style={{ margin: "0 auto" }}>
            <Card
              className="card-light-green-section p-0"
              style={{ margin: "0 auto" }}
            >
              <Row
                gutter={[16, 16]}
                className="p-0"
                justify="space-between"
                style={{alignItems: "center"}}
             
              >
                <Col className="card-padding-custom" md={10}>
                  <p
                    className="txt-mobPartnet-heading"
                  >
                    mobPARTNER is for
                  </p>
                  <p className="text-font-list">
                    <GreenTickIcon /> Construction material suppliers
                  </p>
                  <p className="text-font-list">
                    <GreenTickIcon /> Interior material suppliers
                  </p>
                  <p className="text-font-list">
                    <GreenTickIcon /> Manufacturers & D2C brands
                  </p>
                </Col>
                <Col md={8} className="partner-user-img text-right">
                  <img src={PartnerUserIcon} alt="" width={"80%"}/>
                </Col>
              </Row>
            </Card>
            </div>
          </section>
          <section className="wow fadeInUp d-block d-sm-block d-lg-none d-xl-none">
            <div className="container">
            <Card
              className=" card-light-green-section p-0"
              style={{ margin: "0 auto" }}
            >
              <Row
                gutter={[16, 16]}
                className="p-0"
                justify="space-between"
                style={{alignItems: "center"}}
             
              >
                <Col className="card-padding-custom" md={10}>
                  <h2
                    className="txt-mobPartnet-heading"
                  >
                    mobPARTNER is for
                  </h2>
                  <p className="text-font-list">
                    <GreenTickIcon /> Construction material suppliers
                  </p>
                  <p className="text-font-list">
                    <GreenTickIcon /> Interior material suppliers
                  </p>
                  <p className="text-font-list">
                    <GreenTickIcon /> Manufacturers & D2C brands
                  </p>
                </Col>
                <Col md={8} className="partner-user-img text-center width-100">
                  <img src={PartnerUserNoBgIcon} alt="" width={"50%"}/>
                </Col>
              </Row>
            </Card>
            </div>
          </section>
          <section
            className="wow fadeInUp main-common-padding"
            style={{ backgroundColor: "white",  }}
          >
            <div className="container" style={{ margin: "0 auto" }}>
              <h2 className="credit-heading-black text-center">
                <span className="credit-heading-bg">
                  {" "}
                  Line of credit issues?
                </span>{" "}
                We have you covered
              </h2>
              <Row className="mt-4">
                <Col span={24} className="card-risk-section d-flex">
                  <TickIcon />
                  <span className="text-heading-sm ml-16px">
                    You carry zero risk on client’s default
                  </span>
                </Col>
              </Row>
              <Row
                gutter={[24,24]}
                justify="center"
                style={{ marginTop: "24px",flexWrap:"flex" }}
              >
                <Col md={8}>
                  <Card className="card-light-section-common height-100">
                    <CustomercreditIcon
                     className="credt-icon-top"
                    />
                    <h3 className="txt-credit-section-heading">
                      Give{" "}
                      <span className="text-common-green"> line of credit</span>{" "}
                      to your customers
                    </h3>
                    <p style={{ marginTop: "16px" }}>
                      Sell instantly to clients already on line of credit or add
                      your client to onboard them to line of credit.
                    </p>
                    <img
                      src={Mobcreditlogo}
                      className="mobile-logo-size"
                      alt=""
                    />
                  </Card>
                </Col>
                <Col md={8} style={{flex:"1" }}>
                  <Card className="card-light-section-common height-100">
                    <CreditIcon className="credt-icon-top" />
                    <h3 className="txt-credit-section-heading">
                      Up to <span className="text-common-green">50 Lakhs</span>{" "}
                      per client (90 days)
                    </h3>
                    <p style={{ marginTop: "16px" }}>
                      90 days line of credit for your client (21 days is
                      interest free for your client)
                    </p>
                    <p>Backed by</p>
                    <Col className="d-flex" style={{ gap: "10px" }}>
                      <img
                        src={MuthoothLogo}
                        className=""
                        alt="Partner logo 1"
                      />
                      <img src={RupifyLogo} className="" alt="Partner logo 4" />
                    </Col>
                  </Card>
                </Col>
                <Col md={8} style={{flex:"1" }}>
                  <Card className="card-light-section-common height-100">
                    <RepaymentIcon className="credt-icon-top" />
                    <h3 className="txt-credit-section-heading">
                      <span className="text-common-green">
                        {" "}
                        Assured payment{" "}
                      </span>{" "}
                      in T+2 days
                    </h3>
                    <p style={{ marginTop: "16px", marginBottom: "24px" }}>
                      Assured payment on full delivery of your sales
                      transactions. Order amount is credited via OTP by client.
                    </p>

                    <AssuredpaymentsIcon />
                  </Card>
                </Col>
              </Row>
            </div>
          </section>
          <section className="container " >
            <div
              className=" card-light-section-common"
              style={{ margin: "0 auto", padding: "24px" }}
            >
              <h2 className="txt-dark-bold">
                Use existing mobCREDIT clients <br />
                <span className="txt-green-dark"> or add your clients</span>
              </h2>
              <Row
                gutter={[24, 24]}
                justify="center"
                style={{ marginTop: "40px" }}
              >
                <Col md={8}>
                  <img
                    src={CreditActivelogo}
                    width={"100%"}
                    alt="mobCredit"
                  ></img>
                </Col>
                <Col md={8}>
                  <img
                    src={CreditRejectlogo}
                    width={"100%"}
                    alt="mobCredit"
                  ></img>
                </Col>
                <Col md={8}>
                  <img
                    src={CreditApplylogo}
                    width={"100%"}
                    alt="mobCredit"
                  ></img>
                </Col>
              </Row>
            </div>
          </section>
          <section
            className="wow fadeInUp main-common-padding"
            style={{ backgroundColor: "white" }}
          >
            <div className="container" style={{ margin: "0 auto" }}>
              <h2
                className="txt-font-bigger"
              >
                Grow your construction & interior material <br /> business with
                mobCREDIT
              </h2>
              <Row gutter={[24, 24]} className="mt-4">
                <Col xs={24} md={12}>
                  <Card className="mb-2 card-easyline lineofcredit-bg-img">
                    <Text className="font-large-text">
                      Easy line of credit sales creation
                    </Text>
                    <Text className="font-medium-text">
                      mobCREDIT based sales can be created instantly for
                      approved clients with their OTP confirmation
                    </Text>
                    <Col className="text-center mt-4">
                    <img
                      src={LoCIcon}
                      className="img-lineofcredit"
                      alt=""
                    ></img>
                    </Col>
                  </Card>
                  <Card className="mt-4 card-onboarding">
                    <Text className="font-large-text">
                      Activate mobCREDIT now!
                    </Text>
                    <Text className="font-medium-text">
                      Activate mobCREDIT immediately upon login (add your
                      business and personal GSTIN, Aadhar and PAN)
                    </Text>
                  </Card>
                </Col>
                <Col xs={24} md={12}>
                  <Card className="mb-2 card-guarenteed">
                    <Text className="font-large-text">
                      Guaranteed payment <TickIcon />{" "}
                    </Text>
                    <Text className="font-medium-text">
                      Guaranteed payment upon delivery confirmation and your
                      invoice in 2 working days
                    </Text>
                  </Card>
                  <Card className="mt-4 card-optimise">
                    <Text className="font-large-text">
                      Optimise your cash flow
                    </Text>
                    <Text className="font-medium-text">
                      You get paid in t+2 working days and your buyers
                      (Architects & contractors) can pay pack within 90 days.
                    </Text>
                    <Col className="text-center mt-4">
                      <img
                        src={CashFlowIcon}
                        className="img-lineofcredit1"
                        alt=""
                      ></img>
                    </Col>
                  </Card>
                </Col>
              </Row>
            </div>
          </section>
          <section
            className="wow fadeInUp d-none d-md-none d-lg-block d-xl-block"
            style={{ padding: "40px 0" }}
          >
            <div className="container" style={{ margin: "0 auto" }}>
              <h2
               className="txt-font-bigger"
              >
                How does mobCREDIT work?
              </h2>
              <Row
                gutter={[16, 16]}
                justify="space-between"
                style={{ marginTop: "40px" }}
              >
                <Col>
                  <Tabs
                    defaultActiveKey="1"
                    className="homepage-video-tab"
                    tabPosition={"left"}
                  >
                    <TabPane
                      key="1"
                      tab={
                        <Collapse
                          className="custom-collapse width-100"
                          activeKey={activeKey}
                          onChange={(key) => setActiveKey(key)}
                          defaultActiveKey={["1"]}
                          accordion
                          expandIconPosition="end"
                          expandIcon={({ isActive }) => isActive ? "" : <PlusIcon />}
                        >
                          <Collapse.Panel
                            header={
                              <div className="text-left">
                                <span>1. Easy registration with GSTIN</span>
                              </div>
                            }
                            key="1"
                          >
                            <span className="f-small-text overflow-text">
                              Easy registration with GSTIN - mobPartner (36
                              secs) - Sales management + Line of Credit
                            </span>
                          </Collapse.Panel>
                        </Collapse>
                      }
                    >
                      <Card
                        className="video-card-content card-easyline  mb-2"
                        onClick={() =>
                          handleModalOpen(
                            "SpFemJFcfa4",
                            "Easy registration with GSTIN (36 secs)"
                          )
                        }
                      >
                        <img
                          width={"100%"}
                          src={Video2Logo}
                          alt="Easy registration with GSTIN (36 secs)"
                        ></img>

                        <div className="d-flex justify-content-center cls-center-img">
                          <Button className="play-button" type="text">
                            <PlayLogo />
                          </Button>
                        </div>
                      </Card>
                    </TabPane>
                    <TabPane
                      key="2"
                      tab={
                        <Collapse
                          className="custom-collapse width-100"
                          // defaultActiveKey={["1"]}
                          activeKey={activeKey}
                          onChange={(key) => setActiveKey(key)}
                          accordion
                          expandIconPosition="end"
                          expandIcon={({ isActive }) => isActive ? "" : <PlusIcon />}
                        >
                          <Collapse.Panel
                            header={
                              <div className="text-left">
                                <span>2. Activate Line of Credit</span>
                              </div>
                            }
                            key="2"
                          >
                            <span className="f-small-text overflow-text">
                              Activate Line of Credit - mobPartner (88 secs) -
                              Sales management + Line of Credit
                            </span>
                          </Collapse.Panel>
                        </Collapse>
                      }
                    >
                      <Card
                        className="video-card-content card-easyline mb-2"
                        onClick={() =>
                          handleModalOpen(
                            "3AakpS6zLZY",
                            "Activate Line of Credit (88 secs)"
                          )
                        }
                      >
                        <img
                          width={"100%"}
                          src={Video3Logo}
                          alt="Activate Line of Credit (88 secs)"
                        ></img>

                        <div className="d-flex justify-content-center cls-center-img">
                          <Button className="play-button" type="text">
                            <PlayLogo />
                          </Button>
                        </div>
                      </Card>
                    </TabPane>
                    <TabPane
                      key="3"
                      tab={
                        <Collapse
                          className="custom-collapse width-100"
                          // defaultActiveKey={["1"]}
                          activeKey={activeKey}
                          onChange={(key) => setActiveKey(key)}
                          accordion
                          expandIconPosition="end"
                          expandIcon={({ isActive }) => isActive ? "" : <PlusIcon />}
                        >
                          <Collapse.Panel
                            header={
                              <div className="text-left">
                                <span>3. Create mobCREDIT/ store sales</span>
                              </div>
                            }
                            key="3"
                          >
                            <span className="f-small-text overflow-text">
                              Manage store sales - mobPartner (36 secs) - Sales
                              management + Line of Credit
                            </span>
                          </Collapse.Panel>
                        </Collapse>
                      }
                    >
                      <Card
                        className="video-card-content card-easyline mb-2"
                        onClick={() =>
                          handleModalOpen(
                            "NFy5mCrdZgY",
                            "Manage store sales (36 secs)"
                          )
                        }
                      >
                        <img
                          width={"100%"}
                          src={Video1Logo}
                          alt="Manage store sales (36 secs)"
                        ></img>

                        <div className="d-flex justify-content-center cls-center-img">
                          <Button type="text" className="play-button">
                            <PlayLogo />
                          </Button>
                        </div>
                      </Card>
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
            </div>
          </section>
          <section
            className="wow fadeInUp d-block d-sm-block d-lg-none d-xl-none"
            style={{ padding: "20px 0" }}
          >
            <div className="container" style={{ margin: "0 auto" }}>
              <h2
                 className="txt-font-bigger"
              >
                How does mobCREDIT work?
              </h2>
              <Row
                gutter={[16, 16]}
                justify="space-between"
                style={{ marginTop: "20px" }}
              >
                <Col span={24}>
                  <Collapse
                    className="home-collapse"
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => isActive ? "" : <PlusIcon />}
                    defaultActiveKey={["1"]}
                    accordion={true}
                  >
                    <Collapse.Panel
                      header={
                        <div className="text-left">
                          <span className="txt-collapse-heading">
                            1. Easy registration with GSTIN
                          </span>
                        </div>
                      }
                      key="1"
                    >
                      <p>
                        <Card
                          className="video-card-content card-easyline p-0  mb-2"
                          onClick={() =>
                            handleModalOpen(
                              "SpFemJFcfa4",
                              "Easy registration with GSTIN (36 secs)"
                            )
                          }
                        >
                          <Text className="mb-2">
                            Easy registration with GSTIN - mobPartner (36 secs)
                            - Sales management + Line of Credit
                          </Text>
                          <img
                            width={"100%"}
                            src={Video2Logo}
                            alt="Easy registration with GSTIN (36 secs)"
                          ></img>

                          <div className="d-flex justify-content-center cls-center-img-mob  ">
                            <Button className="play-button" type="text">
                              <PlayLogo />
                            </Button>
                          </div>
                        </Card>
                      </p>
                    </Collapse.Panel>
                    <Collapse.Panel
                      header={
                        <div className="text-left">
                          <span className="txt-collapse-heading">
                            2. Activate Line of Credit
                          </span>
                        </div>
                      }
                      key="2"
                    >
                      <p>
                        <Card
                          className="video-card-content p-0 card-easyline mb-2"
                          onClick={() =>
                            handleModalOpen(
                              "3AakpS6zLZY",
                              "Activate Line of Credit (88 secs)"
                            )
                          }
                        >
                          <Text className="mb-2">
                            Activate Line of Credit - mobPartner (88 secs) -
                            Sales management + Line of Credit
                          </Text>
                          <img
                            width={"100%"}
                            src={Video3Logo}
                            alt="Activate Line of Credit (88 secs)"
                          ></img>

                          <div className="d-flex justify-content-center cls-center-img-mob">
                            <Button className="play-button" type="text">
                              <PlayLogo />
                            </Button>
                          </div>
                        </Card>
                      </p>
                    </Collapse.Panel>
                    <Collapse.Panel
                      header={
                        <div className="text-left">
                          <span className="txt-collapse-heading">
                            3. Manage store sales
                          </span>
                        </div>
                      }
                      key="3"
                    >
                      <p>
                        <Card
                          className="video-card-content p-0 card-easyline mb-2"
                          onClick={() =>
                            handleModalOpen(
                              "NFy5mCrdZgY",
                              "Manage store sales (36 secs)"
                            )
                          }
                        >
                          <Text className="mb-2">
                            Manage store sales - mobPartner (36 secs) - Sales
                            management + Line of Credit
                          </Text>
                          <img
                            width={"100%"}
                            src={Video1Logo}
                            alt="Manage store sales (36 secs)"
                          ></img>

                          <div className="d-flex justify-content-center cls-center-img-mob">
                            <Button type="text" className="play-button">
                              <PlayLogo />
                            </Button>
                          </div>
                        </Card>
                      </p>
                    </Collapse.Panel>
                  </Collapse>
                </Col>
              </Row>
            </div>
          </section>
          <section className="wow fadeInUp d-none d-md-none d-lg-block d-xl-block main-common-padding" 
            style={{ backgroundColor: "#e6f7ff" }}
          >
            <div className="container" style={{ margin: "0 auto" }}>
              <Row
                gutter={[16, 16]}
                justify="space-between"
                // style={{ marginTop: "20px" }}
              >
                <Col className="catalogue-bg-img" md={24}>
                  <h2
                   className="txt-font-bigger text-left"
                  >
                    2.5 lakhs SKU's prebuilt to <br />
                    make your custom catalogue <br /> in minutes⚡️
                  </h2>
                  <p className="txt-font-medium" style={{ marginTop: "16px" }}>
                    You can also add items manually
                  </p>
                  <Button
                    type="primary"
                    onClick={() => window.open("/SellerLogin", "_blank")}
                    className="register-style-btn"
                  >
                    Register now
                  </Button>
                  <p className="txt-font-sm" style={{ marginTop: "16px" }}>
                    It takes less than 1 minute to register
                  </p>
                </Col>
                {/* <Col md={8}>
                  <img
                    src={SKUAddIcon}
                    alt="Catalogue"
                    style={{ marginTop: "16px", width: "100%" }}
                  />
                </Col> */}
              </Row>
            </div>
          </section>
          <section className="wow fadeInUp d-block d-sm-block d-lg-none d-xl-none"
            style={{ backgroundColor: "#e6f7ff",padding:"12px 12px 0px 12px" }}
          >
            <div className="container" style={{ margin: "0 auto"}}>
              <Row
                gutter={[16, 16]}
                justify="space-between"
                // style={{ marginTop: "20px" }}
              >
                <Col className="" span={24}>
                  <h2
                    style={{
                      fontSize: "28px",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    2.5 lakhs SKU's prebuilt to <br />
                    make your custom catalogue <br /> in minutes⚡️
                  </h2>
                  <p style={{ marginTop: "16px" }}>
                    You can also add items manually
                  </p>
                  <Button
                    type="primary"
                    onClick={() => window.open("/SellerLogin", "_blank")}
                    className="register-style-btn"
                  >
                    Register now
                  </Button>
                  <p style={{ marginTop: "16px" }}>
                    It takes less than 1 minute to register
                  </p>
                </Col>
                <Col span={24}>
                  <img
                    src={SKUAddIcon}
                    alt="Catalogue"
                    style={{ marginTop: "16px", width: "100%" }}
                  />
                </Col>
              </Row>
            </div>
          </section>
          <section className="wow fadeInUp main-common-padding" style={{ backgroundColor: "white" }}>
            <div className="container" style={{ margin: "0 auto" }}>
              <h2
              className="txt-font-bigger"
              >
                Hear what your clients have to say
              </h2>
              <Carousel
                touchDraggable={true}
                // autoplay
                dotPosition="bottom"
                slidesToShow={1}
                slidesToScroll={1}
                initialSlide={1}
                style={{ textAlign: "left" }}
                className="d-block d-sm-block d-lg-none d-xl-none"
              >
                {testimonialsData.map((testimonial, index) => (
                  <div key={index}>
                    <Card className="card-testimonial text-left bg-testimonial">
                      <Row
                        className="d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col className="p-0">
                          <img
                            src={testimonial.Logo}
                            alt={testimonial.Logo}
                            className={testimonial.logoclass}
                          />
                        </Col>
                        <Col>
                          <Text className="testimonial-badge">
                            {testimonial.firmtype}
                          </Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-0 mt-2">
                          <Col style={{ height: "110px" }}>
                            <Text className="font-italic">
                              {testimonial.quote}
                            </Text>
                          </Col>
                          <Col
                            className="d-flex mt-4"
                            style={{ gap: "10px", alignItems: "center" }}
                          >
                            <Col>
                              {" "}
                              <img
                                src={testimonial.authorimage}
                                alt={testimonial.authorimage}
                              />
                            </Col>
                            <Col style={{ marginTop: "8px" }}>
                              <Typography.Title
                                className="authorname"
                                level={5}
                              >
                                {testimonial.author}
                              </Typography.Title>
                              <Typography.Text
                                className="font-italic"
                                style={{ marginTop: "-10px" }}
                              >
                                {testimonial.position}
                              </Typography.Text>
                            </Col>
                          </Col>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                ))}
              </Carousel>
              <Carousel
                touchDraggable={true}
                // autoplay
                dotPosition="bottom"
                slidesToShow={2}
                slidesToScroll={1}
                initialSlide={1}
                style={{ textAlign: "left" }}
                className="d-none d-md-none d-lg-block d-xl-block"
              >
                {testimonialsData.map((testimonial, index) => (
                  <div key={index}>
                    <Card className="card-testimonial text-left bg-testimonial">
                      <Row
                        className="d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col className="p-0">
                          <img
                            src={testimonial.Logo}
                            alt={testimonial.Logo}
                            className={testimonial.logoclass}
                          />
                        </Col>
                        <Col>
                          <Text className="testimonial-badge">
                            {testimonial.firmtype}
                          </Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-0 mt-2">
                          <Col style={{ height: "110px" }}>
                            <Text className="font-italic">
                              {testimonial.quote}
                            </Text>
                          </Col>
                          <Col
                            className="d-flex mt-4"
                            style={{ gap: "10px", alignItems: "center" }}
                          >
                            <Col>
                              {" "}
                              <img
                                src={testimonial.authorimage}
                                alt={testimonial.authorimage}
                              />
                            </Col>
                            <Col style={{ marginTop: "8px" }}>
                              <Typography.Title
                                className="authorname"
                                level={5}
                              >
                                {testimonial.author}
                              </Typography.Title>
                              <Typography.Text
                                className="font-italic"
                                style={{ marginTop: "-10px" }}
                              >
                                {testimonial.position}
                              </Typography.Text>
                            </Col>
                          </Col>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                ))}
              </Carousel>
            </div>
          </section>
          <section>
            <div className="container" style={{ margin: "0 auto" ,marginBottom: "40px",marginTop: "20px"}}>
              <h2
                 className="txt-font-bigger"
              >
                All major brands SKUs
              </h2>
              {/* <Carousel autoplay dots={false} infinite speed={1000} pauseOnHover={false}  slidesToShow={6}
                slidesToScroll={1}
                initialSlide={1}> */}

              <Marquee autoFill={true}>
                <img
                  src={BrandLogo1}
                  alt="Brand logo 1"
                  className="marquee-img"
                />

                <img
                  src={BrandLogo2}
                  alt="Brand logo 7"
                  className="marquee-img"
                />

                <img
                  src={BrandLogo3}
                  alt="Brand logo 1"
                  className="marquee-img"
                />

                <img
                  src={BrandLogo4}
                  alt="Brand logo 7"
                  className="marquee-img"
                />

                <img
                  src={BrandLogo5}
                  alt="Brand logo 1"
                  className="marquee-img"
                />

                <img
                  src={BrandLogo6}
                  alt="Brand logo 7"
                  className="marquee-img"
                />

                <img
                  src={BrandLogo7}
                  alt="Brand logo 1"
                  className="marquee-img"
                />

                <img
                  src={BrandLogo8}
                  alt="Brand logo 7"
                  className="marquee-img"
                />

                <img
                  src={BrandLogo9}
                  alt="Brand logo 1"
                  className="marquee-img"
                />

                <img
                  src={BrandLogo10}
                  alt="Brand logo 7"
                  className="marquee-img"
                />

                <img
                  src={BrandLogo11}
                  alt="Brand logo 7"
                  className="marquee-img"
                />

                <img
                  src={BrandLogo12}
                  alt="Brand logo 7"
                  className="marquee-img"
                />

                {/* </Carousel> */}
              </Marquee>
            </div>
          </section>
        </Content>
        <Footer className="wow fadeInUp">
          <section className="footer-bottom-section d-none d-lg-block d-md-none">
            <div className="container">
              <div className="row">
                {/* Column for the logo */}
                <div className="col-lg-2 col-12">
                  <PartnerLogoIcon />
                </div>
                {/* Column for Company links */}
                {/* <div className="col-lg-2 col-12 d-flex justify-content-center">
                <div className="footer-bottom-box">
                  <h3>Company</h3>
                  <ul>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        About us
                      </a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        Career
                      </a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        Contact us
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
                {/* Column for For You links */}
                {/* <div className="col-lg-2 col-12 d-flex justify-content-end">
                <div className="footer-bottom-box">
                  <h3>For you</h3>
                  <ul>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        FAQ’s{" "}
                      </a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        Return policy
                      </a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        Privacy policy
                      </a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        Terms and conditions
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
                {/* Column for Other links */}
                <div className="col-lg-4 col-12 d-flex justify-content-end">
                  <div className="footer-bottom-box">
                    {/* <h3>Others</h3> */}
                    <ul>
                      {/* <li>
                       <a href="/SellerLogin" target="_blank" rel="noopener noreferrer">
                         {" "}
                       Register Now
                       </a>
                     </li>
                     <li>
                       <a href="/SellerLogin" rel="noopener noreferrer">
                         {" "}
                       Login
                       </a>
                     </li> */}
                      {/* <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        Large orders(RFQ){" "}
                      </a>
                    </li>
                    <li>
                      <a href="/" rel="noopener noreferrer">
                        {" "}
                        Reselling
                      </a>
                    </li>
                    <li>
                      <a href="/" rel="noopener noreferrer">
                        {" "}
                        Rental
                      </a>
                    </li>
                    <li>
                      <a href="/" rel="noopener noreferrer">
                        {" "}
                        Construction waste
                      </a>
                    </li>
                    <li>
                      <a href="/" rel="noopener noreferrer">
                        {" "}
                        Become a supplier
                    </a>
                  </li> */}
                    </ul>
                  </div>
                </div>
                {/* Column for Contact information */}
                <div className="col-lg-6 col-12 d-flex justify-content-end">
                  <div className="footer-bottom-box">
                    <h3>Need help with something?</h3>
                    <p>
                      Call us:
                      <a href="tel:+91 8660423608" className="number-style1">
                        +91 8660423608
                      </a>
                    </p>
                    <p>
                      Watsapp:
                      <a
                        href="https://api.whatsapp.com/send?phone=918970415365&amp;text="
                        className="number-style2"
                      >
                        +91 8970415365
                      </a>
                    </p>
                    <p>
                      Mail us:
                      <a
                        href="mailto:ask@madoverbuildings.com"
                        className="number-style3"
                      >
                        ask@madoverbuildings.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="footer-bottom-section d-block d-lg-none d-md-none">
            <div className="container">
              <div className="row">
                {/* Logo */}
                <div className="col-12">
                  <PartnerLogoIcon />
                </div>
                {/* Company Links */}
                {/* <div className="col-6">
                <div className="footer-bottom-box footer-box-1mobile">
                  <h3>Company</h3>
                  <ul>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        About us
                      </a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        Career
                      </a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        Contact us
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
                {/* Others Links */}
                <div className="col-6">
                  <div className="footer-bottom-box footer-box-1mobile">
                    {/* <h3>Others</h3> */}
                    <ul>
                      {/* <li>
                      <a href="/Sellerlogin" target="_blank" rel="noopener noreferrer">
                        {" "}
                        Register Now
                      </a>
                    </li>
                    <li>
                      <a href="/Sellerlogin" target="_blank" rel="noopener noreferrer">
                        {" "}
                        Login
                      </a>
                    </li> */}
                      {/* <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        Privacy policy
                      </a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        {" "}
                        Terms and conditions
                      </a>
                    </li> */}
                    </ul>
                  </div>
                </div>
                {/* For You Links */}
                {/* <div className="col-6">
                <div className="footer-bottom-box footer-box-1mobile">
                  <h3>For you</h3>
                  <ul>
                    <li>
                      <a href="/"> FAQ’s </a>
                    </li>
                    <li>
                      <a href="/"> Return policy</a>
                    </li>
                    <li>
                      <a href="/"> Privacy policy</a>
                    </li>
                    <li>
                      <a href="/"> Terms and conditions</a>
                    </li>
                  </ul>
                </div>
              </div> */}
                {/* Contact Information */}
                <div className="col-12">
                  <div className="footer-bottom-box">
                    <h3>Need help with something?</h3>
                    <p>
                      Call us:
                      <a href="tel:8660423608" className="number-style1">
                        +91 8660423608
                      </a>
                    </p>
                    <p>
                      Watsapp:
                      <a
                        href="https://api.whatsapp.com/send?phone=918970415365&amp;text="
                        className="number-style2"
                      >
                        +91 8970415365
                      </a>
                    </p>
                    <p>
                      Mail us:
                      <a
                        href="mailto:ask@madoverbuildings.com"
                        className="number-style3"
                      >
                        ask@madoverbuildings.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="border-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="border-top-style"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="footer-last-section d-none d-md-none d-xl-block d-lg-block">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-4 col-6">
                      <div className="input-group footer-drop-down-style">
                        <label className="input-group-text">
                          <img src={Flag} alt="National Flag" />
                        </label>
                        <select
                          value={selectedValue}
                          className="form-select"
                          onChange={handleChange}
                        >
                          <option value="0">India</option>
                          {/*<option value="1">India</option>
                                            <option value="2">India</option>
                                            <option value="3">India</option>*/}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="input-group footer-drop-down-style">
                        <label className="input-group-text">
                          <img src={Globe} alt="Globe Icon" />
                        </label>
                        <select
                          value={selectedValue}
                          className="form-select"
                          onChange={handleChange}
                        >
                          <option value="0">English</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="footer-social-icon-style">
                    <ul>
                      <li className="desktop-style-text-folllow">
                        Follow us on
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/mad-over-buildings/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={linkedin} alt="linkedin" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://m.facebook.com/madoverbuildings/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={facebook} alt="facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/madoverbuildings_mob/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={instagram} alt="insta" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/mobforindia"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={twitter} alt="twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <p className="company-name-style">
                    2024 by Madoverbuilding AI Private Limited
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="footer-last-section d-block d-lg-none d-md-none d-xl-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-4 col-6">
                      <div className="input-group footer-drop-down-style">
                        <label className="input-group-text">
                          <img src={Flag} alt="National Flag" />
                        </label>
                        <select
                          className="form-select"
                          value={selectedValue}
                          onChange={handleChange}
                        >
                          <option value="0">India</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="input-group footer-drop-down-style">
                        <label className="input-group-text">
                          <img src={Globe} alt="Globe Icon" />
                        </label>
                        <select
                          value={selectedValue}
                          className="form-select"
                          onChange={handleChange}
                        >
                          <option value="0">English</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="footer-social-icon-style">
                    <p className="follows-on-top">Follow us on</p>
                    <ul>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/mad-over-buildings/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={linkedin} alt="linkedin" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://m.facebook.com/madoverbuildings/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={facebook} alt="facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/madoverbuildings_mob/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={instagram} alt="instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/mobforindia"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <img src={twitter} alt="twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <p className="company-name-style">
                    2024 by Madoverbuilding AI Private Limited
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Footer>
      </Layout>
      <Modal
        className="youtube-modal"
        width={"75%"} // increase width to 800px
        height={"75%"} // increase height to 600px
        wrapClassName="modal-center"
        title={modalVideoTitle}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setModalVideoUrl("");  
          // setautoPlay("0");
           document.getElementById("youtube-iframe").src = "";         
           document.getElementById("youtube-iframe").src = autoPlay;  
        }}
        footer={null}
      >
        {modalVisible && (
          <iframe
            id="youtube-iframe"
            width="100%"
            height="100%"
            src={modalVideoUrl}
            // src={`https://www.youtube.com/embed/${modalVideoId}?autoplay=1`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            webkitallowfullscreen
            mozallowfullscreen
            onClick={() => {
              const iframe = document.getElementById("youtube-iframe");
              iframe.requestFullscreen();
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default Seller;
