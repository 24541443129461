import { React, useEffect, useState } from "react";
import {
  useLazyGetVendorUserDetailsQuery,
  useUserCreationMutation,
  useUserDeleteMutation,
  useLazyGetUserRolesDetailsQuery,
  useUserRoleUpdateCreationMutation
} from "../../../apis/Seller/SellerAccountsAPI";
import { SellerDashboardSkeleton } from "../../../components/skeleton/SellerDashboardSkeleton";
import { Table, Avatar, Space, Row, Col, Modal, Button, Form,Select } from "antd";
import EditIcon from "../../../assets/seller/icons/edit-2.svg";
import userremove from "../../../assets/seller/icons/userremove.svg";
import rolechange from "../../../assets/seller/icons/rolechange.svg";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import SellerUserAdd from "./SellerUserAdd";

export default function SellerMemberAccess() {
  const [CreateUserApi] = useUserCreationMutation();
  const [DeleteUserApi] = useUserDeleteMutation();
  const [UpdateUserRolesApi] = useUserRoleUpdateCreationMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Userform] = Form.useForm();
  const [selectedRow, setSelectedRow] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleReset = () => {
    setIsModalOpen(false);
    Userform.resetFields();
    setIsEditModalOpen(false);
  };
  const [SellerUsers, { data: SellerUserData, isFetching: isLoading }] =
    useLazyGetVendorUserDetailsQuery();

  useEffect(() => {
    SellerUsers();
  }, [SellerUsers]);
  // eslint-disable-next-line
  const [RoleDetails, { data: RolesData, isFetching: isRolesLoading }] =
  useLazyGetUserRolesDetailsQuery();

useEffect(() => {
  RoleDetails();
}, [RoleDetails]);
  const handleRemoveMember = (memberId,person_full_name) => {
    // code to remove the member with the given ID
    Modal.confirm({
      title: '',
      className:'ant-modal-confirmation',
      content: (
        <div>
          <img src={userremove} alt="Remove Icon" style={{ width: "160px", height: "120px" }} />
          <p className="text-header">Are you sure you want to remove {person_full_name}?</p>
          <p className="text-head-light">
            Once removed, this member will not be able to access the store details.
          </p>
        </div>
      ),
      okText: 'YES, REMOVE MEMBER',
      cancelText: 'CANCEL',
      cancelClass: 'btn-cancel order-cancel-btn-style btnI',
      okClass: 'btn-del-danger', // add this
      onOk: () => {
        handleUserDelete(memberId);
      },
      okButtonProps: {
        className: 'btn-del-danger',
      },
      cancelButtonProps: {
        className: 'btn-cancel order-cancel-btn-style btnI',
      },
   
    });
    // ...
  };
  const handleUserDelete = async (values) => {
   
    if (values) {    
      const response = await DeleteUserApi({
        sub_admin_id: values,       
      });
      console.log("response?.status", response?.data?.status);
      if (response?.data?.status === false) {
        showErrorToast(response?.data?.message);
      } else if (response?.error?.data?.status === false) {
        showErrorToast(response?.error?.data?.message);
        
      } else {
        setIsModalOpen(false);
        showSuccessToast(response?.data?.message);        
        SellerUsers();
        
      }
    }
  };
  const handleUpdateUserRole = async (values) => {
  
   
    if (values) {    
      const response = await UpdateUserRolesApi({
        user_id: values.user_id,  
        groups: [values.groups],
        user_permissions:[],     
      });
     
      if (response?.data?.status === false) {
        showErrorToast(response?.data?.message);
      } else if (response?.error?.data?.status === false) {
        showErrorToast(response?.error?.data?.message);
        
      } else {
        setIsModalOpen(false);
        showSuccessToast(response?.data?.message);        
        SellerUsers();
        
      }
    }
  };
  const handleRoleChange = (memberId,person_full_name,Role) => {
    // code to remove the member with the given ID
    Modal.confirm({
      title: '',
      className:'ant-modal-confirmation',
      content: (
        <div>
          <img src={rolechange} alt="role change Icon" style={{ width: "160px", height: "120px" }} />
          <p className="text-header">Are you sure you want to change role of {person_full_name}?</p>
          <p className="text-head-light">
            Once changed, this member will able to access the screens based on selected role.
          </p>
        </div>
      ),
      okText: 'UPDATE',
      cancelText: 'CANCEL',
      cancelClass: 'btn-cancel order-cancel-btn-style btnI',
      okClass: 'btn-theme order-got-btn-style btnI', // add this
      onOk: () => {
        handleUpdateUserRole({user_id:memberId,groups:Role});
      },
      okButtonProps: {
        className: 'btn-theme order-got-btn-style btnI',
      },
      cancelButtonProps: {
        className: 'btn-cancel order-cancel-btn-style btnI',
      },
   
    });
    // ...
  };
  
  
  const handleUserCreate = async (values) => {
    if (values) {
      const response = await CreateUserApi({
        person_full_name: values.person_full_name,
        username: values.username,
        groups: [values.groups],
        password: values.password || "123456",
        user_permissions: [],
      });
      console.log("response?.status", response?.data?.status);
      if (response?.data?.status === false) {
        showErrorToast(response?.data?.message);
      } else if (response?.error?.data?.status === false) {
        showErrorToast(response?.error?.data?.message);
        Userform.resetFields();
      } else {
        showSuccessToast(response?.data?.message);
        Userform.resetFields();
        setIsModalOpen(false);
        SellerUsers();
      }
    }
  };

  const functionEdit = (record) => {
    setSelectedRow(record);
    setIsModalOpen(true);
    setIsEditModalOpen(true);
  };

  const columns = [
    
    {
      title: "Name",
      dataIndex: "person_full_name",
      key: "name",
      render: (text, record) => (
        <div className="d-flex mt-2">
          {record?.seller_display_image ? (
            <Avatar
              src={record?.seller_display_image}
              className="avatar-6 img-fluid-6 rounded-circle mr-1"
            />
          ) : (
            <span className="member_img">
              {record?.person_full_name?.slice(0, 2).toUpperCase()}
            </span>
          )}

          <p className="mt-2">
            <span className="member-name">{record?.person_full_name}</span>
            <br />
            <span className="member-email">{record?.email}</span>
          </p>
        </div>
      ),
    },

    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      align: "right",
      render: (text, record) => (
        <Select className="change-password-input no-border-class"  defaultValue={record?.roleid} placeholder="Select role" 
        onChange={(value) => {
          // handle the change event here
        
          // call your method here with the selected value
          handleRoleChange(record?.id,record?.person_full_name,value);
        }}
        >
          {RolesData?.data &&
            RolesData?.data.map((role) => (
              <Select.Option key={role.id} value={role.id}>
                {role.name}
              </Select.Option>
            ))}
        </Select>
      )
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (_, record) => (
        <Space size="middle">
          {/* <Button onClick={() => functionDeletePOScontact(record)}>
            Delete
          </Button> */}
          <Button className="btn-blank" onClick={() => functionEdit(record)}>
            <img src={EditIcon} alt=""></img>
          </Button>
        </Space>
      ),
    },
  ];

  const data = SellerUserData?.data?.map((user) => ({
    person_full_name: user.person_full_name,
    email: user.email,
    created_at: user.created_at,
    role: user?.permissions?.groups[0]?.name || "NA",
    roleid: user?.permissions?.groups[0]?.id || "NA",
    seller_display_image: user.seller_display_image,
    id: user.id,
  }));
  return (
    <>
      {isLoading ? (
        <SellerDashboardSkeleton />
      ) : (
        <>
          <Row>
            <Col span={14}>
              <Col style={{ display: "flex", justifyContent: "space-between" }}>
                <Col>
                  <h5 className="">Members</h5>
                  <p className="text-muted">
                    {SellerUserData?.data.length || 0} <span>members</span>
                  </p>
                </Col>
                <Col>
                  <div className="text-right mt-2">
                    <button
                      type="button"
                      className="btn btn-theme btn-mob-size"
                      onClick={() => setIsModalOpen(true)}
                    >
                      ADD MEMBERS
                    </button>
                  </div>
                </Col>
              </Col>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                className="table table-bordered mt-2 border-radius-12 first-child-border table-member"
                id="tblmembers"
              />
            </Col>
          </Row>
          <Modal
            title="Invite member"
            visible={isModalOpen}
            onCancel={handleReset}
            footer={[
              <Form
                form={Userform}
                name="passwordChange"
                autoComplete="off"
                style={
                  {
                    // maxWidth: 500,
                  }
                }
                layout="vertical"
                onFinish={handleUserCreate}
              >
                {!isEditModalOpen ? (
                  <div className="modal-footer d-block text-center footer-bottom-border-line">
                    <Button
                      key="cancel"
                      className="btn-cancel order-cancel-btn-style btnI"
                      // onClick={handleModalClose}
                      onClick={handleReset}
                    >
                      CANCEL
                    </Button>
                    <Button
                      key="gotIt"
                      type="primary"
                      className="btn-theme order-got-btn-style btnI"
                      htmlType="submit"
                    >
                      SEND INVITE
                    </Button>
                  </div>
                ) : (
                  <div className="modal-footer d-block text-center footer-bottom-border-line">
                    <Button
                      key="removesubadmin"
                      type="primary"
                      className="btn-cancel order-cancel-btn-style btnI"
                      // htmlType="submit"
                      style={{ color: "#de350b" }}
                      onClick={(memberId) =>
                        handleRemoveMember(selectedRow?.id,selectedRow?.person_full_name)
                      }
                    >
                      REMOVE MEMBER
                    </Button>
                  </div>
                )}
              </Form>,
            ]}
          >
            <SellerUserAdd
              Userform={Userform}
              handleUserCreate={handleUserCreate}
              selectedRow={selectedRow}
              isEditModalOpen={isEditModalOpen}
              RolesData={RolesData}
            />
          </Modal>
        </>
      )}
    </>
  );
}
