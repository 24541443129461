import React, { useState } from "react";
import { Card, Col, Row, Typography, Tabs, Button, Badge, Modal } from "antd";

import { useNavigate } from "react-router-dom";
import OrderStaticText from "./DashboardStatic/OrderStaticText";
import { ReactComponent as TagIcon } from "../../../assets/seller/icons/tag.svg";
import { ReactComponent as UserIcon } from "../../../assets/seller/icons/user.svg";
import CartIcon from "../../../assets/seller/icons/cart.svg";
import MoneyBagIcon from "../../../assets/seller/icons/moneybag.webp";
import MobCreditIcon from "../../../assets/seller/icons/credit.svg";
import MobCreditButtonIcon from "../../../assets/seller/icons/Mobcreditlogo1.svg";
import SellerOrders from "../../../components/SellerComponents/SellerOrdersComponent/SellerOrdersComponent";
import { ReactComponent as NewOrder } from "../../../assets/seller/icons/NewOrder.svg";
import { ReactComponent as ReadyForPickup } from "../../../assets/seller/icons/ReadyForPickup.svg";
import { ReactComponent as Upcomming } from "../../../assets/seller/icons/Upcomming.svg";
import { ReactComponent as AttentionRequired } from "../../../assets/seller/icons/AttentionRequired.svg";
import { ReactComponent as TotalSalesAmount } from "../../../assets/seller/icons/TotalSalesAmount.svg";
import { ReactComponent as ReturExchange } from "../../../assets/seller/icons/ReturnExchange.svg";
import MobCreditVideo from "./../SellerMobCreditOnboarding/MobCreditVideo";
import { onlyDateFormat } from "../../../commonFuntions/CommonUtilFunctions";
const { Text } = Typography;

function SellerActiveDashboard({ dashboardData }) {
  const navigate = useNavigate();
  const bmp_credit_enable =
    localStorage.getItem("bmp_credit_enable") === "true";
  const bmp_plan_expiry = localStorage.getItem("bmp_plan_expiry");
  const currentDate = new Date();
  const options = { weekday: "long", month: "long", day: "numeric" };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);
  const { Title } = Typography;
  const { TabPane } = Tabs;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleViewDetals = (status) => {
    navigate(`/Seller/SellerOrders/`, { state: { status } });
  };
  const count = [
    {
      Status: "New orders",
      title: dashboardData?.data?.new_orders_total,
      icon: <NewOrder />,
      class: "cursor-pointer",
      onClick: () => handleViewDetals("New orders"),
    },
    {
      Status: "Ready for pickup",
      title: dashboardData?.data?.ready_orders_total,
      icon: <ReadyForPickup />,
      class: "cursor-pointer",
      onClick: () => handleViewDetals("Ready for pickup"),
    },
    {
      Status: "Upcoming",
      title: dashboardData?.data?.upcoming,
      icon: <Upcomming />,
      class: "cursor-pointer",
      onClick: () => handleViewDetals("Upcoming"),
    },
    {
      Status: "Attention required",
      title: "0",
      icon: <AttentionRequired />,
      // onClick: () => console.log("New orders clicked"),
    },
    {
      Status: "Total sales amount",
      title: `₹ ${dashboardData?.data?.total_sales_amount.toFixed(2)}`,
      icon: <TotalSalesAmount />,
      // onClick: () => console.log("New orders clicked"),
    },
    {
      Status: "Returns/ Exchange",
      title: dashboardData?.data?.returns_and_exchange,
      icon: <ReturExchange />,
      //  onClick: () => console.log("New orders clicked"),
    },
  ];
  return (
    <>
      <div className="layout-content">
        <Row className="mb-3">
          <Col span={14} sm={8} md={8} lg={14} xl={8} className="col-info">
            <>
              <span className="today-style">Today</span>
              <span className="font-semibold m-0 formatted-date">
                {" "}
                {formattedDate}
              </span>
            </>
          </Col>
          {/* <Col span={12} sm={8} md={8} lg={8} xl={8}> */}
          <OrderStaticText heading={"How to use?"} description={""} />
          {/* </Col> */}
        </Row>
        <div className="d-block d-sm-block d-lg-none d-xl-none">
          <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
            <Row className="mb-4">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                <Button
                  onClick={() =>
                    (window.location.href = "/seller/createProduct/")
                  }
                  className="btn-outline-default color-style width-100 btn-height-style "
                >
                  <TagIcon style={{ marginRight: "12px" }} />
                  Add new item
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ margintop: "20px" }}
              >
                <Badge
                  // count={"Coming Soon"}
                  // disabled
                  className="badge-outlined margin-badge width-100"
                >
                  <button
                    className="btn-theme btn-height-style text-left btn-height-home add-new-style-font width-100 button-inner"
                    onClick={() =>
                      (window.location.href = "/seller/storesales")
                    }
                  >
                    <img
                      src={CartIcon}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "12px",
                      }}
                      alt=""
                    />
                    Add new store sale
                  </button>
                </Badge>
              </Col>
            </Row>
            {/* <Row className="mt-4 mb-4">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ margintop: "20px" }}
            >
              <Card className="mob-credit">
                <Col className="mob-credit-badge">
                  <img src={MobCreditIcon} className="w-100" alt="" />
                </Col>
                <p>
                  Do you want to offer line of credit solution to your customer?
                </p>
                <Row>
                  <Col span={12}>
                    <img src={MoneyBagIcon} className="money-bag" alt="" />
                  </Col>
                  <Col span={12} className="text-right mt-4">
                    <Text
                      className="hyperlink-text mobcredit-applynow"
                      onClick={() =>
                        window.open("/Seller/mobcreditonboarding", "_blank")
                      }
                    >
                      Apply Now
                    </Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row> */}
            {!bmp_credit_enable ? (
              <Row className="mt-4">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ margintop: "20px" }}
                >
                  <Card className="mob-credit">
                    <Col className="mob-credit-badge">
                      <img src={MobCreditIcon} className="w-100" alt="" />
                    </Col>
                    <p>
                      Do you want to offer line of credit solution to your
                      customer?
                    </p>
                    <Row>
                      <Col span={12}>
                        <img src={MoneyBagIcon} className="money-bag" alt="" />
                      </Col>
                      <Col span={12} className="text-right mt-4">
                        <Text
                          className="hyperlink-text mobcredit-applynow"
                          onClick={() =>
                            window.open("/seller/mobcreditonboarding", "_blank")
                          }
                        >
                          Apply Now
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            ) : (
              <Row className="mt-4">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ margintop: "20px" }}
                >
                  <Card
                    title={
                      <>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <img src={MobCreditButtonIcon} alt=""></img>
                          <Text className="text-white">Active</Text>
                        </Col>
                      </>
                    }
                    className="customer-section"
                  >
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text>
                        Valid until:{" "}
                        {bmp_plan_expiry && onlyDateFormat(bmp_plan_expiry)}
                      </Text>
                      {/* <Text><a href="/seller/mobcredit/howtouse" className="how-touse-link" target="_blank"> How to use?</a></Text> */}
                      <Text
                        className="how-touse-link cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsModalVisible(true);
                        }}
                      >
                        How to use?
                      </Text>
                    </Col>
                    <Row>
                      <Col span={24} className="mt-2">
                        <Button
                          icon={<UserIcon />}
                          className="mobcredit-newclient"
                          onClick={() =>
                            window.open("/seller/customercredit", "_blank")
                          }
                        >
                          Onboard a new client
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
        </div>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={14} xl={16} className="mb-24">
            {/* <Card bordered={false} className="criclebox h-full"> */}
            <Row className="rowgap-vbox" gutter={[24, 0]}>
              {count.map((c, index) => (
                <Col
                  key={index}
                  xs={12}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className={`mb-24 ${c.class}`}
                >
                  <Card
                    bordered={true}
                    style={{ padding: "1px" }}
                    className="info-box"
                    onClick={c.onClick}
                  >
                    <div className="number  ">
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={5}
                          xl={8}
                          align="left"
                          className=" info-box-content text-left d-none d-md-block d-lg-block d-xl-block"
                        >
                          <div className="icon-box">{c.icon}</div>
                        </Col>
                        <Col
                          xs={24}
                          sm={12}
                          md={12}
                          lg={19}
                          xl={16}
                          align="right"
                          className="padding-0"
                        >
                          <span className="info-box-text">{c.Status}</span>
                          <Title
                            className="info-box-number new-addnumber"
                            level={3}
                          >
                            {c.title}
                          </Title>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row className="mt-4 mb-2">
              <Tabs defaultActiveKey="1">
                <TabPane
                  tab={
                    <span>
                      New orders{" "}
                      <span className="order-badge-rounded">
                        {dashboardData?.data?.new_orders_total}
                      </span>
                    </span>
                  }
                  key="1"
                  style={{ marginRight: "0px" }}
                >
                  <OrderStaticText
                    heading={"How to accept order?"}
                    description={
                      "These are the new orders recieved from MOB. Please view the order and accept it if you have all the items. Call MOB support if items are not available"
                    }
                  />

                  <SellerOrders
                    data={dashboardData?.data?.new_orders.slice(0, 10)}
                  />
                  {dashboardData?.data?.new_orders.length > 0 && (
                    <Col
                      className="viewall-button"
                      onClick={() => handleViewDetals("New orders")}
                    >
                      View all
                    </Col>
                  )}
                </TabPane>
                <TabPane
                  tab={
                    <span>
                      Ready for pickup{" "}
                      <span className="order-badge-rounded">
                        {dashboardData?.data?.ready_orders_total}
                      </span>
                    </span>
                  }
                  key="2"
                  style={{ marginLeft: "0px" }}
                >
                  <OrderStaticText
                    heading={"How to accept order?"}
                    description={
                      "These are the new orders recieved from MOB. Please view the order and accept it if you have all the items. Call MOB support if items are not available"
                    }
                  />
                  <SellerOrders
                    data={dashboardData?.data?.ready_orders.slice(0, 10)}
                  />
                  {dashboardData?.data?.ready_orders.length > 0 && (
                    <Col
                      className="viewall-button"
                      onClick={() => handleViewDetals("Ready for pickup")}
                    >
                      View all
                    </Col>
                  )}
                </TabPane>
              </Tabs>
            </Row>
            {/* </Card> */}
          </Col>

          <Col
            xs={24}
            sm={24}
            md={12}
            lg={10}
            xl={8}
            className="mb-24 d-none d-md-block d-lg-block d-xl-block"
          >
            <Row className="mb-4">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                <Button
                  onClick={() =>
                    (window.location.href = "/seller/createProduct/")
                  }
                  className="btn-outline-default color-style width-100 btn-height-style"
                >
                  <TagIcon style={{ marginRight: "12px" }} />
                  Add new item
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ margintop: "20px" }}
              >
                <Badge
                  // count={"Coming Soon"}
                  // disabled
                  className="badge-outlined margin-badge width-100"
                >
                  <button
                    // disabled
                    className="btn-theme btn-height-style text-left btn-height-home add-new-style-font width-100 button-inner"
                    onClick={() =>
                      (window.location.href = "/seller/storesales")
                    }
                  >
                    <img
                      src={CartIcon}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "12px",
                      }}
                      alt=""
                    />
                    Add new store sale
                  </button>
                </Badge>
              </Col>
            </Row>
            {!bmp_credit_enable ? (
              <Row className="mt-4">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ margintop: "20px" }}
                >
                  <Card className="mob-credit">
                    <Col className="mob-credit-badge">
                      <img src={MobCreditIcon} className="w-100" alt="" />
                    </Col>
                    <p>
                      Do you want to offer line of credit solution to your
                      customer?
                    </p>
                    <Row>
                      <Col span={12}>
                        <img src={MoneyBagIcon} className="money-bag" alt="" />
                      </Col>
                      <Col span={12} className="text-right mt-4">
                        <Text
                          className="hyperlink-text mobcredit-applynow"
                          onClick={() =>
                            window.open("/seller/mobcreditonboarding", "_blank")
                          }
                        >
                          Apply Now
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            ) : (
              <Row className="mt-4">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ margintop: "20px" }}
                >
                  <Card
                    title={
                      <>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <img src={MobCreditButtonIcon} alt=""></img>
                          <Text className="text-white">Active</Text>
                        </Col>
                      </>
                    }
                    className="customer-section"
                  >
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text>
                        Valid until:{" "}
                        {bmp_plan_expiry && onlyDateFormat(bmp_plan_expiry)}
                      </Text>
                      {/* <Text><a href="/seller/mobcredit/howtouse" className="how-touse-link" target="_blank"> How to use?</a></Text> */}
                      <Text
                        className="how-touse-link cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsModalVisible(true);
                        }}
                      >
                        How to use?
                      </Text>
                    </Col>
                    <Row>
                      <Col span={24} className="mt-2">
                        <Button
                          icon={<UserIcon />}
                          className="mobcredit-newclient"
                          onClick={() =>
                            window.open("/seller/customercredit", "_blank")
                          }
                        >
                          Onboard a new client
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
      <Modal
        title="How does mobCREDIT work?"
        width={"65%"}
        height={"65%"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <div className="padding-16px">
          <MobCreditVideo />
        </div>
      </Modal>
    </>
  );
}

export default SellerActiveDashboard;
