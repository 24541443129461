import {
  Avatar,
  Button,
  Col,
  Skeleton,
  Typography,
  Card,
  Row,
  Badge,
  Select,
} from "antd";

import React, { useEffect, useState } from "react";
// import { ArrowLeftOutlined } from "@ant-design/icons";
import "./SellerViewDetails.css";

// import { ReactComponent as DwonloadIcon } from "../../../../../assets/icons/download.svg";
import { ReactComponent as NotesIconIcon } from "../../../../../assets/seller/icons/notesicon.svg";

//   import StatusDropDown from "../SellerOrderItems/StatusDropDown";
// eslint-disable-next-line
import { useNavigate, useParams } from "react-router-dom";
//   import NoVehicleAssigned from "./NoVehicleAssigned/NoVehicleAssigned";
// import DeliveryTable from "./SellerDeliveryTable/SellerDeliveryTable";

import { dateFormat } from "../../../../../commonFuntions/CommonUtilFunctions";
// import { ReactComponent as InvoiceIcon } from "../../../../../assets/seller/icons/invoice.svg";
//   import AddVehicleModal from "./AddVehicleModal/AddVehicleModal";
//   import CustomProfileSection from "../../../../components/CustomContainer/CustomProFileSection/CustomProfileSection";
//   import SelectedVehicleTable from "./SelectedVehicleTable/SelectedVehicleTable";

// import { UpArrow } from "../../../../components/Icons/UpArrow";
// import { DownArrow } from "../../../../components/Icons/DownArrow";

import { useLazySellerGetStockDetailsQuery } from "../../../../../apis/Seller/SellerStoreSalesAPI";
import { useDispatch, useSelector } from "react-redux";
import {
  editSubOrderDetailSelector,
  // vehicleDetailSelector,
  // vehicleDetailSelector,
} from "../../../../../redux/slices/manageOrders/selector";
import { getEditSubOrderDetail } from "../../../../../redux/slices/manageOrders/action";
import {
  showErrorToast,
  // showSuccessToast,
} from "../../../../../NotificationToast/NotificationToast";

import { baseUrl } from "../../../../../commonUtils/commonUtils";
import {
  handlePdfDownload,
  testFileUrl,
} from "../../../../../commonFuntions/CommonUtilFunctions";
import SellerStockTable from "./SellerDeliveryTable/SellerStockTable";

const { Text } = Typography;

const SellerOutofStockView = () => {
  const { id } = useParams();

  // const bmp_id = localStorage.getItem("bmp_id");
  const bmp_name = localStorage.getItem("bmp_name");

  // eslint-disable-next-line
  const [paymentStatus, setPaymentStatus] = useState("");

  const editSubOrderData = useSelector(editSubOrderDetailSelector);
  // console.log(
  //   "🚀 ~ SellerStoreViewDetails ~ editSubOrderData:",
  //   editSubOrderData
  // );

  const [
    getStockDetailApi,
    { data: editSubData, isFetching: isFetchingEditSubData },
  ] = useLazySellerGetStockDetailsQuery();

  // eslint-disable-next-line
  const [checkboxState, setCheckboxState] = useState({
    email: false,
    phone_number: false,
  });

  // const handleNavigateDemand = (url) => {
  //   window.open(`${url}`, "_blank");

  //   // window.open(`${"uate/"}${demandRupifiUrl}${item}`, "_blank");
  // };

  const handleViewPdf = (url) => {
    if (testFileUrl(url || "")) {
      handlePdfDownload(url);
    } else {
      showErrorToast("Something went wrong");
    }
  };

  // const [
  //   updateOrderVehicleRequestApi,
  //   { isLoading: updateOrderVehicleRequestLoading },
  //   //  { isSuccess: successUpdateOrderStatusApi }
  // ] = useUpdateVehcileRequestStatusMutation();
  // const vehicleDetailData = useSelector(vehicleDetailSelector);

  // const navigate = useNavigate();
  const dispatch = useDispatch();

  // const onEdit = (data) => {
  //   setVehicleUpdateData(data);
  //   setUpdateVehicleModalOpen(true);
  // };

  // eslint-disable-next-line

  const columnsDeliveryBy = [
    {
      title: `${editSubOrderData?.stocks?.length} Items`,
      width: "200px",
      // dataIndex: "products",
      render: (params, record) => {
        return (
          <>
            <Col className="deliveryBy-wrapper">
              <Col>
                <Avatar
                  size={44}
                  shape="square"
                  src={`${baseUrl}${params?.product?.image}`}
                />
              </Col>
              <Col
                className="deliveryBy-items"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  width: "250px",
                }}
              >
                <Text>{params?.product?.item_name_title}</Text>
                {params?.product?.hsn_code && (
                  <Text style={{ color: "#6c7c8c" }}>
                    HSN: {params?.product?.hsn_code}
                  </Text>
                )}
              </Col>
            </Col>
          </>
        );
      },
    },

    {
      title: "Unit",
      width: "120px",
      align: "center",
      // dataIndex: "Unit",
      render: (params, record) => {
        return (
          <>
           
                <Text>{params?.product?.unit}</Text>
                
           
          </>
        );
      },
    },
    {
      title: "Quantity",
      width: "120px",
      align: "center",
      dataIndex: "stock",
    },
    {
      title: "Notes",
      width: "180px",
      dataIndex: "notes",
      align: "right",
      render: (params, record) => {
        return (
          <Col className="text-right" style={{ marginRight: "16px" }}>
            {params || ""}
          </Col>
        );
      },
    },
  ];
  // const handleBack = () => {
  //   navigate(-1);
  // };

  useEffect(() => {
    getStockDetailApi(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (editSubData?.status) {
      dispatch(getEditSubOrderDetail(editSubData?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSubData]);

  return (
    <>
      <Col className="view-detail-wrapper">
        <Col className="view-detail-status no-border">
          <Col className="detail-activity">
            {/* <Button
              className="view-detail-btn"
              icon={<ArrowLeftOutlined />}
              onClick={handleBack}
            >
              BACK
            </Button> */}
            {/* <ActivityContainer onClick={showDrawer}>
              <ClockIcon style={{ height: "20px", width: "20px" }} />
              <ActivityText level={5}>Activity log</ActivityText>
            </ActivityContainer> */}
          </Col>
          {isFetchingEditSubData ? (
            <>
              <Skeleton active paragraph={{ rows: 2 }} />
            </>
          ) : (
            <>
              <Col className="detail-invoice-wrapper">
                <Col className="comp-wrapper">
                  {/* <Button
                  className="view-detail-btn"
                  icon={<ShareIcon />}
                  onClick={handleBack}
                >
                  SHARE
                </Button> */}
                  <Button
                  className="view-detail-btn"
                  icon={<NotesIconIcon />}
                  onClick={() => handleViewPdf(editSubData?.data?.invoice)}
                >
                  DOWNLOAD
                </Button>

                  {editSubData?.data?.proforma_invoice &&
                    editSubData?.data?.proforma_invoice.length > 0 && (
                      <Select
                        className="ddl-width"
                        placeholder={
                          <>
                            <NotesIconIcon /> Proforma invoice
                          </>
                        }
                        options={editSubData?.data?.proforma_invoice?.map(
                          (item) => {
                            const url = new URL(item);
                            const fileName = url.pathname.split("/").pop();
                            return {
                              value: item,
                              label: fileName,
                            };
                          }
                        )}
                        onChange={(selectedOption) =>
                          handleViewPdf(selectedOption)
                        }
                      />
                    )}
                </Col>
                <Col className="comp-wrapper">
                  {/* <Link
                    to={`/Seller/StoreSales/update-order/${editSubData?.data?.order_id}`}
                    target="_blank"
                  >
                    <Button className="view-detail-btn" icon={<EditIcon />}>
                      EDIT SALE
                    </Button>
                  </Link> */}
                </Col>
              </Col>
            </>
          )}
        </Col>

        {isFetchingEditSubData ? (
          <>
            <Skeleton active paragraph={{ rows: 8 }} />
          </>
        ) : (
          <>
            <Card className={"no-strip"} title={""}>
              <Row>
                <Col
                  style={{ display: "flex" }}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={16}
                >
                  <Text className="text-heading">Out of stock</Text>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={8}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Badge className="bg-bmp-square">
                    <Text className="text-center">{bmp_name}</Text>
                  </Badge>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={12} md={8} lg={6} xl={12}>
                  {/* <Typography.Title className="text-invoice-billing" level={5}>
                    BILLED BY
                  </Typography.Title> */}
                  <Typography.Title level={5}>
                    {editSubData?.data?.address?.name}
                  </Typography.Title>
                  <Row>
                    {" "}
                    <Text className="text-detail">
                      {editSubData?.data?.address?.address}
                    </Text>
                  </Row>
                  <Row>
                    <Text className="text-detail">
                      {editSubData?.data?.address?.address_line_2}
                    </Text>
                  </Row>
                  <Row>
                    <Text className="text-detail">
                      Ph no: {editSubData?.data?.address?.phone_number}
                    </Text>
                  </Row>
                  {editSubData?.data?.address?.email && (
                    <Row>
                      <Text className="text-detail">
                        Email Id: {editSubData?.data?.address?.email || ""}
                      </Text>
                    </Row>
                  )}
                  {editSubData?.data?.address?.gst_number && (
                    <Row>
                      <Text>
                        GSTIN: {editSubData?.data?.address?.gst_number || ""}
                      </Text>
                    </Row>
                  )}
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={12}>
                  <Row>
                    <Text className="text-caption mb-2">Order Date: </Text>
                    <span className="text-detail ml-1">
                      {dateFormat(editSubData?.data?.created_at)}
                    </span>
                  </Row>
                  <Row>
                    <Text className="text-caption mb-2">
                      Out of stock ref #
                    </Text>
                    <span className="text-detail ml-1">
                      {editSubData?.data?.out_of_stock_ref_no}
                    </span>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col className="vehicle-notify mt-4">
                  <SellerStockTable
                    data={editSubOrderData?.stocks}
                    columns={columnsDeliveryBy}
                    editSubOrderData={editSubOrderData}
                    deliveryStatus={""}
                  />
                </Col>
              </Row>
              {editSubOrderData?.notes && (
                <>
                  <Col className="vehicle-notify mt-4">
                    <Text className="detail-heading">NOTES</Text>
                    {editSubOrderData?.notes}
                  </Col>
                </>
              )}
            </Card>
          </>
        )}
      </Col>
    </>
  );
};

export default SellerOutofStockView;
