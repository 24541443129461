import { React, useState } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Button,
  Badge,
  Form,
  Modal,
  Select,
} from "antd";
import { useNavigate } from "react-router-dom";
import OrderStaticText from "./DashboardStatic/OrderStaticText";
import { ReactComponent as TagIcon } from "../../../assets/seller/icons/tag.svg";
import MobCreditButtonIcon from "../../../assets/seller/icons/Mobcreditlogo1.svg";
import { ReactComponent as UserIcon } from "../../../assets/seller/icons/user.svg";
import CartIcon from "../../../assets/seller/icons/cart.svg";
import MoneyBagIcon from "../../../assets/seller/icons/moneybag.webp";
import MobCreditIcon from "../../../assets/seller/icons/credit.svg";
import { ReactComponent as NewOrdergrey } from "../../../assets/seller/icons/OrderGrey.svg";
import { ReactComponent as ReadyForPickup } from "../../../assets/seller/icons/readyforpickupgrey.svg";
import { ReactComponent as Upcomming } from "../../../assets/seller/icons/upcominggrey.svg";
import { ReactComponent as AttentionRequired } from "../../../assets/seller/icons/attentiongrey.svg";
import { ReactComponent as TotalSalesAmount } from "../../../assets/seller/icons/TotalSalesAmount.svg";
import { ReactComponent as ReturExchange } from "../../../assets/seller/icons/returnsgrey.svg";
import { ReactComponent as HomeTickIcon } from "../../../assets/seller/icons/home_tick.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/seller/icons/checked-1.svg";
import verified from "../../../assets/seller/icons/verified.svg";
import packageicon from "../../../assets/seller/icons/package.svg";
import { onlyDateFormat } from "../../../commonFuntions/CommonUtilFunctions";
import { useSellerRaiseRequestMutation } from "../../../apis/Seller/sellerDashboard";
import MobCreditVideo from "./../SellerMobCreditOnboarding/MobCreditVideo";
import {
  showErrorToast,
  // showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
const { Text } = Typography;
function SellerDashboardInactive({ dashboardData,requestedFlag,setrequestedFlag }) {
  const navigate = useNavigate();
  const currentDate = new Date();
  const bmp_credit_enable =
    localStorage.getItem("bmp_credit_enable") === "true";
  const bmp_plan_expiry = localStorage.getItem("bmp_plan_expiry");
  const options = { weekday: "long", month: "long", day: "numeric" };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);
  const [OpenSellerRequestSubmittedModal, setOpenSellerRequestSubmittedModal] =
    useState(false);
    const [selectedCity, setSelectedCity] = useState('BENGALURU');

    const [isModalVisible, setIsModalVisible] = useState(false);
  const [SellerRaiseRequest] =
  useSellerRaiseRequestMutation();

  const { Title } = Typography;
  const handleViewDetals = (status) => {
    navigate(`/Seller/SellerOrders/`, { state: { status } });
  };
  const [visible, setVisible] = useState(false);
  const count = [
    {
      Status: "New orders",
      title: 0,
      icon: <NewOrdergrey />,
      class: "cursor-pointer card-disabled",
      onClick: () => handleViewDetals("New orders"),
    },
    {
      Status: "Ready for pickup",
      title: 0,
      icon: <ReadyForPickup />,
      class: "cursor-pointer card-disabled",
      onClick: () => handleViewDetals("Ready for pickup"),
    },
    {
      Status: "Upcoming",
      title: 0,
      icon: <Upcomming />,
      class: "cursor-pointer card-disabled",
      onClick: () => handleViewDetals("Upcoming"),
    },
    {
      Status: "Attention required",
      title: "0",
      class: "cursor-pointer card-disabled",
      icon: <AttentionRequired />,
      // onClick: () => console.log("New orders clicked"),
    },
    {
      Status: "Total sales amount",
      class: "cursor-pointer card-padding",
      title: `₹ ${dashboardData?.data?.total_sales_amount.toFixed(2)}`,
      icon: <TotalSalesAmount />,
      // onClick: () => console.log("New orders clicked"),
    },
    {
      Status: "Returns/ Exchange",
      title: dashboardData?.data?.returns_and_exchange,
      class: "cursor-pointer card-disabled",
      icon: <ReturExchange />,
      //  onClick: () => console.log("New orders clicked"),
    },
  ];
  const handleSelectChange = (value) => {
    setSelectedCity(value);
  };
  const handleButtonClick = () => {
    setVisible(true);
  };
  const handleSaveRequestClick = async () => {
    const params = { city: selectedCity };
    const response = await SellerRaiseRequest(params);    
    if (response?.data?.status) {
      setVisible(false);
      setOpenSellerRequestSubmittedModal(true);
      setrequestedFlag(true);
    } else {
      showErrorToast(response?.error?.data?.message);
    }  
   
  };
  const handleModalCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="layout-content">
        <Row className="mb-3">
          <Col span={14} sm={8} md={8} lg={8} xl={8} className="col-info">
            <>
              <span className="today-style">Today</span>
              <span className="font-semibold m-0 formatted-date">
                {" "}
                {formattedDate}
              </span>
            </>
          </Col>
          {/* <Col span={12} sm={8} md={8} lg={8} xl={8}> */}
          <OrderStaticText heading={"How to use?"} description={""} />
          {/* </Col> */}
        </Row>
        <div className="d-block d-sm-block d-lg-none d-xl-none">
          <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
            <Row className="mb-2">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                <Button
                  onClick={() =>
                    (window.location.href = "/seller/createProduct/")
                  }
                  className="btn-outline-default color-style width-100 btn-height-style "
                >
                  <TagIcon style={{ marginRight: "12px" }} />
                  Add new item
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ margintop: "20px" }}
              >
                <Badge
                  // count={"Coming Soon"}

                  className="badge-outlined margin-badge width-100"
                >
                  <button
                    className="btn-theme btn-height-style text-left btn-height-home add-new-style-font width-100 button-inner"
                    onClick={() =>
                      (window.location.href = "/seller/storesales")
                    }
                  >
                    <img
                      src={CartIcon}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "12px",
                      }}
                      alt=""
                    />
                    Add new store sale
                  </button>
                </Badge>
              </Col>
            </Row>
            {!bmp_credit_enable ? (
              <Row className="mt-4">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ margintop: "20px" }}
                >
                  <Card className="mob-credit">
                    <Col className="mob-credit-badge">
                      <img src={MobCreditIcon} className="w-100" alt="" />
                    </Col>
                    <p>
                      Do you want to offer line of credit solution to your
                      customer?
                    </p>
                    <Row>
                      <Col span={12}>
                        <img src={MoneyBagIcon} className="money-bag" alt="" />
                      </Col>
                      <Col span={12} className="text-right mt-4">
                        <Text
                          className="hyperlink-text mobcredit-applynow"
                          onClick={() =>
                            window.open("/seller/mobcreditonboarding", "_blank")
                          }
                        >
                          Apply Now
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            ) : (
              <Row className="mt-4">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ margintop: "20px" }}
                >
                  <Card
                    title={
                      <>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <img src={MobCreditButtonIcon} alt=""></img>
                          <Text className="text-white">Active</Text>
                        </Col>
                      </>
                    }
                    className="customer-section"
                  >
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text>
                        Valid until:{" "}
                        {bmp_plan_expiry && onlyDateFormat(bmp_plan_expiry)}
                      </Text>
                      <Text  className="how-touse-link cursor-pointer"
    onClick={(e) => {
      e.preventDefault();
      setIsModalVisible(true);
    }}>
  
    How to use?
  
</Text>
                    </Col>
                    <Row>
                      <Col span={24} className="mt-2">
                        <Button
                          icon={<UserIcon />}
                          className="mobcredit-newclient"
                          onClick={() =>
                            window.open("/seller/customercredit", "_blank")
                          }
                        >
                          Onboard a new client
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
        </div>
        <Row gutter={[24, 0]} className="mt-4">
          <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
            {/* <Card bordered={false} className="criclebox h-full"> */}
            <Row className="rowgap-vbox" gutter={[24, 0]}>
              {count.map((c, index) => (
                <Col
                  key={index}
                  xs={12}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className={`mb-24 ${c.class}`}
                >
                  <Card
                    bordered={true}
                    style={{ padding: "1px" }}
                    className="info-box"
                    onClick={c.onClick}
                  >
                    <div className="number-inactive">
                      {/* <Row gutter={[24, 0]}> */}
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={10}
                        align="left"
                        className="info-box-content text-left d-none d-md-block d-lg-block d-xl-block"
                      >
                        <div className="icon-box">{c.icon}</div>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={14}
                        align="right"
                        className="padding-0"
                      >
                        <span className="info-box-text ">{c.Status}</span>
                        <Title
                          className="info-box-number new-addnumber"
                          level={3}
                        >
                          {c.title}
                        </Title>
                      </Col>
                      {/* </Row> */}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>

            <Row className="top-padding">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl
                className="display-flex-end"
              >
                <Typography.Title
                  level={5}
                  className="d-flex-center fixed-font"
                >
                  Start selling online with MOB in just 2 steps
                </Typography.Title>
              {!requestedFlag ?(
                <Button
                  type="primary"
                  className="float-right btn-request"
                  onClick={handleButtonClick}
                >
                  REQUEST
                </Button>
              ):(
                <Button
                  type="default"
                  className="float-right btn-requested"  
                  icon={<CheckedIcon/>}               
                >
                  REQUESTED
                </Button>
                )}
              </Col>
            </Row>

            <Row className="mt-4 mb-2">
              <div className="layout-content">
                <Row className="mb-3 layout-content">
                  <Col span={24} md={24} className="col-info">
                    <Card
                      bordered={true}
                      style={{ padding: "1px" }}
                      className="info-box info-box-top"
                    >
                      <div className="number">
                        <Row gutter={[24, 0]}>
                          <Col xs={4} align="info-box-content text-center">
                            <img alt="" src={verified} />
                          </Col>
                          <Col xs={20} align="left">
                            <Typography.Title
                              level={6}
                              className="info-box-text"
                            >
                              Get verified with mob
                            </Typography.Title>
                            <Typography.Text className="info-box-text-inner">
                              Register with additional info like GST no, Aadhar
                              card, Bank Details etc.
                            </Typography.Text>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                    <Card
                      bordered={true}
                      style={{ padding: "1px" }}
                      className="info-box info-box-bottom"
                    >
                      <div className="number">
                        <Row gutter={[24, 0]}>
                          <Col xs={4} align="info-box-content text-center">
                            <img alt="" src={packageicon} />
                          </Col>
                          <Col xs={20} align="left">
                            <Typography.Title
                              level={6}
                              className="info-box-text"
                            >
                              Build your catalogue and start selling
                            </Typography.Title>
                            <Typography.Text className="info-box-text-inner">
                              Start getting orders from your area. Get paid to
                              your account on completeion of sales.
                            </Typography.Text>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row gutter={[24, 0]} className="top-padding">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                    <Typography.Title level={5}>
                      Online selling with MOB available in
                    </Typography.Title>
                    <Row gutter={[16, 16]} className="mt-4 h-scroll">
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                        style={{ gap: "16px" }}
                        className="mb-24 mt-4 d-flex"
                      >
                        <Button
                          type="button"
                          className="btn-large btn-dashboard margin-badge"
                        >
                          BENGALURU
                        </Button>
                        <Badge
                          count={"Coming Soon"}
                          className="badge-outlined margin-badge"
                        >
                          <Button
                            type="primary"
                            className="btn-large btn-dashboard card-disabled"
                          >
                            CHENNAI
                          </Button>
                        </Badge>
                        <Badge
                          count={"Coming Soon"}
                          className="badge-outlined margin-badge"
                        >
                          <Button
                            type="primary"
                            className="btn-dashboard btn-large card-disabled"
                          >
                            MUMBAI
                          </Button>
                        </Badge>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Row>
            {/* </Card> */}
          </Col>

          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            className="mb-24 d-none d-md-block d-lg-block d-xl-block"
          >
            <Row className="mb-4">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
                <Button
                  onClick={() =>
                    (window.location.href = "/seller/createProduct/")
                  }
                  className="btn-outline-default color-style width-100 btn-height-style "
                >
                  <TagIcon style={{ marginRight: "12px" }} />
                  Add new item
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ margintop: "20px" }}
              >
                <button
                  className="btn-theme btn-height-style text-left btn-height-home add-new-style-font width-100 button-inner"
                  onClick={() => (window.location.href = "/seller/storesales")}
                >
                  <img
                    src={CartIcon}
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "12px",
                    }}
                    alt=""
                  />
                  Add new store sale
                </button>
              </Col>
            </Row>
            {!bmp_credit_enable ? (
              <Row className="mt-4">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ margintop: "20px" }}
                >
                  <Card className="mob-credit">
                    <Col className="mob-credit-badge">
                      <img src={MobCreditIcon} className="w-100" alt="" />
                    </Col>
                    <p>
                      Do you want to offer line of credit solution to your
                      customer?
                    </p>
                    <Row>
                      <Col span={12}>
                        <img src={MoneyBagIcon} className="money-bag" alt="" />
                      </Col>
                      <Col span={12} className="text-right mt-4">
                        <Text
                          className="hyperlink-text mobcredit-applynow"
                          onClick={() =>
                            window.open("/seller/mobcreditonboarding", "_blank")
                          }
                        >
                          Apply Now
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            ) : (
              <Row className="mt-4">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ margintop: "20px" }}
                >
                  <Card
                    title={
                      <>
                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <img src={MobCreditButtonIcon} alt=""></img>
                          <Text className="text-white">Active</Text>
                        </Col>
                      </>
                    }
                    className="customer-section"
                  >
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text>
                        Valid until:{" "}
                        {bmp_plan_expiry && onlyDateFormat(bmp_plan_expiry)}
                      </Text>
                      <Text  className="how-touse-link cursor-pointer"
    onClick={(e) => {
      e.preventDefault();
      setIsModalVisible(true);
    }}>
  
    How to use?
  
</Text>
                    </Col>
                    <Row>
                      <Col span={24} className="mt-2">
                        <Button
                          icon={<UserIcon />}
                          className="mobcredit-newclient"
                          onClick={() =>
                            window.open("/seller/customercredit", "_blank")
                          }
                        >
                          Onboard a new client
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>

      <Modal
        title="Request for MOB seller"
        visible={visible}
        onCancel={handleModalCancel}
        footer={[
          <div className="modal-footer d-block text-center footer-bottom-border-line">
            <Button
              key="cancel"
              className="btn-cancel order-cancel-btn-style btnI"
              onClick={handleModalCancel}
            >
              CANCEL
            </Button>

            <Button
              key="gotIt"
              type="primary"
              className="btn-theme order-got-btn-style btnI"
              onClick={handleSaveRequestClick}
            >
              REQUEST
            </Button>
          </div>,
        ]}
      >
        <div style={{ padding: "12px" }}>
          <Form>
            <Row className="accept-order-padding pt-0 mt-4">
              <Col span={24}>
                <Text className="request-head">Select your city</Text> <br />
                <Text className="request-head-sm">
                  MOB is currently live in these cities
                </Text>
              </Col>

              <Col span={24} className="mt-4">
                <Form.Item
                  name="select"
                  rules={[
                    { required: true, message: "Please select an option" },
                  ]}
                >
                  <Select   value={selectedCity} onChange={handleSelectChange} placeholder="Select city" style={{ width: "100%" }}>
                    <Select.Option  value="BENGALURU">BENGALURU</Select.Option>
                    {/* <Select.Option value="CHENNAI">CHENNAI</Select.Option>
                  <Select.Option value="MUMBAI">MUMBAI</Select.Option> */}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Col className="text-alert-style">
            <Text>
              Not in your city yet? no problem! we will update you in the
              dashboard when we add new locations.
            </Text>
          </Col>
        </div>
      </Modal>
      <Modal
        className="order-payment-style"
        title={<Text></Text>}
        visible={OpenSellerRequestSubmittedModal}
        onCancel={() => setOpenSellerRequestSubmittedModal(false)}
        footer={[]}
      >
        <div className="padding-16px">
          <Row className="submissionModalParent">
            <Row className="submissionModalImageContentParent ">
              <HomeTickIcon />

              <Row className="submissionModalContentParent">
                <Text className="submissionModalTitle">
                  Your request has been submitted!
                </Text>
                <Text className="submissionModalDesc">
                  Our support member will get in touch with you shortly.
                  <br /> For further assistance please call/ whatsapp at{" "}
                  <a
                    href="https://wa.me/918970415365"
                    rel="noreferrer"
                    target="_blank"
                  >
                    +91 8970415365
                  </a>
                  .<br />
                  You can also reach us through mail at{" "}
                  <a href="mailto:ask@madoverbuildings.com">
                    ask@madoverbuildings.com
                  </a>
                </Text>
              </Row>

              <Button
                className="editAddressFooterButton"
                onClick={() => setOpenSellerRequestSubmittedModal(false)}
              >
                OK
              </Button>
            </Row>
          </Row>
        </div>
      </Modal>
      <Modal
    title="How does mobCREDIT work?"
    visible={isModalVisible}
    width={"65%"}
    height={"65%"}
    onCancel={() => setIsModalVisible(false)}
    footer={null}
  >
    <div className="padding-16px">
        <MobCreditVideo/>
        </div>
  </Modal>
    </>
  );
}

export default SellerDashboardInactive;
