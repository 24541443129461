import {
  Affix,
  Avatar,
  Badge,
  Button,
  Checkbox,
  Col,
  Row,
  // Switch,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./sellerMobLib.css";
import BannerImage from "../../../assets/icons/Mask Group 183@3x.png";
import SearchDropdown from "../../../components/DropDown/SearchDropdown";
import { UpArrow } from "../../../components/Icons/UpArrow";
import { DownArrow } from "../../../components/Icons/DownArrow";
import CustomTableWithPagination from "../../../components/CustomTableWithPagination/CustomTableWithPagination";
import {
  useCreateStoreProductMutation,
  useLazyGetExistingSellerTableQuery,
  useLazyGetSearchSellerProductQuery,
  useUpdateStoreSalesProductMutation,
  useUpdateProductFlagTaxMutation
  
} from "../../../apis/sellerCatalogue";
import { ReactComponent as DefaultIcon } from "../../../assets/icons/default.svg";
import CustomTableInput from "../../../components/Input/CustomTableInput/CustomTableInput";
import { ReactComponent as FlagIcon } from "../../../assets/icons/flagicon.svg";
import { ReactComponent as RedFlagIcon } from "../../../assets/icons/redFlagIcon.svg";
import { ReactComponent as GreenTickIcon } from "../../../assets/icons/greenTickTable.svg";
import {
  getAddCatalogueLength,
  getSellerMobLibraryTableData,
  // getSellerTableData,
  getSellerTableRowData,
  getViewStatus,
} from "../../../redux/slices/sellerCatalogue/action";
import { CloseOutlined } from "@ant-design/icons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../NotificationToast/NotificationToast";
import TableSkeleton from "../../../components/skeleton/TableSkeleton";
import { useDispatch, useSelector } from "react-redux";
import {
  addCatalogueLengthSelector,
  // sellerCatalogueTableSelector,
  sellerMobLibraryTableSelector,
} from "../../../redux/slices/sellerCatalogue/selector";
import FlagItemModal from "../../../components/Modal/FlagItemModal/FlagItemModal";
import ItemFlagModal from "../../../components/Modal/FlagItemModal/ItemFlagModal";
import CustomIconInput from "../../../components/Input/CustomIconInput/CustomIconInput";
// import SearchInput from "../../../components/Input/SearchInputs/SearchInput";
import ItemDetailsModal from "../../../components/Modal/ItemDetailsModal/ItemDetailsModal";
import AddedToCatalogueModal from "../../../components/Modal/AddToCatalogueModals/AddedToCatalogueModal";
import LibraryWorkingModal from "../../../components/Modal/AddToCatalogueModals/LibraryWorkingModal";
import CustomAutoComplete from "../../../components/Input/CustomAutoComplete/CustomAutoComplete";
import { baseUrl } from "../../../commonUtils/commonUtils";
import { useParams } from "react-router-dom";
const { Text } = Typography;
const handleTableData = (params) => {

  let initialArrVal = [];
  let parentSubOrder = [];

  params?.results?.products?.map((item, index) => {
    let obj = {
      key: item.id,
      productName: item.product_name,
      image: item.images[0] ? `${baseUrl}${item.images[0]?.image}` : "",
      mob_sku: item.mob_sku,
      brand_name: item.brand_name,
      mrp: item.mrp,
      uom: item.mob_sku,
      category: item.category,
      subCategory: item.sub_category_id,
      expandRow: false,
      subproducts: item.subproducts,
      selling_price: item.your_selling_price,
      // rfq_price: item.rfq_price,
      tax: item?.product_tax_code?.tax,
      hsn_code: item?.hsn_code,
      sub_category_lvl_1: item?.sub_category,
      sub_category_lvl_2: item?.sub_sub_category,
      is_available: item?.is_available,
      status: item?.is_active,
      vendor_data: item?.vendor_data,
      vendor_selling_price: item?.vendor_data?.vendor_selling_price,
      maximum_retail_price: item?.vendor_data?.maximum_retail_price,
      warranty_term: item?.vendor_data?.warranty_term,
      comission_rfq: item?.vendor_data?.comission_rfq,
      dealer_price: item?.vendor_data?.dealer_price,
      rfq_price: item?.vendor_data?.rfq_price,
      stock: item?.vendor_data?.stock,
      vendor_product_id: item?.vendor_data?.vendor_product_id,
      onEdit: false,
      redFlag: false,
      product_description: item?.product_description,
      images: item?.images,
      checkbox: false,
      subChild: item?.subproducts,
      product_bullet_points : item?.product_bullet_points
    };
    initialArrVal.push(obj);
    // parentSubOrder.push(obj);
    if (item?.subproducts?.length) {
      item?.subproducts?.map((element, index) => {       
        parentSubOrder.push({
          parentKey: item.id,
          key: element.id,
          // icon: <Col className="table-icon">...</Col>,
          productName: element.product_name,
          image: element.images[0]
            ? `${baseUrl}${element.images[0]?.image}`
            : "",
          status: element?.is_active,
          mob_sku: element.mob_sku,
          brand_name: element.brand_name,
          mrp: item.mrp,
          selling_price: element.your_selling_price,
          // rfq_price: element.rfq_price,
          tax: element?.product_tax_code?.tax,
          hsn_code: element?.hsn_code,
          uom: element.mob_sku,
          category: element.category,
          sub_category_lvl_1: element?.sub_category,
          sub_category_lvl_2: element?.sub_sub_category,
          subCategory: element.sub_category_id,
          is_available: element?.is_available,
          vendor_data: element?.vendor_data,
          onEdit: false,
          redFlag: false,
          vendor_selling_price: element?.vendor_data?.vendor_selling_price,
          maximum_retail_price: element?.vendor_data?.maximum_retail_price,
          warranty_term: element?.vendor_data?.warranty_term,
          comission_rfq: element?.vendor_data?.comission_rfq,
          dealer_price: element?.vendor_data?.dealer_price,
          rfq_price: element?.vendor_data?.rfq_price,
          stock: element?.vendor_data?.stock,
          vendor_product_id: element?.vendor_data?.vendor_product_id,
          product_description: item?.product_description,
          images: element?.images,
          checkbox: false,
        });
        return null;
      });
    }
    return null;
  });

  return { initialArrVal, parentSubOrder };
};
const SellerMobLib = () => {
  const navigate = useNavigate();
  const { seller_id: urlSellerId } = useParams();
  const bmp_Id=localStorage.getItem("bmp_id");
  const seller_id = urlSellerId || localStorage.getItem("bmp_id");
  const [currentPage, setCurrentPage] = useState(1);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [subProducts, setSubProducts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reason, setReason] = useState('Tax is inaccurate');
  const [comments, setComments] = useState('');
  // const [subCategoryLength, setSubCategoryLength] = useState(0);

  const [isFixedBarVisible, setIsFixedBarVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [titleCheckboxChecked, setTitleCheckboxChecked] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandsSearch, setBrandsSearch] = useState([]);
  const [updateProductApi] = useUpdateStoreSalesProductMutation();
  const [createProductApi] = useCreateStoreProductMutation();
  const dispatch = useDispatch();
  const tableSelector = useSelector(sellerMobLibraryTableSelector);
  const addCatalogueLengthSelectorData = useSelector(
    addCatalogueLengthSelector
  );
  const [sellerTableApi, { data: tableData, isFetching: isLoading }] =
    useLazyGetExistingSellerTableQuery();
  const [getAutoCompleteSearchApi, { data: productDataList,isFetching: IsAutocompleteFetching }] =
    useLazyGetSearchSellerProductQuery();
    const [updateProductFlagTaxApi] = useUpdateProductFlagTaxMutation();
  const [openModal, setOpenModal] = useState(false);
  const [openModalCarousel, setOpenModalCarousel] = useState(false);
  const [openModalFlag, setOpenModalFlag] = useState(false);
  const [openModalCatalogue, setOpenModalCatalogue] = useState(false);
  const [openWorkingModal, setOpenWorkingModal] = useState(false);
  const handleOpenModal = (record) => {
    if (!record?.redFlag) {
      dispatch(getSellerTableRowData(record));
      setOpenModal(true);
    }
  };
  const handleCarouselModal = (record) => {
    console.log(record, "👮🏻‍♂️🎅🏻")
    dispatch(getSellerTableRowData(record));
    setOpenModalCarousel(true);
  };

  const handleOk = () => {
    setOpenModal(false);
  };
  const handleOkCatalogue = () => {
    dispatch(getAddCatalogueLength(0));
    window.location.reload();
    setOpenModalCatalogue(false);
   // sellerTableApi({ id: seller_id }); 
  };
  const handleBackCatalogue = () => {
    if (bmp_Id) {
      navigate(`/seller/catalogue`);   
    } else {
      navigate(`/seller/catalogue/${seller_id}`);
    }
  };
  const handleWorkingModal = () => {
    setOpenWorkingModal(false);
  };
  const handleHowWorks = () => {
    setOpenWorkingModal(true);
  };
  const handleOkCarousel = () => {
    setOpenModalCarousel(false);
  };
  const handleGOBack = () => {
    setOpenModalFlag(false);
  };
  // const handleFlagItem = (record) => {
  //   let currentTableData = [...tableDataSource];
  //   currentTableData = currentTableData.map((table, index) => {
  //     if (table.key === record.key) {
  //       return { ...table, redFlag: true };
  //     } else {
  //       return table;
  //     }
  //   });
  //   setTableDataSource(currentTableData);
  //   setOpenModal(false);
  //   setOpenModalFlag(true);
  // };
  const handleFlagItem = async (record) => {
    // console.log("record",record?.vendor_product_id);
    let currentTableData = [...tableDataSource];
    currentTableData = currentTableData.map((table, index) => {
      if (table.key === record.key) {
        return { ...table, redFlag: true };
      } else {
        return table;
      }
    });
    const params = {
      vendor_product_id: record?.vendor_product_id,
      comment: reason,
      reason: comments,
    };
    // console.log("params",params);
    const response = await updateProductFlagTaxApi(params);
    if (response?.data?.status) {
      setTableDataSource(currentTableData);
      setOpenModal(false);
      setOpenModalFlag(true);
      setReason('Tax is inaccurate');
      setComments('');
    } else {
      showErrorToast(response?.error?.data?.message);
    }
   
  };
  // const startItemIndex = (currentPage - 1) * 50;
  // const endItemIndex = startItemIndex + 50;
  // const currentData = tableDataSource.slice(startItemIndex, endItemIndex);
  const handleChangeRowData = (keyName, inputValue, record) => {
    let currentTableData = [...tableDataSource];
    currentTableData = currentTableData.map((table, index) => {
      if (table.key === record.key) {
        return { ...table, [keyName]: inputValue };
      } else {
        return table;
      }
    });

    setTableDataSource(currentTableData);
  };
  const onSelectTableChange = async (val) => {
    let params = val;
    const response = await sellerTableApi({ params, id: seller_id });
    if (response?.status) {
      dispatch(getSellerMobLibraryTableData(response?.data?.data));
    }
  };
  const handleEdit = (record) => {
    let currentTableData = [...tableDataSource];
    currentTableData = currentTableData.map((table, index) => {
      if (table.key === record.key) {
        return { ...table, onEdit: true };
      } else {
        return table;
      }
    });
    setTableDataSource(currentTableData);
  };
  const handleGetData = async () => {
    const response = await sellerTableApi({ id: seller_id });
    if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    }
  };
  useEffect(() => {
    // sellerTableApi();
    handleGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (tableData?.status === true) {
      dispatch(getSellerMobLibraryTableData(tableData?.data));
      dispatch(
        getViewStatus({
          bmp_id: tableData?.data?.results?.vendor_info?.bmp_id,
          vendor_name: tableData?.data?.results?.vendor_info?.vendor_name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);
  useEffect(() => {
    if (tableSelector) {
      const data = tableSelector ? handleTableData(tableSelector) : "";
      setTableDataSource(data?.initialArrVal || []);
      setSubProducts(data?.parentSubOrder || []);
    }
  }, [tableSelector]);
  const handlePageChange = async (currPage) => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item.label));
    categories.map((item) => categoriesArray.push(item.label));
    const params = {
      page: currPage,
      ...(searchText && { search: searchText }),
      ...(brandArray.length && { brands: brandArray }),
      ...(brandsSearch.length && { brands: brandsSearch }),
      ...(categoriesArray.length && { category: categoriesArray }),
    };
    setCurrentPage(currPage);
    await sellerTableApi({ params, id: seller_id });
    setIsFixedBarVisible(false);
    setTitleCheckboxChecked(false);
    setSelectedRows([]);
  };
  // const StatusToggle = (props) => {
  //   const { record, params, handleChangeRowData, name } = props;
  //   const [checked, setChecked] = useState(false);
  //   const onChange = (checked) => {
  //     setChecked(checked);
  //     handleChangeRowData(name, checked, record);
  //   };
  //   useEffect(() => {
  //     setChecked(record.status);
  //   }, []);
  //   return (
  //     <>
  //       <Switch checked={checked} onChange={onChange} />
  //     </>
  //   );
  // };
  const handleSaveProduct = async (record) => {
    const params = {
      vendor_product_id: record?.vendor_product_id,
      store_selling_price:record?.store_selling_price,
      // vendor_selling_price: record?.vendor_selling_price,
      // rfq_price: record?.rfq_price,
      stock: record?.stock,
      // maximum_retail_price: record?.maximum_retail_price,
      // warranty_term: record?.warranty_term,
      // comission_rfq: record?.comission_rfq,
      // dealer_price: record?.dealer_price,
      is_active: record?.status,
    };

    const response = await updateProductApi(params);
    if (response?.error) {
      showErrorToast(response?.error?.data?.message);
    }
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      let currentTableData = [...tableDataSource];
      currentTableData = currentTableData.map((table, index) => {
        if (table.key === record.key) {
          return { ...table, onEdit: false };
        } else {
          return table;
        }
      });
      setTableDataSource(currentTableData);
    }
  };
  const handleCreateProduct = async () => {
    let params = [];
    selectedRows?.map((item) => {
      const rows = document.querySelectorAll(`tr[data-row-key="${item.key}"]`);
      let vendorSellingPrice = 0;
      let stock = 0;
      rows.forEach((row) => {
        const rowElement = row;
        const vendorSellingPriceInput = rowElement.querySelector(
          'input[name="store_selling_price"]'
        );
        const stockinner = rowElement.querySelector('input[name="stock"]');
        if (vendorSellingPriceInput) {
          vendorSellingPrice = parseFloat(vendorSellingPriceInput.value || 0);
        }
        if (stockinner) {
          stock = parseInt(stockinner.value || 0);
        }
      });

      const obj = {
        vendor: tableSelector?.results?.vendor_info?.vendor_id,
        product: item?.key,
        store_selling_price: vendorSellingPrice || 0,
        stock: stock || 0,
        tax: item?.tax || 0,
      };

      params.push(obj);
      return null;
    });

    const response = await createProductApi(params);
    if (response?.error) {
      let data = response?.error?.data?.data;
      // let errorList = [];
      let errorValue;
      for (const field in data) {
        if (data.hasOwnProperty(field)) {
          const errorMessages = data[field];
          // const errorKey = Object.keys(errorMessages);
          errorValue = Object.values(errorMessages);

          // if (Array.isArray(errorMessages)) {
          //   errorMessages.forEach((errorMessage) => {
          //     errorList.push(`${field} : ${errorMessage}`);
          //   });
          // } else {
          //   // Handle the case where errorMessages is not an array
          //   errorList.push(`${field} : ${errorMessages}`);
          // }
        }
      }
      showErrorToast(errorValue[0][0]);
    }
    if (response?.data?.status) {
      showSuccessToast(response?.data?.message);
      setOpenModalCatalogue(true);     
      setIsFixedBarVisible(false);
      setTitleCheckboxChecked(false);
      setSelectedRows([]);
      dispatch(getAddCatalogueLength(selectedRows?.length || 0));
      // sellerTableApi({ id: seller_id }); 
     
    }
  };

  const handleTitleCheckboxChange = (e) => {
    setTitleCheckboxChecked(e.target.checked);

    if (e.target.checked) {
      let finalProductsArray = [];
      let selectedArray = [];
      // eslint-disable-next-line
      let newTableData = tableDataSource.map((item) => {
        // if (!item.is_available) {
        finalProductsArray.push({
          ...item,
          checkbox: !item.is_available ? e.target.checked : false,
          ...(!item?.parentKey && { expandRow: true }),
        });
        if (!item?.is_available) {
          selectedArray.push(item);
        }
        if (!item?.expandRow) {
          item?.subChild?.map((child, index) => {
            finalProductsArray.push({
              parentKey: item.key,
              key: child.id,
              id: child.id,
              // icon: <Col className="table-icon">...</Col>,
              productName: child.product_name,
              image: child.images[0]
                ? `${baseUrl}${child.images[0]?.image}`
                : "",
              status: child?.is_active,
              mob_sku: child.mob_sku,
              brand_name: child.brand_name,
              mrp: child.mrp,
              selling_price: child.your_selling_price,
              // rfq_price: element.rfq_price,
              tax: child?.product_tax_code?.tax,
              hsn_code: child?.hsn_code,
              uom: child.mob_sku,
              category: child.category,
              sub_category_lvl_1: child?.sub_category,
              sub_category_lvl_2: child?.sub_sub_category,
              subCategory: child.sub_category_id,
              is_available: child?.is_available,
              vendor_data: child?.vendor_data,
              onEdit: false,
              redFlag: false,
              vendor_selling_price: child?.vendor_data?.vendor_selling_price,
              maximum_retail_price: child?.vendor_data?.maximum_retail_price,
              warranty_term: child?.vendor_data?.warranty_term,
              comission_rfq: child?.vendor_data?.comission_rfq,
              dealer_price: child?.vendor_data?.dealer_price,
              rfq_price: child?.vendor_data?.rfq_price,
              stock: child?.vendor_data?.stock,
              vendor_product_id: child?.vendor_data?.vendor_product_id,
              product_description: child?.product_description,
              images: child?.images,
              checkbox: !child?.is_available ? e.target.checked : false,
            });
            if (!child?.is_available) {
              selectedArray.push(child);
            }
            return null;
          });
        }
        // return {
        //   ...item,
        //   checkbox: e.target.checked,
        //   expandRow: e.target.checked,
        // };
        // }
        // finalProductsArray.push(item);
        return item;
      });

      // let allRows = tableDataSource.filter((item) => {
      //   if (!item.is_available) {
      //     // item.checkbox = true;
      //     return { ...item, checkbox: true };
      //   }
      //   return null;
      // });
      // let subRows = subProducts.filter((item) => {
      //   if (!item.is_available) {
      //     return { ...item, checkbox: true };
      //   }
      //   return null;
      // });

      setTableDataSource([...finalProductsArray]);
      setSelectedRows([...selectedArray]); // update this
      setIsFixedBarVisible(selectedArray.length > 0);
    } else {
      const finalProductsArray = tableDataSource?.map((item) => {
        return { ...item, checkbox: false };
      });
      setTableDataSource([...finalProductsArray]);
      setSelectedRows([]);
      setIsFixedBarVisible(false);
    }
  };
  const handleSelectedItem = (record, e) => {
    let currentTableData = [...tableDataSource];
    currentTableData = currentTableData.map((table) =>
      table.key === record.key ? { ...table, checkbox: true } : table
    );
    let newSelectedRows = currentTableData.filter((item) => item?.checkbox);
    setOpenModalCarousel(false);
    setSelectedRows(newSelectedRows);
    setTableDataSource(currentTableData);
    setIsFixedBarVisible(newSelectedRows.length > 0);
  };
  const handleCheckboxChange = (e, record, tableIndex) => {
    let currentTableData = [...tableDataSource];
    let parentIndex = "";
    let subOrderList = [];
    // subOrderList = subProducts?.filter((item) => {
    //   if (item?.parentKey === record?.key) {
    //     item.checkbox = true;
    //     return item;
    //   }
    //   return null;
    // });
    currentTableData = currentTableData?.map((table, index) => {
      if (table?.key === record?.key) {
        parentIndex = index;
        if (!table?.expandRow) {
          table?.subChild?.map((child, index) => {
            subOrderList.push({
              parentKey: table.key,
              key: child.id,
              id: child.id,
              // icon: <Col className="table-icon">...</Col>,
              productName: child.product_name,
              image: child.images[0]
                ? `${baseUrl}${child.images[0]?.image}`
                : "",
              status: child?.is_active,
              mob_sku: child.mob_sku,
              brand_name: child.brand_name,
              mrp: child.mrp,
              selling_price: child.your_selling_price,
              // rfq_price: element.rfq_price,
              tax: child?.product_tax_code?.tax,
              hsn_code: child?.hsn_code,
              uom: child.mob_sku,
              category: child.category,
              sub_category_lvl_1: child?.sub_category_id,
              sub_category_lvl_2: child?.sub_category_id_2,
              subCategory: child.sub_category_id,
              is_available: child?.is_available,
              vendor_data: child?.vendor_data,
              onEdit: false,
              redFlag: false,
              vendor_selling_price: child?.vendor_data?.vendor_selling_price,
              maximum_retail_price: child?.vendor_data?.maximum_retail_price,
              warranty_term: child?.vendor_data?.warranty_term,
              comission_rfq: child?.vendor_data?.comission_rfq,
              dealer_price: child?.vendor_data?.dealer_price,
              rfq_price: child?.vendor_data?.rfq_price,
              stock: child?.vendor_data?.stock,
              vendor_product_id: child?.vendor_data?.vendor_product_id,
              product_description: child?.product_description,
              images: child?.images,
              checkbox: !child?.is_available ? true : false,
            });
            return null;
          });
        } else {
          // subOrderList = [];
        }
        return {
          ...table,
          expandRow: true,
          checkbox: !table?.is_available
            ? e.target.checked
              ? true
              : false
            : false,
        };
      } else {
        if (table?.parentKey === record?.key) {
          return {
            ...table,
            checkbox: !table?.is_available
              ? e.target.checked
                ? true
                : false
              : false,
          };
        } else {
          return table;
        }
      }
    });

    if (e.target.checked) {
      currentTableData.splice(parentIndex + 1, 0, ...subOrderList);
      setTitleCheckboxChecked(false);
    } else {
      setTitleCheckboxChecked(false);
    }

    // const newSelectedRows = e.target.checked
    //   ? [
    //       ...selectedRows,
    //       { ...record, tableIndex },
    //       ...subOrderList?.filter((item) => {
    //         if (item?.parentKey === record?.key && !item?.is_available) {
    //           item.checkbox = true;
    //           return item;
    //         }
    //         return null;
    //       }),
    //     ]
    //   : selectedRows.filter((item) => record?.key !== item?.key);
    let newSelectedRows = currentTableData.filter((item) => item?.checkbox);
    setSelectedRows(newSelectedRows);
    setTableDataSource(currentTableData);
    setIsFixedBarVisible(newSelectedRows.length > 0);
  };

  const _onCancel = () => {
    let allRows = tableDataSource.map((item) => {
      item.checkbox = false;
      return item;
    });
    setTableDataSource(allRows);
    setIsFixedBarVisible(false);
    setTitleCheckboxChecked(false);
    setSelectedRows([]);
  };
  const handleRowClick = (record, open) => {
    // let currentTableData = tableDataSource.filter((item) => !item.parentKey);

    let currentTableData = [...tableDataSource];   
    let parentIndex = "";
    let subOrderList = subProducts.filter(
      (item) => item.parentKey === record.key
    );

    if (record?.checkbox) {
      subOrderList = subOrderList.map((item) => {
        const table = selectedRows.find((table) => table.key === item.key);
        return { ...item, checkbox: table?.checkbox };
      });
    }
      
      
    currentTableData = currentTableData.map((table, index) => {
     
      if (table.key === record.key) {
        
        parentIndex = index;
       
        return { ...table, expandRow: open === "up" ? true : false };
      } else {
        // return { ...table, expandRow: false };
        return { ...table };
      }
    });

    if (open === "up") {
      currentTableData.splice(parentIndex + 1, 0, ...subOrderList);
      // setSubCategoryLength(subOrderList.length || 0);
    } else {
      currentTableData = currentTableData.filter(
        (item) => !(item.parentKey === record?.key)
      );
   
      // currentTableData.splice(parentIndex + 1, subOrderList.length);
      // setSubCategoryLength(0);
    }
    setTableDataSource(currentTableData);
  };
  const handleFilterLibrary = () => {
    let brandArray = [];
    let categoriesArray = [];
   
    brands.map((item) => brandArray.push(item.label));
    categories.map((item) => categoriesArray.push(item.label));
    sellerTableApi({
      id: seller_id,
      params: {
        ...(brandArray.length && { brands: brandArray }),
        ...(categoriesArray.length && { category: categoriesArray }),
        page: 1,
      },
    });
    setCurrentPage(1);
  };
  const handleFilterSelect = (label) => {
    let brandArray = [];
    let categoriesArray = [];
    brands.map((item) => brandArray.push(item?.label));
    categories.map((item) => categoriesArray.push(item?.label));
    if (label === "Categories") {
      sellerTableApi({
        id: seller_id,
        params: {
          ...(brandArray.length && { brands: brandArray }),
          page: 1,
        },
      });
    } else if (label === "Brands") {
      sellerTableApi({
        id: seller_id,
        params: {
          ...(categoriesArray.length && { category: categoriesArray }),
          page: 1,
        },
      });
    }
    setCurrentPage(1);
  };
  const handleClearFilter = () => {
    setSearchText("")
    setCategories([]);
    setBrands([]);
    sellerTableApi({ id: seller_id });
    setCurrentPage(1);
  };

  // const items = [
  //   // {
  //   //   label: "Edit",
  //   //   key: "1",
  //   //   //   onClick: onEdit,
  //   // },
  //   {
  //     label: "Activate",
  //     key: "2",
  //     // onClick: onActivate,
  //   },
  //   {
  //     label: "Delete",
  //     key: "3",
  //     danger: true,
  //     //   onClick: onDelete,
  //   },
  // ];

  const columns = [
    {
      title: (
        <Checkbox
          style={{ paddingLeft: "12px" }}
          checked={titleCheckboxChecked}
          onChange={handleTitleCheckboxChange}
        />
      ),
      key: "icon",
      dataIndex: "icon",
      fixed: typeof window !== 'undefined' && window.innerWidth > 768 ? "left" : "",
      width: "50px",
      render: (params, record, tableIndex) => {
        return (
          <>
            {!record.is_available && (
              <Checkbox
                onChange={(e) => handleCheckboxChange(e, record, tableIndex)}
                // checked={
                //   titleCheckboxChecked ||
                //   selectedRows.some(
                //     (selectedRow) =>
                //       tableIndex === selectedRow.tableIndex &&
                //       selectedRow.key === record.key
                //   )
                // }
                checked={record?.checkbox}
              />
            )}
          </>
        );
      },
      // height: "60px",
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      // fixed: "left",
      fixed: typeof window !== 'undefined' && window.innerWidth > 768 ? "left" : "",
      width: "250px",
      render: (params, record) => {
        return (
          <>
            <Col
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                paddingLeft: record?.parentKey ? "60px" : "0px",
                opacity: record?.is_available ? 0.5 : 1,
              }}
            >
              {!(record?.parentKey || !record?.subproducts?.length) && (
                <Col>
                  {record?.expandRow ? (
                    <UpArrow
                      className={"cursor"}
                      onClick={() => handleRowClick(record, "down")}
                    />
                  ) : (
                    <DownArrow
                      className={"cursor"}
                      onClick={() => handleRowClick(record, "up")}
                    />
                  )}
                </Col>
              )}
              <Col onClick={() => handleCarouselModal(record)}>
                <Badge count={record?.is_available ? <GreenTickIcon /> : <></>}>
                  <Avatar
                    style={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f1f1f2",
                      cursor: "pointer",
                    }}
                    shape="square"
                    src={record?.image}
                    alt="Avatar"
                    icon={<DefaultIcon width={20} height={20} />}
                  />
                </Badge>
              </Col>
              <Col
                style={{ fontWeight: 500, cursor: "pointer" }}
                onClick={() => handleCarouselModal(record)}
              >
                {record?.productName}
              </Col>
            </Col>
          </>
        );
      },
    },

    {
      title: "SKU",
      dataIndex: "mob_sku",
      key: "mob_sku",
      width: "120px",
      render: (param, record) => {
        return (
          <>
            <Col style={{ opacity: record?.is_available ? 0.5 : 1 }}>
              {record?.mob_sku}
            </Col>
          </>
        );
      },
    },
    {
      title: "MRP",
      dataIndex: "mrp",
      key: "mrp",
      width: "120px",
      render: (params) => {
        // return <Text>₹ {params ? params?.toFixed(2) : "--"}</Text>;
        return <Text>{params ? params?.toFixed(2) : "--"}</Text>;
      },
    },
    {
      title: <Col style={{ textAlign: "center" }}>Tax %</Col>,
      dataIndex: "tax",
      key: "tax",
      width: "120px",
      render: (param, record) => {
        return (
          <>
            {!record?.is_available && !record?.checkbox ? (
              <Text>-</Text>
            ) : (
              <CustomIconInput
                modalOpen={handleOpenModal}
                handleOk={handleOk}
                addonAfter={record?.redFlag ? <RedFlagIcon /> : <FlagIcon />}
                disable={true}
                value={record?.tax}
                name={"tax"}
                handleChangeRowData={handleChangeRowData}
                record={record}
                toolTipText={
                  "Tax % is not editable as the item is imported from the mob library. If you do think that the tax % is not accurate please click on the flag option and our team will look into it."
                }
              />
            )}
          </>
        );
      },
    },
    {
      title: "Store sale price",
      dataIndex: "store_selling_price",
      key: "store_selling_price",
      width: "120px",
      render: (param, record) => {
        return (
          <>
           
            {record?.is_available && record?.checkbox ? (
              <Text style={{ display: "block", textAlign: "left" }}>-</Text>
            ) : (
              <CustomTableInput
                prefix={"₹"}
                value={record?.vendor_data?.store_selling_price}
                // value={record?.vendor_selling_price}
                name={"store_selling_price"}
                handleChangeRowData={handleChangeRowData}
                record={record}
                disable={record?.checkbox || record?.onEdit ? false : true}
                check={"inventory"}
                // greaterValue={record?.mrp}
                errorText={"SP"}
                endText={"mrp"}
                mrpCheck={true}
              />
            )}
          </>
        );          
      },
    },

    {
      title: "Available inventory",
      dataIndex: "stock",
      key: "stock",
      width: "160px",
      render: (param, record) => {
        return (
          <Col style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
            {!record?.is_available && !record?.checkbox ? (
              <Text style={{ display: "block", textAlign: "right" }}>-</Text>
            ) : (
              <CustomTableInput
                type="number"
                minValue={0}
                value={record?.vendor_data?.stock}
                name={"stock"}
                handleChangeRowData={handleChangeRowData}
                record={record}
                disable={record?.checkbox || record?.onEdit ? false : true}
                conditionTest={"stock"}
              />
            )}
            <Col style={{ width: "80px" }}>
              {record?.is_available && (
                <Col>
                  {!record?.onEdit ? (
                    <Text
                      style={{
                        width: "100px",
                        color: "#2973f0",
                        cursor: "pointer",
                      }}
                      onClick={() => handleEdit(record)}
                    >
                      Edit
                    </Text>
                  ) : (
                    <Button
                      type="primary"
                      style={{ backgroundColor: "#de350b" }}
                      onClick={() => handleSaveProduct(record)}
                    >
                      Save
                    </Button>
                  )}
                </Col>
              )}
            </Col>
          </Col>
        );
      },
    },
  ];

  return (
    <>
      <Row style={{ gap: "40px" }} className="sellersideview">
        <Col className="banner">
          <Col className="left-banner">
            <Text className="text-I">
              Add products to your catalogue within minutes
            </Text>
            <Text className="text-II" onClick={() => handleHowWorks()}>
              How it works?
            </Text>
          </Col>
          <Col className="right-banner">
            <img src={BannerImage} alt="Banner" className="image" />
          </Col>
        </Col>

        <Col className="task-bar">
          {/* <SearchInput
            size={"large"}
            placeholder={"Search by name, description, SKU"}
            getLibraryApi={sellerTableApi}
            width={"50%"}
          /> */}
          <CustomAutoComplete
            // sellerTableApi={sellerTableApi}
            sellerTableApi={onSelectTableChange}
            api={getAutoCompleteSearchApi}
            dataList={productDataList}
            id={seller_id}
            IsAutocompleteFetching={IsAutocompleteFetching}
            selectedItems={brandsSearch}
            setSelectedItems={setBrandsSearch}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          
        </Col>
        <Col className="task-bar">
         
          <Col className="dropdown-wrapperr">
            <SearchDropdown
              // isCustomBorder={"8px"}
              list={tableSelector?.results?.categories}
              label={"Categories"}
              placeholder={"Search categories"}
              selectedItems={categories}
              setSelectedItems={setCategories}
              handleFilterLibrary={handleFilterLibrary}
              handleFilterSelect={handleFilterSelect}
              setSearchText= {setSearchText}
              searchText= {searchText}
            />
            <SearchDropdown
              // isCustomBorder={"8px"}
              list={tableSelector?.results?.brands}
              label={"Brands"}
              placeholder={"Search brands"}
              selectedItems={brands}
              setSelectedItems={setBrands}
              handleFilterLibrary={handleFilterLibrary}
              handleFilterSelect={handleFilterSelect}
              setSearchText= {setSearchText}
              searchText= {searchText}

            />
            {/* <SearchDropdown
            isCustomBorder={"8px"}
            list={tableSelector?.results?.brands}
            label={"Sort"}
            placeholder={"Sort"}
            selectedItems={addedFrom}
            setSelectedItems={setAddedFrom}
            handleFilterLibrary={handleFilterLibrary}
          /> */}

            {(brands?.length !== 0 || categories?.length !== 0) && (
              <Button
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: "30px",
                  height: "48px",
                  fontWeight: 500,
                  border: "none",
                }}
                onClick={handleClearFilter}
              >
                <CloseOutlined /> Clear filters
              </Button>
            )}
          </Col>
        </Col>
        <Col style={{ padding: "0 2rem" }}>
          <Text
            style={{ fontWeight: 500, fontSize: "20px" }}
          >{`All items ${tableSelector?.pagination?.total_entries}`}</Text>
        </Col>
        <Col className="table-wrapper">
          <CustomTableWithPagination
            className="mob-list-table table-fixed"
            tableDataSource={tableDataSource || []}
            tableColumns={columns}
            locale={{
              emptyText: isLoading ? <TableSkeleton length={30} /> : "",
            }}
            scroll={{ x: "100%" }}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            itemsPerPage={tableDataSource?.length || 50}
            totalEntries={
              !isLoading && tableData?.data?.pagination?.total_entries
            }
            isLoading={tableDataSource.length && isLoading}
          />
          {isFixedBarVisible && (
            <Affix
              offsetBottom={0}
              offsetTop={10}
              style={{ position: "sticky", zIndex: 999 }}
            >
              <Col
              className="sticky-col"                
              >
                <Col
                 className="sticky-col-inner"    
                 
                >
                  <Text
                    style={{
                      width: "150px",
                      color: "#0a243f",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                  {selectedRows?.length} Items selected <br /><span style={{fontSize:"12px",fontWeight:"500",color:"#0a243f",opacity:"0.6"}}>Including variants</span>
                  </Text>
                  <Col style={{ display: "flex", gap: "1rem" }}>
                    <Button
                      style={{ height: "46px", padding: "0.5rem 2rem" }}
                      onClick={_onCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#0354a3",
                        height: "46px",
                        padding: "0.5rem 2rem",
                      }}
                      onClick={() => handleCreateProduct()}
                    >
                      ADD TO YOUR CATALOGUE
                    </Button>
                  </Col>
                </Col>
              </Col>
            </Affix>
          )}
        </Col>
        <FlagItemModal
          open={openModal}
          handleOk={handleOk}
          // handleCancel={handleCancel}
          handleSave={handleFlagItem}
          reason={reason}
          setReason={setReason}
          comments={comments}
          setComments={setComments}
        />
        <ItemFlagModal open={openModalFlag} handleOk={handleGOBack} />
        <ItemDetailsModal
          open={openModalCarousel}
          handleOk={handleOkCarousel}
          handleSave={handleSelectedItem}
        />
        <AddedToCatalogueModal
          item={addCatalogueLengthSelectorData}
          open={openModalCatalogue}
          handleOk={handleOkCatalogue}
          handleback={handleBackCatalogue}
        />
        <LibraryWorkingModal
          open={openWorkingModal}
          handleOk={handleWorkingModal}
        />
      </Row>
    </>
  );
};

export default SellerMobLib;
